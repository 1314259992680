import eventBus from '../../js/event-bus';
import $ from '../../js/i18n.config';

const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      name: 'editProtocol',
      icon: 'edit',
      hint: $.t('common.toolTips.edit'),
    },
  ],
};

export const colMethods = {
  openProtocolPopup(e) {
    eventBus.$emit('openProtocolsPopup', e.row.data);
  },
  async removeProtocol(e) {
    // await this.removeProtocols(e.row.data.Id);
  },
  addButtonFuncs(buttons) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'editProtocol':
          button.onClick = this.openProtocolPopup;
          break;
        case 'deleteProtocol':
          button.onClick = this.removeProtocol;
          break;
        default:
          break;
      }
    });
  },
};

export default [
  {
    dataField: 'Title',
    caption: $.t('protocols.popup.title'),
    dataType: 'string',
  },
  {
    dataField: 'Description',
    caption: $.t('protocols.popup.description'),
    dataType: 'string',
  },
  buttonCol,
];
