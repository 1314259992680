import Api from '../../services/Api';
import Recipients from '../../js/models/Recipients';
import Cargos from '../../js/models/Cargos';
import Origins from '../../js/models/Origins';
import Destinations from '../../js/models/Destinations';
import helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    cargos: [],
    origins: [],
    destinations: [],
    recipientsInfo: [],
    isOriginBlock: false,
    isDestinationBlock: false,
    userData: null,
    comercialCasi: [
      'Arturo Gisbert Gallego',
      'Jose Carlos Escobar Martinez',
      'Francisco Fuentes Cespedes',
      'Virginie Kinh',
      'Manuel Segura Sánchez',
      'Antonio Zaragoza',
    ],
    preReferenciaFrutaria: [
      '02/',
      '04/',
      '05/',
      '06/',
    ],
    codPreferenciaFrutaria: '',
  },
  getters: {
    getCargos(state) {
      return state?.cargos;
    },
    getOrigins(state) {
      return state?.origins;
    },
    getDestinations(state) {
      return state?.destinations;
    },
    getRecipientInfo(state) {
      return state?.recipientsInfo;
    },
    getComercialCasi(state) {
      return state?.comercialCasi;
    },
    getPreReferenciaFrutaria(state) {
      return state?.preReferenciaFrutaria;
    },
    getCodPreReferenciaFrutaria(state) {
      return state?.codPreferenciaFrutaria;
    },
    isOriginBlock(state) {
      return state?.isOriginBlock;
    },
    isDestinationBlock(state) {
      return state?.isDestinationBlock;
    },
    getCarriers(state) {
      return state?.carriers;
    },
  },
  mutations: {
    UPDATE_CARGOS_LIST(state, payload) {
      state.cargos = payload;
    },
    UPDATE_ORIGINS_LIST(state, payload) {
      state.origins = payload;
    },
    UPDATE_DESTINATIONS_LIST(state, payload) {
      state.destinations = payload;
    },
    UPDATE_USERDATA_LIST(state, payload) {
      state.userData = payload;
    },
    UPDATE_RECIPIENTINFO_LIST(state, payload) {
      state.recipientsInfo = payload;
    },
    UPDATE_CARRIERS(state, payload) {
      state.carriers = payload;
    },
  },
  actions: {
    async fetchCargosList({ commit }) {
      try {
        const data = await Api.merchandise.getAll();
        if (data.status !== 404) {
          commit('UPDATE_CARGOS_LIST', JSON.parse(data.response));
        }
      } catch (error) {
        commit('UPDATE_CARGOS_LIST', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async fetchOriginsList({ commit }) {
      const data = await Origins.getAll();
      commit('UPDATE_ORIGINS_LIST', data);
    },
    async fetchDestinationsList({ commit }) {
      const data = await Destinations.getAll();
      commit('UPDATE_DESTINATIONS_LIST', data);
    },
    async fetchRecipientInfo({ commit }) {
      // const data = await Recipients.getAll();
      const data = await Api.recipientInfo.getAll();
      commit('UPDATE_RECIPIENTINFO_LIST', JSON.parse(data.response));
    },
    async fetchCarriers({ commit }) {
      const data = await Api.carriers.getAll();
      commit('UPDATE_CARRIERS', JSON.parse(data.response));
    },
  },
};
