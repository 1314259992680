<template>
  <DxDataGrid
    id="complatins-data-grid"
    v-bind="commonProps.dataGrid"
    :data-source="complatins"
    :focused-row-enabled="true"
    key-expr="id"
    :columns="complatinsColumns"
  />
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import complatinsColumns from './complatinsTabColumnsData';
import { commonProps } from '../../tripsColumnData';

export default {
  name: 'ComplatinsTab',
  components: {
    DxDataGrid,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      commonProps,
      complatinsColumns,
      complatins: this.data.data.complaints,
    };
  },
};
</script>

<style>

</style>
