<template>
  <DxPopup
    v-bind="commonProps.popup"
    :title="title"
    :visible.sync="show"
    :on-hidden="onHidden"
    height="auto"
    :width="620"
  >
    <DxForm
      ref="form"
      label-location="top"
      :form-data="formData"
      :show-colon-after-label="false"
      :on-field-data-changed="onFieldDataChanged"
    >
      <DxSimpleItem
        data-field="name"
        editor-type="dxTextBox"
        :is-required="true"
        :label="{
          text: $t('common.track.name')
        }"
        :editor-options="{
          placeholder: $t('common.placeholder.selectName'),
        }"
      />
      <DxSimpleItem
        editor-type="dxSelectBox"
        data-field="category"
        :is-required="true"
        :label="{
          text: $t('administration.cargo.category')
        }"
        :editor-options="{
          dataSource: merchandiseCategories,
          placeholder: $t('common.placeholder.selectCategory'),
        }"
      />
    </DxForm>
    <div>
      <f7-icon
        icon="info_circle_fill"
        :size="15"
      />
    </div>
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="hidePopup"
      >
        {{ $t("common.options.cancel") }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="addCargos"
      >
        {{ $t("common.options.save") }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm, DxSimpleItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';
import helpers from '../../../js/helpers';

export default {
  name: 'DeactivatePopup',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxButton,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      isEditing: false,
      formData: {
        name: '',
        category: '',
        id: '',
      },
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    title() {
      if (this.isEditing) return this.$t('administration.cargo.popup.titleEditing');
      return this.$t('administration.cargo.popup.title');
    },
    ...mapGetters('Trips', ['merchandiseCategories']),
  },
  mounted() {
    eventBus.$on('showCargosPopup', (mercancia) => {
      this.show = true;
      if (mercancia) {
        this.isEditing = true;
        this.formData = mercancia;
      }
    });
  },
  methods: {
    onFieldDataChanged(e) {
      const { dataField, value } = e;
      this.formData[dataField] = value;
    },
    hidePopup() {
      this.show = false;
    },
    async addCargos() {
      try {
        this.$f7.preloader.show();
        await this.postMerchandise(this.formData);
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        this.$f7.dialog.alert(msg);
      } finally {
        this.$f7.preloader.hide();
        this.show = false;
      }
    },
    onHidden() {
      this.form.resetValues();
      this.id = '';
      this.show = false;
      this.isEditing = false;
    },
    ...mapActions('Trips', ['postMerchandise']),
  },
};
</script>
