<template>
  <DxPopup
    :visible.sync="visible"
    v-bind="commonProps.popup"
    :on-hidden="hide"
    :title="$t('trips.ordersTab.addOrder')"
    :width="500"
  >
    <DxForm
      ref="form"
      :col-count="1"
      :form-data="formData"
      :items="fields"
    />
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="hide"
      >
        {{ $t("common.options.cancel") }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="handleAddOrder"
      >
        {{ $t("common.options.save") }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import {
  DxForm,
} from 'devextreme-vue/form';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../../components/trips/tripsColumnData';

export default {
  name: 'AddOrderPopup',
  components: {
    DxPopup,
    DxButton,
    DxForm,
  },
  data() {
    return {
      commonProps,
      formData: {
        reference: '',
      },
      fields: [
        {
          dataField: 'reference',
          isRequired: true,
          label: {
            text: this.$t('common.track.reference'),
          },
          validationRules: [
            {
              type: 'custom',
              message: this.$t('trips.ordersTab.errorMessage'),
              validationCallback: (e) => !this.orders.some((item) => item.reference === e.value),
            },
          ],

        },
      ],
      visible: false,
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapGetters('Trips', ['orders']),
  },
  mounted() {
    eventBus.$on('showAddOrderPopup', () => {
      this.visible = true;
    });
  },

  methods: {

    handleAddOrder() {
      if (this.formData.reference !== '' && this.formData.reference !== null && this.formData.reference !== undefined) {
        if (!this.form.validate().isValid) return;
        this.addOrder(
          {
            reference: this.formData.reference,
            deliveryPlaces: [],
            id: null,
            loadPlaces: [],
            merchandises: [],
          },
        );
        this.hide();
      } else {
        this.$f7.dialog.alert(this.$t('trips.ordersTab.errorReferenceNull'));
      }
    },

    hide() {
      this.form.resetValues();
      this.visible = false;
    },
    ...mapActions('Trips', ['addOrder']),
  },
};
</script>
