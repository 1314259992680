<template>
  <div class="wrapper main-documents">
    <div v-if="showPopup">
      <DxPopup
        id="delete-file-popup"
        v-bind="commonProps.popup"
        :title="$t('deviceInfo.documents.deletePopup.title')"
        :visible.sync="showPopup"
        :on-hidden="onHiddenDelete"
        height="auto"
      >
        <f7-block class="no-margin">
          <div class="padding-vertical-half text-align-center">
            <h4 class="color-his-primary">
              {{ $t('deviceInfo.documents.deletePopup.line1') }}
            </h4>
          </div>
        </f7-block>
        <f7-block-footer
          class="no-margin-top display-flex justify-content-center"
        >
          <!-- TODO cambiar por Devxtreme -->
          <f7-button
            fill
            type="contained"
            styling-mode="outlined"
            name="deactivate"
            @click="deletePopupDelete"
          >
            {{ $t('common.options.confirm') }}
          </f7-button>
        </f7-block-footer>
      </DxPopup>
    </div>
    <f7-row no-gap>
      <f7-col :width="25">
        <Table
          :title="shipmentInfo.title"
          :data="shipmentInfo.data"
        />
        <Table
          :title="shipmentSummary.title"
          :data="shipmentSummary.data"
        />
      </f7-col>
      <f7-col :width="75">
        <f7-card class="card-block">
          <div class="card-header no-margin-bottom">
            <f7-col>
              {{ $t('common.blocks.documents') }}
            </f7-col>
            <f7-col
              class="text-align-right margin-bottom-half"
            >
              <AttachFilePopup />
            </f7-col>
          </div>
          <div class="card-body">
            <f7-row>
              <DxDataGrid
                id="documents-data-grid"
                key-expr="Id"
                v-bind="dataGridProps"
                :word-wrap-enabled="true"
                :on-cell-prepared="onCellPrepared"
                :data-source="dataSource"
                :remote-operations="true"
                :column-auto-width="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
              >
                <DxHeaderFilter :visible="true" />
                <DxSelection
                  mode="single"
                />
                <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="DocumentsData"
                />
                <DxColumnChooser
                  :enabled="true"
                  mode="select"
                />
                <DxColumnFixing :enabled="true" />
                <DxScrolling column-rendering-mode="standard" />
                <DxGrouping :auto-expand-all="true" />
                <DxPaging :page-size="10" />
                <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="[5, 10, 20]"
                  :show-info="true"
                />
                <DxColumn
                  data-field="Id"
                  :caption="id"
                  alignment="left"
                  :visible="false"
                  :allow-header-filtering="false"
                  :show-in-column-chooser="false"
                />
                <DxColumn
                  data-field="Extension"
                  caption=""
                  alignment="left"
                  :allow-header-filtering="false"
                  cell-template="fileType"
                  :show-in-column-chooser="false"
                  :width="40"
                />
                <DxColumn
                  data-field="DisplayName"
                  :caption="$t('deviceInfo.documents.fileTable.description')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Name"
                  :caption="$t('deviceInfo.documents.fileTable.name')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="DateModified"
                  :caption="$t('deviceInfo.documents.fileTable.dateModified')"
                  :calculate-display-value="dateModified"
                  :allow-sorting="true"
                  data-type="datetime"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Description"
                  :caption="$t('deviceInfo.documents.fileTable.grades')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Owner"
                  :calculate-display-value="getOwner"
                  :caption="$t('deviceInfo.documents.fileTable.owner')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Extension"
                  :calculate-display-value="getFileExtension"
                  :caption="$t('deviceInfo.documents.fileTable.extension.title')"
                  alignment="left"
                  :allow-header-filtering="true"
                  :visible="false"
                  :calculate-filter-expression="calculateFilterExpression"
                >
                  <DxHeaderFilter :data-source="createFilters" />
                </DxColumn>
                <DxColumn
                  data-field="DateCreated"
                  :caption="$t('deviceInfo.documents.fileTable.dateCreated')"
                  :calculate-display-value="dateCreated"
                  :allow-sorting="true"
                  data-type="datetime"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field=""
                  alignment="center"
                  cell-template="download"
                  :allow-header-filtering="false"
                  :show-in-column-chooser="false"
                  :width="50"
                />
                <DxColumn
                  data-field=""
                  alignment="center"
                  cell-template="edit"
                  :allow-header-filtering="false"
                  :show-in-column-chooser="false"
                  :width="50"
                />
                <DxColumn
                  data-field=""
                  alignment="center"
                  cell-template="delete"
                  :allow-header-filtering="false"
                  :show-in-column-chooser="false"
                  :width="50"
                />
                <template #fileType="{data}">
                  <f7-icon
                    :md="getFileTypeIcon(data)"
                  />
                </template>
                <template #download="{ data }">
                  <DxButton
                    class="dx-btn-pdf"
                    raised
                    type="default"
                    styling-mode="outlined"
                    @click="handleDownloadFile(data.data.Id)"
                  >
                    <f7-icon
                      md="material:file_download"
                    />
                  </DxButton>
                </template>
                <template #delete="{ data }">
                  <DxButton
                    class="dx-btn-pdf"
                    raised
                    type="default"
                    :on-content-ready="onBtnReady"
                    :disabled="isDisabled(data)"
                    styling-mode="outlined"
                    @click="removeFiles(data.data.Id)"
                  >
                    <f7-icon
                      md="material:clear"
                    />
                  </DxButton>
                </template>
                <template #edit="{ data }">
                  <DxButton
                    class="dx-btn-pdf"
                    raised
                    type="default"
                    :disabled="isDisabled(data)"
                    styling-mode="outlined"
                    @click="editFiles(data.data)"
                  >
                    <f7-icon
                      md="material:edit"
                    />
                  </DxButton>
                </template>
              </DxDataGrid>
            </f7-row>
          </div>
        </f7-card>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import DxButton from 'devextreme-vue/button';
import { DxPopup } from 'devextreme-vue/popup';

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxHeaderFilter,
  DxScrolling,
  DxGrouping,
  DxColumnFixing,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import {
  DxColumnChooser,
} from 'devextreme-vue/tree-list';
import Table from '../Table/index.vue';
import AttachFilePopup from './attachFilePopup/index.vue';
import { commonProps } from '../trips/tripsColumnData';
import eventBus from '../../js/event-bus';

export default {
  name: 'Documents',
  components: {
    AttachFilePopup,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxHeaderFilter,
    DxButton,
    DxPopup,
    Table,
    DxColumnChooser,
    DxScrolling,
    DxGrouping,
    DxColumnFixing,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      commonProps,
      shipmentInfo: {
        title: this.$t('deviceInfo.information.sideTable.shipmentInfo.title'),
        data: [],
      },
      shipmentSummary: {
        title: this.$t('deviceInfo.information.sideTable.shipmentSummary.title'),
        data: [],
      },
      calculateFilterExpression(filterValue) {
        const column = this;
        switch (filterValue) {
          case 'image':
            return [
              [column.dataField, 'startswith', 'image'],
            ];
          case 'application':
            return [
              [column.dataField, 'startswith', 'application'],
            ];
          case 'others':
            return [
              ['!', [column.dataField, 'startswith', 'application']],
              'and',
              ['!', [column.dataField, 'startswith', 'image']],
            ];
          default:
            break;
        }

        return null;
      },
      filters: [
        {
          value: 'algo',
          name: 'Images',
        },
      ],
      showPopup: false,
      fileGuid: '',
    };
  },
  computed: {
    dataSource() {
      // const localData = [];
      // const data = this.shipmentsList;
      return this.files;
    },
    ...mapState('Shipments', ['files', 'downloadFileData', 'currentShip']),
    ...mapGetters('authentication', ['getUsername']),
    ...mapGetters('Shipments', ['getTableShipmentInfo', 'getTableShipmentSummary']),
  },
  async beforeMount() {
    try {
      await this.getFilesList();
      this.shipmentInfo.data = this.getTableShipmentInfo;
      this.shipmentSummary.data = this.getTableShipmentSummary;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  mounted() {
    // Funcionalidad resaltar documento clickeado en notificaciones
    eventBus.$on('highlightDocument', (id) => {
      if (id) this.id = id;
    });
    this.shipmentInfo.data = this.getTableShipmentInfo;
    this.shipmentSummary.data = this.getTableShipmentSummary;
  },
  methods: {
    onCellPrepared(e) {
    // Funcionalidad resaltar documento clickeado en notificaciones

      if (e.key === this.id) {
        const row = e;
        row.style.setProperty('background-color', 'red');
      }
    },
    dateValue(row) {
      return new Date(row.DateModified);
    },
    dateModified(row) {
      return moment.utc(row.DateModified).local().format('DD/MM/YYYY HH:mm');
    },
    dateCreated(row) {
      return moment.utc(row.DateCreated).local().format('DD/MM/YYYY HH:mm');
    },
    onBtnReady(btn) {
      if (btn.element.ariaDisabled === 'true') {
        btn.element.title = this.$t('deviceInfo.documents.fileTable.removeDisabled');
        btn.element.style.pointerEvents = 'auto';
      }
    },
    isDisabled(data) {
      if (!this.currentShip.editPermissions) return true;

      const owner = data.row.data.Owner;
      const currentUser = this.getUsername.toLowerCase();

      return currentUser !== owner;
    },
    createFilters(data) {
      data.dataSource.postProcess = (data) => {
        const filterTypes = [
          { key: 'images', value: 'image', text: this.$t('deviceInfo.documents.fileTable.fileTypeFilters.pictures') },
          { key: 'documents', value: 'application', text: this.$t('deviceInfo.documents.fileTable.fileTypeFilters.documents') },
          { key: 'others', value: 'others', text: this.$t('deviceInfo.documents.fileTable.fileTypeFilters.others') },
        ];

        return filterTypes;
      };
    },

    async handleDownloadFile(guid) {
      await this.downloadFile(guid);
      this.openFile(this.downloadFileData);
    },
    async removeFiles(guid) {
      this.showPopup = true;
      this.fileGuid = guid;
    },
    getFileTypeIcon(data) {
      const type = data.value.split('/')[0];
      const extension = data.value.split('/')[1];
      if (type === 'image') return 'material:image';
      if (type === 'application') {
        switch (extension) {
          case 'pdf':
            return 'material:picture_as_pdf';
          default:
            return 'material:insert_drive_file';
        }
      }
      return 'material:insert_drive_file';
    },
    getFileExtension(data) {
      const type = data.Extension.split('/')[0];

      if (type === 'image') return this.$t('deviceInfo.documents.fileTable.extension.image');
      if (type === 'application') return this.$t('deviceInfo.documents.fileTable.extension.document');
      return this.$t('deviceInfo.documents.fileTable.extension.other');
    },
    getOwner(data) {
      const owner = data.Owner.split('.').map((name) => {
        const [first, ...rest] = name;
        const formatedName = first.toUpperCase() + rest.join('');
        return formatedName;
      }).join(' ');

      return owner;
    },
    openFile(urlFile) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:${urlFile.Extension};base64,${urlFile.Attachment}`;
      downloadLink.download = `${urlFile.Name}`;
      downloadLink.click();
    },
    onHiddenDelete() {
      this.showPopup = false;
      this.fileGuid = '';
    },
    deletePopupDelete() {
      this.removeFile(this.fileGuid);
      this.showPopup = false;
      this.fileGuid = '';
    },
    ...mapActions('Shipments', ['getFilesList', 'downloadFile', 'removeFile']),
    async editFiles(guid) {
      eventBus.$emit('openPopupFile', guid);
    },
  },
};
</script>
<style lang="scss" scoped>
  @import url('Documents.styles.scss');
  .highlight{
    background-color: red;
  }
  .iconos{
  width: 10px;
  height: 10px;
}
</style>
