<template>
  <div class="main-automaticUpdate">
    <div class="content-refresh">
      <div class="card no-margin">
        <div
          class="card-header"
        >
          <div>
            <img
              class="margin-right-half"
              :src="icWifiOn"
            > {{ getGMTtimedate() }}
          </div>
          <span>{{ getGMTtimehour() }}</span>
        </div>
        <div class="card-body">
          <div class="form-check">
            <label
              class="form-check-label"
              for="autorefresh"
            >
              <button
                style="border:none; background-color: transparent; width:40px; outline: none;"
                @click="updateComponent"
              >
                <svg
                  class="margin-right-half"
                  width="22"
                  height="22"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="var(--f7-theme-color)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19 0C33.4827 0 38 5.21039 38 19C38 32.7896 33.4827 38 19 38C4.51727 38 0 32.7896 0 19C0 5.21039 4.51727 0 19 0Z"
                  />
                  <path
                    d="M27.7143 7C27.2409 7 26.8571 7.38376 26.8571 7.85716V10.9883C22.4442 6.71594 15.4035 6.82994 11.1311 11.2428C9.11769 13.3225 7.99445 16.1053 8.00002 19C8.00002 19.4734 8.38378 19.8571 8.85718 19.8571C9.33058 19.8571 9.71429 19.4734 9.71429 19C9.71534 13.7927 13.9375 9.57223 19.1447 9.57328C21.7893 9.57379 24.3121 10.685 26.0977 12.6357L22.3006 13.9017C21.8509 14.0513 21.6076 14.5371 21.7572 14.9868C21.9068 15.4365 22.3926 15.6798 22.8423 15.5302L27.9852 13.8159C28.3362 13.699 28.5726 13.37 28.5715 13V7.85711C28.5714 7.38376 28.1877 7 27.7143 7Z"
                    fill="white"
                  />
                  <path
                    d="M29.4285 18.1428C28.9551 18.1428 28.5713 18.5266 28.5713 19C28.5703 24.2072 24.3481 28.4277 19.1409 28.4266C16.4963 28.4261 13.9735 27.315 12.1879 25.3642L15.985 24.0983C16.4347 23.9487 16.678 23.4629 16.5284 23.0132C16.3788 22.5635 15.893 22.3201 15.4433 22.4697L10.3004 24.184C9.94936 24.3009 9.71296 24.63 9.71412 25V30.1428C9.71412 30.6162 10.0979 31 10.5713 31C11.0447 31 11.4284 30.6162 11.4284 30.1428V27.0117C15.8413 31.284 22.8821 31.17 27.1544 26.7571C29.1679 24.6774 30.2911 21.8946 30.2855 19C30.2856 18.5266 29.9019 18.1428 29.4285 18.1428Z"
                    fill="white"
                  />
                </svg>
              </button>
              {{ $t("automaticUpdate.txtTime") }}
            </label>
            <label class="toggle toggle-init">
              <input
                type="checkbox"
                @click="toggle"
                @class="isActive = !isActive"
              >
              <span
                class="toggle-icon"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { MILISECONDS_TO_REFRESH_AUTOMATICALLY } from '../../js/constants';

import icWifiOn from '../../static/icons/ic_wifi_on.svg';
import icWifiOff from '../../static/icons/ic_wifi_off.svg';
import icRefresh from '../../static/icons/ic_refresh.svg';

export default {
  name: 'AutomaticUpdate',
  isActive: true,
  computed: {
    icWifiOn: () => icWifiOn,
    icWifiOff: () => icWifiOff,
    icRefresh: () => icRefresh,
  },
  created() {
    setInterval(this.refreshNow, MILISECONDS_TO_REFRESH_AUTOMATICALLY);
  },
  methods: {
    async update() {
      try {
        await this.fetchShipmentListOff();
      } catch (error) {
        this.$f7.dialog.alert(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    getGMTtimedate() {
      return moment(new Date()).format('DD/MM/YYYY');
    },
    getGMTtimehour() {
      return moment(new Date()).format('HH:mm');
    },
    toggle() {
      this.isActive = !this.isActive;
    },
    refreshNow() {
      if (this.isActive) {
        this.update();
      }
    },
    updateComponent() {
      const self = this;
      self.show = false;
      this.$nextTick(() => {
        this.$forceUpdate();
        this.update();
        self.show = true;
      });
    },
    ...mapActions('Shipments', ['fetchShipmentListOff']),
  },
};
</script>
<style lang="scss" scoped>
@import "./AutomaticUpdate.styles.scss";
</style>
