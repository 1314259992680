import Dexie from 'dexie';
import { DATABASE_NAME } from '../js/constants';

const db = new Dexie(DATABASE_NAME);

db.version(1).stores({
  devices: '++deviceId, activationPlace, alertIntrusion, alertTemperature, autoActivation, cargo, carrier, co2, coefficient, coverage, customerOfOurCustomer, customerRef, dateActivation, dateDeactivate, dateDeliver, dateLastReportUTC, dateLoading, dateTimeAcquiredUtc, deliveryPlace, deviceTemp, deviceType, genericAttribute01, genericAttribute02, genericAttribute03, genericAttribute04, genericAttribute05, higherTemperature, humidity, idDeviceReport, isAvalible, lastLocation, lastTemperature, latitude, listContactsPhone, listMails, listMailsOversight, longitude, lowerTemperature, modificationPermissions, pulpTemperature, *recipientInfo, state, timeSinceLastReport, transportNum, typeTransport',
  recipientInfo: '++Identifier, Name',
  cargos: '++Identifier, Name, *Range',
  origins: '++Identifier, Name',
  destinations: '++Identifier, Name',
  notification: '++Id, Company, InsertedDate, Read, *Resources, User',
  shipments: '++Id, *carriers, companiesAccess, *complaints, creatorUser, customerReference, dateArrived, dateDelivery, dateEstimatedDelivery, dateEstimatedArrived, dateEstimatedLoading, dateLoading, *destination, *devices, *files, idCompany, insertedDate, *orders, *origin, reference, state',
});

export default db;
