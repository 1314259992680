var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-card',{staticClass:"card-block main-orders",attrs:{"title":"Pedidos"}},[_c('f7-block',[_c('div',{staticClass:"content-buttons margin-vertical"},[_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"styling-mode":"contained"},on:{"click":_vm.addPedido}},[_c('i',{staticClass:"dx-icon-plus padding-right-half"}),_vm._v("\n        "+_vm._s(_vm.$t('trips.add'))+"\n      ")])],1),_vm._v(" "),_c('DxTabPanel',{staticClass:"margin-bottom",attrs:{"data-source":_vm.orders,"defer-rendering":false,"show-nav-buttons":true,"repaint-changes-only":true,"item-title-template":"title","item-template":"itemTemplate"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var pedido = ref.data;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('trips.registerTrip.referenceMin'))+" "+_vm._s(pedido.reference))]),_vm._v(" "),_c('i',{staticClass:"dx-icon dx-icon-trash no-margin",on:{"click":function($event){return _vm.handleRemoveOrder(pedido)}}})])]}},{key:"itemTemplate",fn:function(ref){
var pedido = ref.data;
return [_c('DxTabPanel',{attrs:{"data-source":_vm.createTabs(pedido),"item-title-template":"title","item-template":"itemTemplate"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var tab = ref.data;
return [_c('span',[_vm._v(_vm._s(tab.name))])]}},{key:"itemTemplate",fn:function(ref){
var tab = ref.data;
return [(tab.name.toLowerCase() === 'información')?_c('div',{staticClass:"padding-half"},[_c('DxForm',{ref:"form",staticClass:"padding-bottom-half",attrs:{"col-count":2,"on-field-data-changed":_vm.onFieldDataChanged,"form-data":tab.data,"items":tab.fields}})],1):_c('div',{staticClass:"padding-top"},[_c('DxDataGrid',{attrs:{"data-source":tab.data,"columns":tab.columns,"on-toolbar-preparing":_vm.onToolbarPreparing},scopedSlots:_vm._u([{key:"editTooltip",fn:function(){return [_c('p',[_c('i',[_vm._v(_vm._s(_vm.$t('trips.messageEditCelds')))])])]},proxy:true}],null,true)},[_c('DxEditing',{attrs:{"allow-adding":true,"allow-updating":true,"allow-deleting":true,"confirm-delete":true,"mode":"cell","use-icons":true}},[_c('DxTexts',{attrs:{"confirm-delete-message":_vm.$t('devextreme.confirmDelete')}})],1)],1)],1)]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }