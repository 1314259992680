import StatusCell from './statusCell.vue';
import TempCell from './tempCell.vue';
import AlertCell from './alertCell.vue';
import ArrivalCell from './arrivalCell.vue';
import HumidityCell from './humidityCell.vue';

export default [
  { name: 'status-cell', component: StatusCell },
  { name: 'temp-cell', component: TempCell },
  { name: 'alert-cell', component: AlertCell },
  { name: 'arrival-cell', component: ArrivalCell },
  { name: 'humidity-cell', component: HumidityCell },
];
