<template>
  <f7-row
    class="main-homeFilters no-margin"
  >
    <div class="content-selectbox">
      <div class="label-text-data">
        {{ $t('home.filters.selectBox.title'), }}
      </div>
      <DxSelectBox
        :data-source="states"
        display-expr="name"
        value-expr="value"
        :value="shipmentsFilters.status"
        :on-value-changed="onChangeStatus"
      />
    </div>
    <CalendarInitEndDate key-name="dates" />
    <f7-link
      class="homefilters-icon-restore"
      @click="clearFilters"
    >
      <f7-icon
        class="text-muted"
        md="material:restore"
      />
      <span class="text-muted hidden-desktop">Restaurar periodo</span>
    </f7-link>
  </f7-row>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapState } from 'vuex';
import CalendarInitEndDate from '../calendarInitEndDate/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'HomeFilters',
  components: {
    DxSelectBox,
    CalendarInitEndDate,
  },
  data() {
    return {
      states: [
        {
          name: this.$t('home.filters.selectBox.enabled'),
          value: 1,
        }, {
          name: this.$t('home.filters.selectBox.disabled'),
          value: 0,
        }, {
          name: this.$t('home.filters.selectBox.all'),
          value: -1,
        },
      ],
    };
  },
  computed: {
    ...mapState('Shipments', ['shipmentsFilters']),
  },
  methods: {
    onChangeStatus(e) {
      if (e.value === 0 || e.value === -1) {
        eventBus.$emit('hiddenMap');
        eventBus.$on('hiddenMap');
      }

      this.setStatusFilter(e.value);
    },
    ...mapActions('Shipments', ['setStatusFilter', 'clearFilters']),
  },
};
</script>

<style>
@import url('HomeFilters.styles.scss');
</style>
