<template>
  <div class="main-summary">
    <f7-row no-gap>
      <f7-col width="25">
        <f7-card class="card-block">
          <div class="card-header">
            {{ $t('common.blocks.documents') }}
          </div>
          <div class="card-body">
            <div class="list margin-horizontal-half">
              <ul>
                <li
                  v-for="(item, index) in summaries"
                  :key="index"
                >
                  <a
                    class="item-link item-content"
                    href="#"
                    @click="modificarSrcIframe(item.src)"
                  >

                    <div class="item-media">
                      <img
                        width="42"
                        height="42"
                        :class="`${item.class}`"
                      >
                    </div>
                    <div class="item-inner">
                      <div class="item-title">
                        {{ item.nombre }}
                        <div class="item-footer">{{ fileSize.toFixed() }} kb</div>
                      </div>
                      <div class="item-after" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </f7-card>
      </f7-col>
      <f7-col width="75">
        <f7-card class="card-block">
          <div class="card-header">
            {{ $t('summary.display') }}
          </div>
          <div class="card-body no-margin no-padding">
            <iframe
              style="min-height: calc(100vh - 200px); border-left: 2px solid white; border-right: 2px solid white; border-radius: 4px;"
              width="100%"
              height="100%"
              :src="srcIframe"
              frameborder="0"
            />
          </div>
        </f7-card>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import ContactsListComponent from 'framework7/components/contacts-list/contacts-list';
import { mapActions, mapState, mapGetters } from 'vuex';
import { baseUrlAPI } from '../../js/constants';

export default {
  name: 'Summary',
  data() {
    return {
      summaries: [],
      srcIframe: '',
      fileSize: '',
    };
  },
  computed: {
    ...mapState('Shipments', ['files', 'downloadFileData', 'currentShip']),
    ...mapGetters('authentication', ['getUsername']),
    ...mapState('authentication', ['user']),
  },
  beforeMount() {
    /* this.summaries.push({
      nombre: 'default.pdf',
      src: 'https://www.hispatec.com/distribuidores/2020-Agrotareo-Hispatec.pdf',
      class: 'ic-pdf',
    }); */

    try {
      this.$f7.preloader.show();
      this.exportPdf();
      if (this.summaries.length > 0) {
        this.modificarSrcIframe(this.summaries[0].src);
      }
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('Shipments', ['getFilesList', 'downloadFile', 'removeFile']),
    async exportPdf() {
      const self = this;
      const deviceId = this.currentShip.device;

      window.URL = window.URL || window.webkitURL;
      const fileName = `FS_${(deviceId || '').split('/').pop().split('?')[0]}.pdf`;
      const baseUrl = `${baseUrlAPI}/v1/devices/${deviceId}/pdf`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', baseUrl, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.user.access}`);
      xhr.responseType = 'blob';

      let file;

      xhr.onload = function (e) {
        if (this.status === 200) {
          file = new Blob([xhr.response], { type: 'application/pdf' });
          const link = document.createElement('a');
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = function () {
            link.href = reader.result;
            self.summaries.push({
              nombre: fileName,
              src: link.href,
              class: 'ic-pdf',
              size: link.size,
            });
            self.modificarSrcIframe(link.href);
            self.tamanio(file.size);
          };
        }
      };
      xhr.send();
    },
    tamanio(src) {
      this.fileSize = 0.000977 * Number.parseFloat(src);
    },
    modificarSrcIframe(src) {
      if (src !== '') {
        this.srcIframe = `${src}#toolbar=0`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  @import url('Summary.styles.scss');
  .ic-pdf{
    content: url("../../static/icons/ic_pdf.svg");
  }
  .ic-text{
    content: url("../../static/icons/ic_text.svg");
  }
  .ic-csv{
    content: url("../../static/icons/ic_csv.svg");
  }
</style>
