/* eslint-disable no-prototype-builtins */
import Api from '../../services/Api';
import helpers from '../../js/helpers';
import eventBus from '../../js/event-bus';
import Shipments from '../../js/models/Shipments';

export default {
  namespaced: true,
  state: {
    // Definir cambios que se hacen aquí en la mutación INITIALIZE_REGISTER_PAGE
    registerTrip: {
      tripInfo: {
        id: null,
        creatorUser: null,
        idCompany: null,
        insertedDate: null,
        dateLoading: null,
        dateArrived: null,
        dateEstimatedArrived: null,
        dateDelivery: null,
        reference: null,
        clientReference: null,
        aproxDeparture: null,
        aproxDelivery: null,
        origin: null,
        destination: null,
      },
      orders: [],
      carriers: [],
      devices: [],
      files: [],
      complaints: [],
      companiesAccess: [],
    },
    trips: [],
    tripsFilters: [],
    tripData: {
      locations: [],
      merchandise: [],
      devices: [],
    },
  },
  getters: {
    formatedTripData(state) {
      const {
        registerTrip: {
          tripInfo, orders, carriers, devices, files, complaints, companiesAccess,
        },
        tripData: {
          locations,
          merchandise,
        },
      } = state;

      const origin = locations.find((item) => item.id === tripInfo.origin);
      const destination = locations.find((item) => item.id === tripInfo.destination);
      const parsedOrders = orders.map((order) => {
        const {
          id, reference, loadPlaces, deliveryPlaces, merchandises,
        } = order;
        return {
          id,
          reference,
          loadPlaces: loadPlaces.map((place) => ({
            date: place.date,
            loaded: place.loaded,
            ...locations.find((item) => item.id === place.name),
          })),
          deliveryPlaces: deliveryPlaces.map((place) => ({
            date: place.date,
            Delivered: place.Delivered,
            ...locations.find((item) => item.id === place.name),
          })),
          merchandises: merchandises.map((merch) => ({
            metric: merch.metric,
            volume: merch.volume,
            product: merchandise.find((item) => item.product === merch.product),
          })),
        };
      });
      return {
        Id: tripInfo.id,
        creatorUser: tripInfo.creatorUser,
        idCompany: tripInfo.idCompany,
        insertedDate: tripInfo.insertedDate,
        state: tripInfo.state,
        reference: tripInfo.reference,
        customerReference: tripInfo.clientReference,
        dateLoading: tripInfo.dateLoading,
        dateEstimatedLoading: tripInfo.aproxDeparture,
        dateArrived: tripInfo.dateArrived,
        dateEstimatedArrived: tripInfo.dateEstimatedArrived,
        dateDelivery: tripInfo.dateDelivery,
        dateEstimateDelivery: tripInfo.aproxDelivery,
        origin,
        destination,
        orders: parsedOrders,
        carriers,
        devices: devices.map((device) => ({
          ...device.originalDevice,
          dateActivation: device.dateActivation,
          dateDeactivate: device.dateDeactivation,
          higherTemperature: device.highTemp,
          lowerTemperature: device.lowTemp,
        })),
        files,
        complaints,
        companiesAccess,
      };
    },
    filteredTrips(state) {
      return state.trips.filter((trip) => {
        if (state.tripsFilters.length === 0) return true;
        return state.tripsFilters.some((filter) => filter.filterExpr(trip));
      });
    },
    minimalDevices(state) {
      return state.tripData.devices;
    },
    merchandise(state) {
      return state.tripData.merchandise;
    },
    merchandiseCategories(state) {
      const { merchandise } = state.tripData;
      const arr = [];
      merchandise.forEach((merch) => {
        if (!arr.includes(merch.category)) {
          arr.push(merch.category);
        }
      });
      return arr;
    },
    devices(state) {
      return state.registerTrip.devices;
    },
    locations(state) {
      return state.tripData.locations;
    },
    orders(state) {
      return state.registerTrip.orders;
    },
    carriers(state) {
      return state.registerTrip.carriers;
    },
    tripInfo(state) {
      return state.registerTrip.tripInfo;
    },
  },
  mutations: {
    // Trips
    SET_TRIPS(state, payload) {
      state.trips = payload;
    },
    REMOVE_TRIP_FILTER(state, payload) {
      state.tripsFilters.splice(state.tripsFilters.indexOf(payload), 1);
    },
    ADD_TRIP_FILTER(state, payload) {
      state.tripsFilters.push(payload);
    },
    SET_ALL_FILTER(state, payload) {
      state.tripsFilters = [payload];
    },

    // Trip data and register
    SET_LOCATIONS(state, payload) {
      state.tripData.locations = payload;
    },
    SET_MERCHANDISE(state, payload) {
      state.tripData.merchandise = payload;
    },
    SET_CARRIERS(state, payload) {
      state.registerTrip.carrier = payload;
    },
    SET_DEVICES(state, payload) {
      state.tripData.devices = payload;
    },
    ADD_ORDER(state, payload) {
      state.registerTrip.orders.push(payload);
    },
    REMOVE_ORDER(state, payload) {
      const { orders } = state.registerTrip;
      orders.splice(orders.indexOf(payload), 1);
    },
    ADD_CARRIER(state, payload) {
      const { carriers } = state.registerTrip;
      if (payload.dataIndex !== false) {
        carriers[payload.dataIndex] = payload.formData;
      } else {
        carriers.push(payload.formData);
      }
    },
    INITIALIZE_REGISTER_PAGE(state) {
      state.registerTrip = {
        tripInfo: {
          id: null,
          creatorUser: null,
          idCompany: null,
          insertedDate: null,
          dateLoading: null,
          dateArrived: null,
          dateEstimatedArrived: null,
          dateDelivery: null,
          state: null,

          reference: null,
          clientReference: null,
          aproxDeparture: null,
          aproxDelivery: null,
          origin: null,
          destination: null,
        },
        files: [],
        complaints: [],
        companiesAccess: [],
        orders: [],
        carriers: [],
        devices: [],
      };
    },
    FILL_FIELDS_WITH_DATA(state, payload) {
      state.registerTrip = {
        tripInfo: {
          id: payload.Id,
          creatorUser: payload.creatorUser,
          idCompany: payload.idCompany,
          insertedDate: payload.insertedDate,
          dateLoading: payload.dateLoading,
          dateArrived: payload.dateArrived,
          dateEstimatedArrived: payload.dateEstimatedArrived,
          dateDelivery: payload.dateDelivery,
          state: payload.state,

          reference: payload.reference,
          clientReference: payload.customerReference,
          aproxDeparture: payload.dateEstimatedLoading,
          aproxDelivery: payload.dateEstimateDelivery,
          origin: payload.origin.id,
          destination: payload.destination.id,
        },
        orders: payload.orders.map((order) => ({
          ...order,
          loadPlaces: order.loadPlaces.map((loc) => ({
            name: loc.id,
            date: loc.date,
            loaded: loc.loaded,
          })),
          deliveryPlaces: order.deliveryPlaces.map((loc) => ({
            name: loc.id,
            date: loc.date,
            Delivered: loc.Delivered,
          })),
          merchandises: order.merchandises.map((merch) => ({
            product: merch.id,
            volume: merch.volume,
            metric: merch.metric,
          })),
        })),
        carriers: payload.carriers,
        devices: payload.devices.map((device) => ({
          originalDevice: device,
          status: device.state === 37 ? 'active' : 'inactive',
          device: device.deviceId,
          dateActivation: device.dateActivation,
          dateDeactivation: device.dateDeactivate,
          highTemp: device.higherTemperature,
          lowTemp: device.lowerTemperature,
        })),

        files: payload.files,
        complaints: payload.complaints,
        companiesAccess: payload.companiesAccess,

      };
    },
  },
  actions: {
    initializeRegisterPage({ commit }) {
      commit('INITIALIZE_REGISTER_PAGE');
    },
    fillFieldsWithData({ commit }, payload) {
      commit('FILL_FIELDS_WITH_DATA', payload);
    },
    addOrder({ commit }, payload) {
      commit('ADD_ORDER', payload);
    },
    removeOrder({ commit }, payload) {
      commit('REMOVE_ORDER', payload);
    },
    addCarrier({ commit }, payload) {
      commit('ADD_CARRIER', payload);
    },

    // Trips ACTIONS
    async fetchTrips({ commit }, params) {
      const data = await Api.trips.getAll(params);
      commit('SET_TRIPS', JSON.parse(data.response));
    },
    async postTrip(_, trip) {
      const json = JSON.stringify(trip);
      await Api.postTrip(json);
    },
    updateTripsFilters({ commit }, change) {
      switch (change.type) {
        case 'remove':
          commit('REMOVE_TRIP_FILTER', change.filter);
          break;
        case 'add':
          commit('ADD_TRIP_FILTER', change.filter);
          break;
        default:
          break;
      }
    },
    setAllFilter({ commit }, filter) {
      commit('SET_ALL_FILTER', filter);
    },
    // Merchandise ACTIONS
    async getMerchandises({ commit }) {
      try {
        const data = await Api.merchandise.getAll();
        if (data.status !== 404) {
          const parsedResponse = JSON.parse(data.response);

          commit('SET_MERCHANDISE', parsedResponse);
          eventBus.$emit('merchandiseLoaded');
        }
      } catch (error) {
        commit('SET_MERCHANDISE', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async postMerchandise({ dispatch }, merchandise) {
      try {
        await Api.merchandise.post(merchandise);
        await dispatch('getMerchandises');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async removeMerchandise({ dispatch }, id) {
      try {
        await Api.merchandise.remove(id);
        await dispatch('getMerchandise');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },

    async postLocation({ dispatch }, location) {
      try {
        await Api.locations.post(location);
        await dispatch('getLocations');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async removeLocation({ dispatch }, id) {
      try {
        await Api.locations.remove(id);
        await dispatch('getLocations');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async getLocations({ commit }) {
      const data = await Api.locations.getAll();
      const parsedResponse = JSON.parse(data.response);

      commit('SET_LOCATIONS', parsedResponse);
    },

    // Devices ACTIONS

    async getMinimalDevices({ commit }) {
      const data = await Api.getCompleteList('minimaldevices');
      const parsed = JSON.parse(data.response);
      commit('SET_DEVICES', parsed);
    },
    // Carriers ACTIONS
    async getCarriers({ commit }) {
      const data = await Api.carriers.getAll();
      const parsedResponse = JSON.parse(data.response);

      commit('SET_CARRIERS', parsedResponse);
      // eventBus.$emit('carrierLoaded');
    },
    async postCarrier({ dispatch }, carrier) {
      try {
        await Api.carriers.post(carrier);
        await dispatch('getCarriers');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async removeCarrier({ dispatch }, id) {
      try {
        await Api.carriers.remove(id);
        await dispatch('getCarriers');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
  },
};
