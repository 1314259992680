import moment from 'moment';
import { Device } from 'framework7';
import Api from '../../services/Api';
import i18n from '../../js/i18n.config';
import eventBus from '../../js/event-bus';
import Helpers from '../../js/helpers';
// import Devices from '../../js/models/Devices';

export default {
  namespaced: true,
  state: {
    shipmentsList: [],
    shipmentsFilters: {
      status: 1,
      dates: {
        init: moment(new Date()).subtract(3, 'months'),
        end: moment(new Date()),
      },
    },
    currentShip: [],
    report: [],
    file: {
      Id: '',
      Description: '',
      Name: '',
      DisplayName: '',
      Extension: '',
      Attachment: '',
    },
    downloadFileData: {},
    files: [],
    uploadedFiles: [],
    reports: [],
    typeOfTransport: [{
      text: i18n.t('launchTrip.typeTransport.land'),
      value: 134,
    }, {
      text: i18n.t('launchTrip.typeTransport.maritime'),
      value: 135,
    }, {
      text: i18n.t('launchTrip.typeTransport.aerial'),
      value: 136,
    }],
    device: [],
    deviceId: '',
    cargo: '',
    carrier: '',
    customerRef: '',
    dateActivation: null,
    dateDeliver: null,
    activationPlace: '',
    deliveryPlace: '',
    lowerTemperature: '',
    higherTemperature: '',
    customer: '',
    customerOfOurCustomer: '',
    typeTransport: 134,
    transportNum: '',
    numberTruck: '',
    numberTrailer: '',
    numberContainer: '',
    clientWeb: '',
    recipientInfo: {},
    recipientInfoName: '',
    genericAttribute01: '',
    genericAttribute02: '',
    genericAttribute03: '',
    genericAttribute04: '',
    genericAttribute05: '',
    numeroPaletRoyal: '',
    numeroPedidoRoyal: '',
    codPreReferenciaFrutaria: '',
    modalEnableDevice: '',
    status: '',
  },
  computed: {
  },
  methods: {
  },
  getters: {
    getTableShipmentInfo(state) {
      return [
        {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.reference'),
          value: state.currentShip?.reference,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.device'),
          value: state.currentShip?.device,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.departure'),
          value: moment(state.currentShip?.dateStart).format('DD/MM/YYYY'),
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.origin'),
          value: state.currentShip?.origin,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.cargo'),
          value: state.currentShip.cargo,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.range'),
          value: state.currentShip?.range,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentInfo.licensePlate'),
          value: state.currentShip?.numberTransport,
        }];
    },
    getShipmentAverageTemp(state) {
      if (!state.reports.length) return '-/-';
      const parseValue = (val) => {
        if (val === '' || val === null) return 0;
        return Number.parseFloat(val);
      };
      let length = 0;
      // eslint-disable-next-line max-len
      function reducer(acc, val) {
        const accumulator = parseValue(acc.deviceTemp);
        const value = parseValue(val.deviceTemp);
        if (accumulator && value) {
          length += 1;
        }
        return { deviceTemp: accumulator + value };
      }

      const sum = state.reports.reduce(reducer);
      const averageTemp = Math.trunc((sum.deviceTemp / length) * 100) / 100;
      return averageTemp;
    },
    getCurrentAlerts(state) {
      const shipDetail = state.currentShip;
      // TODO: Daily
      const tempAlert = shipDetail?.alertTemperature;
      const intrusionAlert = shipDetail?.alertIntrusion;

      const alertsTranslated = [];

      if (tempAlert) alertsTranslated.push(i18n.t('deviceInfo.reports.table.tempAlert'));
      if (intrusionAlert) alertsTranslated.push(i18n.t('deviceInfo.reports.table.lightAlert'));

      return alertsTranslated.join(' ');
    },
    getShipmentOnRouteTime(state) {
      const shipDetail = state.currentShip;

      const deliveryDate = shipDetail.status === 39 ? moment(shipDetail?.dateDeactivate, 'DD/MM/YYYY') : moment(new Date());
      let shipmentDateStart = moment(shipDetail?.dateActivation, 'DD/MM/YYYY');
      let shipmentDateFirstReport = !state.reports.length ? null : moment(state.reports[0].dateTimeAcquiredUtc);
      if (shipmentDateFirstReport != null && shipmentDateStart.isBefore(shipmentDateFirstReport)) {
        shipmentDateStart = shipmentDateFirstReport;
      }
      const days = deliveryDate.diff(shipmentDateStart, 'days');
      const hours = moment.duration(deliveryDate.diff(shipmentDateStart)).hours();

      const translatedDays = `${days}${i18n.t('deviceInfo.information.sideTable.shipmentSummary.days')}`;
      const translatedHours = `${hours}${i18n.t('deviceInfo.information.sideTable.shipmentSummary.hours')}`;

      return `${days === 0 ? '' : translatedDays} ${hours === 0 ? '' : translatedHours}`;
    },
    getTableShipmentSummary(state, getters) {
      const shipDetail = state.currentShip;

      return [
        {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.lastReport'),
          value: state.currentShip?.lastReport,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.temperature'),
          value: `${shipDetail?.lastTemperature.toFixed(2)}°C`,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.latitude'),
          value: shipDetail?.latitude,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.longitude'),
          value: shipDetail?.longitude,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.location'),
          value: shipDetail?.lastLocation,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.actualAlert'),
          value: getters.getParseAlerts, // getters.getCurrentAlerts,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.lightAlerts'),
          value: getters.getNumLightAlert,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.outOfRange'),
          value: `${getters.getOutOfRangePercentage}%`,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.timeOnRoute'),
          value: getters.getShipmentOnRouteTime,
        }, {
          name: i18n.t('deviceInfo.information.sideTable.shipmentSummary.averageTemp'),
          value: `${getters.getShipmentAverageTemp}°C`,
        }];
    },
    getParseAlerts(state) {
      const shipDetail = state.currentShip;
      // TODO: Daily
      const tempAlert = shipDetail?.alertTemperature;
      const intrusionAlert = shipDetail?.alertIntrusion;

      // const alertsTranslated = [];

      // if (tempAlert) alertsTranslated.push(i18n.t('deviceInfo.reports.table.tempAlert'));
      // if (intrusionAlert) alertsTranslated.push(i18n.t('deviceInfo.reports.table.lightAlert'));

      const iconsArray = [];

      if (tempAlert) {
        iconsArray.push('tempHighIcon');
      } else {
        iconsArray.push('tempHighIconDefault');
      }
      if (intrusionAlert) {
        iconsArray.push('lightIcon');
      } else {
        iconsArray.push('lightIconDefault');
      }

      return iconsArray;
    },
    getOutOfRangePercentage(state) {
      if (!state.reports.length) return '-/-';
      function isOutOfRange(temp) {
        if (temp < state.currentShip.lowerTemperature || temp > state.currentShip.higherTemperature) return true;
        return false;
      }
      let outOfRangeCount = 0;
      state.reports.forEach((report) => {
        if (isOutOfRange(report.deviceTemp)) outOfRangeCount += 1;
      });

      const average = (outOfRangeCount / state.reports.length) * 100;
      return Math.trunc((average * 100) / 100);
    },
    getReports(state) {
      return state?.reports;
    },
    getReport(state) {
      return state?.report;
    },
    getTypeOfTransport() {
    // getTypeOfTransport(state) {
      // return state?.typeOfTransport;
      return [{
        text: i18n.t('launchTrip.typeTransport.land'),
        value: 134,
      }, {
        text: i18n.t('launchTrip.typeTransport.maritime'),
        value: 135,
      }, {
        text: i18n.t('launchTrip.typeTransport.aerial'),
        value: 136,
      }];
    },
    getUploadedFiles(state) {
      return state?.uploadedFiles;
    },
    getNumLightAlert(state) {
      if (!state.reports.length) return 0;
      let numAlert = 0;
      state.reports.forEach((report) => {
        if (report.alertCode === '906') numAlert += 1;
      });

      return numAlert;
    },
  },
  mutations: {
    UPDATE_SHIPMENTS_LIST(state, payload) {
      state.shipmentsList = payload;
    },
    UPDATE_REPORTS(state, payload) {
      state.reports = payload;
    },
    UPDATE_CURRENTSHIP(state, payload) {
      state.currentShip = payload;
    },
    UPDATE_REPORT(state, payload) {
      state.report = payload;
    },
    UPDATE_TYPEOFTRANSPORT(state, payload) {
      state.typeOfTransport = payload;
    },
    SET_DESCRIPTION(state, value) {
      state.description = value;
    },
    UPDATE_VALUES(state, value) { // TODO: ¿No habría que guardar esto en el currentShipment?
      state.deviceId = value.deviceId;
      state.cargo = value.cargo;
      state.carrier = value.carrier;
      state.dateActivation = value.dateActivation === '' ? null : moment(value.dateActivation, 'DD/MM/YYYY').format('MM/DD/YYYY');
      state.dateDeliver = value.dateDeliver === '' ? null : moment(value.dateDeliver, 'DD/MM/YYYY').format('MM/DD/YYYY');
      state.activationPlace = value.activationPlace;
      state.deliveryPlace = value.deliveryPlace;
      state.lowerTemperature = value.lowerTemperature;
      state.higherTemperature = value.higherTemperature;
      state.customer = value.customer;
      state.customerOfOurCustomer = value.customerOfOurCustomer;
      state.typeTransport = value.typeTransport;
      state.transportNum = value.transportNum;
      state.numberTruck = value.numberTruck;
      state.numberContainer = value.numberContainer;
      state.clientWeb = value.clientWeb;
      state.genericAttribute01 = value.genericAttribute01;
      state.genericAttribute02 = value.genericAttribute02;
      state.genericAttribute03 = value.genericAttribute03;
      state.genericAttribute04 = value.genericAttribute04;
      state.genericAttribute05 = value.genericAttribute05;
      state.recipientInfo = value.recipientInfo;
      state.recipientInfoName = value.recipientInfo.Identifier;

      // Control de referencia, cod pre referencia de frutaria.
      if (state.modalEnableDevice === 'frutaria') {
        if (value.customerRef !== '') {
          const indice = value.customerRef.indexOf('/');
          state.codPreReferenciaFrutaria = value.customerRef.slice(0, indice) === undefined ? '' : `${value.customerRef.slice(0, indice)}/`;
          state.customerRef = value.customerRef.slice(indice + 1) === undefined ? '' : value.customerRef.slice(indice + 1);
        } else {
          state.codPreReferenciaFrutaria = '';
          state.customerRef = '';
        }
      } else if (state.modalEnableDevice === 'royal') {
        if (value.customerRef !== '') {
          const indice = value.customerRef.indexOf('/');
          if (indice === -1) {
            state.customerRef = value.customerRef;
          } else {
            state.customerRef = value.customerRef;
            state.numeroPaletRoyal = value.customerRef.slice(0, indice) === undefined ? '' : value.customerRef.slice(0, indice);
            state.numeroPedidoRoyal = value.customerRef.slice(indice + 1) === undefined ? '' : value.customerRef.slice(indice + 1);
          }
        } else {
          state.numeroPaletRoyal = '';
          state.numeroPedidoRoyal = '';
          state.customerRef = '';
        }
      } else {
        state.customerRef = value.customerRef;
      }
      state.status = value.state;
    },
    INIT_STORE(state, value) { // TODO: ¿No habría que guardar esto en el currentShipment?
      state.deviceId = '';
      state.cargo = '';
      state.carrier = '';
      state.customerRef = '';
      state.dateActivation = moment().format('MM/DD/YYYY');
      state.dateDeliver = moment().format('MM/DD/YYYY');
      state.activationPlace = '';
      state.deliveryPlace = '';
      state.lowerTemperature = '';
      state.higherTemperature = '';
      state.customer = '';
      state.customerOfOurCustomer = '';
      state.typeTransport = '';
      state.transportNum = '';
      state.numberTruck = '';
      state.numberContainer = '';
      state.clientWeb = '';
      state.status = '';
    },
    SET_DEVICEID(state, value) { // TODO: ¿No habría que guardar esto en el currentShipment?
      state.deviceId = value;
    },
    SET_CARGO(state, value) {
      state.cargo = value;
    },
    SET_CARRIER(state, value) {
      state.carrier = value;
    },
    SET_CUSTOMERREF(state, value) {
      state.customerRef = value;
    },
    SET_DATEACTIVATION(state, value) {
      state.dateActivation = moment(new Date(new Date(value).valueOf()), 'DD/MM/YYYY').format('MM/DD/YYYY');
    },
    SET_DATEDELIVER(state, value) {
      state.dateDeliver = moment(new Date(new Date(value).valueOf()), 'DD/MM/YYYY').format('MM/DD/YYYY'); // hasta
    },
    SET_ACTIVAIONPLACE(state, value) {
      state.activationPlace = value;
    },
    SET_DELIVERYPLACE(state, value) {
      state.deliveryPlace = value;
    },
    SET_LOWERTEMPERATURE(state, value) {
      state.lowerTemperature = value;
    },
    SET_HIGHERTEMPERATURE(state, value) {
      state.higherTemperature = value;
    },
    SET_CUSTOMER(state, value) {
      state.customer = value;
    },
    SET_CUSTOMEROFOURCUSTOMER(state, value) {
      state.customerOfOurCustomer = value;
    },
    SET_TYPETRANSPORT(state, value) {
      state.typeTransport = value;
    },
    SET_TRANSPORTNUM(state, value) {
      state.transportNum = value;
    },
    SET_NUMBERTRUCK(state, value) {
      state.numberTruck = value;
    },
    SET_NUMBERCONTAINER(state, value) {
      state.numberContainer = value;
    },
    SET_CLIENTWEB(state, value) {
      state.clientWeb = value;
    },
    SET_DEVICE(state, value) {
      state.device = value;
    },
    SET_RECIPIENTINFO(state, value) {
      state.recipientInfo = value;
    },
    SET_RECIPIENTINFONAME(state, value) {
      state.recipientInfoName = value;
    },
    SET_GENERICATTRIBUTE01(state, value) {
      state.genericAttribute01 = value;
    },
    SET_GENERICATTRIBUTE02(state, value) {
      state.genericAttribute02 = value;
    },
    SET_GENERICATTRIBUTE03(state, value) {
      state.genericAttribute03 = value;
    },
    SET_GENERICATTRIBUTE04(state, value) {
      state.genericAttribute04 = value;
    },
    SET_GENERICATTRIBUTE05(state, value) {
      state.genericAttribute05 = value;
    },
    SET_NUMEROPALETROYAL(state, value) {
      state.numeroPaletRoyal = value;
    },
    SET_NUMEROPEDIDOROYAL(state, value) {
      state.numeroPedidoRoyal = value;
    },
    SET_CODPREREFERENCIAFRUTARIA(state, value) {
      state.codPreReferenciaFrutaria = value;
    },
    SET_MODALENABLEDEVICE(state, value) {
      state.modalEnableDevice = value;
    },
    SET_STATUS(state, value) {
      state.status = value;
    },
    SET_FILE_ATTACHMENT(state, payload) {
      state.file.Attachment = payload;
    },
    SET_FILE_NAME(state, payload) {
      state.file.Name = payload;
    },
    SET_FILE_DISPLAY_NAME(state, payload) {
      state.file.DisplayName = payload;
    },
    SET_FILE_DESCRIPTION(state, payload) {
      state.file.Description = payload;
    },
    SET_FILE_EXTENSION(state, payload) {
      state.file.Extension = payload;
    },
    SET_FILE_ID(state, payload) {
      state.file.Id = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    DOWNLOAD_FILE(state, payload) {
      state.downloadFileData = payload;
    },
    SET_STATUS_FILTER(state, payload) {
      state.shipmentsFilters.status = payload;
    },
    SET_DATES_FILTER(state, payload) {
      state.shipmentsFilters.dates = { ...state.shipmentsFilters.dates, ...payload };
    },
    CLEAR_FILTERS(state) {
      state.shipmentsFilters = {
        status: 1,
        dates: {
          init: moment(new Date()).subtract(3, 'months'),
          end: moment(new Date()),
        },
      };
      eventBus.$emit('filtersCleared');
    },
    CLEAR_FILE(state) {
      state.file = {
        Id: '',
        Description: '',
        Name: '',
        DisplayName: '',
        Extension: '',
        Attachment: '',
      };
    },
    SET_CURRENT_SHIP(state, payload) {
      const { device, genericAttributesNames } = payload;
      const rangeTemperature = (device.lowerTemperature === -40 && device.higherTemperature === 40) ? '' : `${device.lowerTemperature} ${i18n.t('shipping.to')} ${device.higherTemperature}ºC`;
      const genericAttributes = {};
      function getNumber(num) {
        if (num === 9) return 10;
        return `0${num + 1}`;
      }
      function updateDate(e) {
        if (moment(e, 'DD/MM/YYYY', true).isValid()) return moment(e, 'DD/MM/YYYY').toDate();
        if (moment(e, 'DD-MM-YYYY', true).isValid()) return moment(e, 'DD-MM-YYYY').toDate();

        return null;
      }
      function diffDate(date) {
        const dateGived = new Date(date).getTime();

        let now = new Date().getTime();
        now += new Date().getTimezoneOffset() * 60000;
        let diff = now - dateGived;
        const days = Math.floor(diff / 86400000);
        diff -= days * 86400000;
        const hour = Math.floor(diff / 3600000);
        diff -= hour * 3600000;
        const min = Math.floor(diff / 60000);

        let result = '';

        if (days > 0) {
          if (days === 1) {
            result += `${days}`;
            result += i18n.t('common.time.day');
          } else {
            result += `${days}`;
            result += i18n.t('common.time.day');
          }
        }

        if (hour > 0) {
          if (hour === 1) {
            result += ` ${hour}`;
            result += i18n.t('common.time.hour');
          } else {
            result += ` ${hour}`;
            result += i18n.t('common.time.hour');
          }
        }

        if (min > 0 && days === 0) {
          result += ` ${min}`;
          result += i18n.t('common.time.minute');
        }

        return result;
      }
      genericAttributesNames.forEach((attributeName, localIndex) => {
        genericAttributes[attributeName] = device[`genericAttribute${getNumber(localIndex)}`];
      });
      state.currentShip = {
        ...device,
        status: device.state,
        editPermissions: device.modificationPermissions,
        idDeviceReport: device.idDeviceReport,
        temperature: device.deviceTemp,
        lowerTemperature: device.lowerTemperature,
        higherTemperature: device.higherTemperature,
        range: rangeTemperature,
        location: device.lastLocation,
        origin: device.activationPlace,
        reference: device.customerRef,
        lastReport: diffDate(device.dateTimeAcquiredUtc),
        dateStart: updateDate(device.dateActivation),
        dateArrived: updateDate(device.dateDeliver),
        numberTransport: device.transportNum,
        carrier: device.carrier,
        destination: device.deliveryPlace,
        device: device.deviceId,
        cargo: device.cargo,
        alert: `${device.alertTemperature} ${device.alertIntrusion}`,
        ...genericAttributes,
      };
    },
  },
  actions: {
    setStatusFilter({ commit, dispatch }, status) {
      if (status !== 1 && status !== 0 && status !== -1) {
        throw new Error('Invalid status provided.');
      } else {
        commit('SET_STATUS_FILTER', status);
        // dispatch('fetchShipmentList');
        dispatch('fetchShipmentListOff');
      }
    },
    setDatesFilter({ commit, dispatch }, dates) {
      if (dates.init.isValid()) {
        commit('SET_DATES_FILTER', { init: dates.init });
      } else {
        throw new Error('Invalid init date provided.');
      }
      if (dates.end.isValid()) {
        commit('SET_DATES_FILTER', { end: dates.end });
      } else {
        throw new Error('Invalid init date provided.');
      }
    },
    clearFilters({ commit }) {
      commit('CLEAR_FILTERS');
    },
    async fetchShipmentList({ commit, state }) {
      // const data = await Devices.getDefault(state.shipmentsFilters);
      const data = await Api.devices.getAll(state.shipmentsFilters);
      commit('UPDATE_SHIPMENTS_LIST', JSON.parse(data.response));
    },
    async fetchShipmentListOff({ commit, state }) {
      // const data = await Devices.getDefault(state.shipmentsFilters);
      const data = await Api.devices.getAll(state.shipmentsFilters);
      commit('UPDATE_SHIPMENTS_LIST', JSON.parse(data.response));
    },
    async fetchReports({ commit }, id) {
      const data = await Api.devices.getReports(id);
      commit('UPDATE_REPORTS', JSON.parse(data.response));
    },
    async setCurrentShipById({ commit, rootGetters }, id) {
      if (id === null || id === '' || id === undefined) throw new Error('You must specify a valid ID');

      try {
        const data = await Api.devices.getOne(id); // TODO: REVISAR SI ESTO ESTA BIEN
        const device = JSON.parse(data.response);
        const genericAttributesNames = rootGetters['authentication/getGenericAttributesNames'];
        commit('SET_CURRENT_SHIP', { device, genericAttributesNames });
      } catch (error) {
        throw new Error('Something went wrong here...');
      }
    },
    async getInfoDeviceId({ commit }, id, modalEnableDevice) {
      try {
        if (id !== null && id !== '' && id !== undefined) {
          const xhr = await Api.devices.getOne(id);
          const device = JSON.parse(xhr.response);
          commit('UPDATE_VALUES', device, modalEnableDevice);
        }
        return Promise.resolve();
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('403')) {
          return Promise.reject(Helpers.getFilteredErrorMessage(e));
        }
        return Promise.reject(Helpers.getError(e));
      }
    },
    async activateDevice({ state }) {
      try {
        await Api.devices.activate(state.deviceId,
          {
            customerRef: state.customerRef,
            cargo: state.cargo,
            carrier: state.carrier,
            customer: state.customer,
            dateActivation: moment(state.dateActivation).format('YYYY-MM-DD[T]HH:mm:ss'),
            dateDeliver: moment(state.dateDeliver).format('YYYY-MM-DD[T]HH:mm:ss'),
            activationPlace: state.activationPlace,
            deliveryPlace: state.deliveryPlace,
            lowerTemperature: state.lowerTemperature,
            higherTemperature: state.higherTemperature,
            customerOfOurCustomer: state.customerOfOurCustomer,
            typeTransport: state.typeTransport,
            numberTruck: state.transportNum,
            numberTrailer: state.numberTrailer,
            numberContainer: state.numberContainer,
            clientWeb: state.clientWeb,
            recipientInfo: state.recipientInfo,
            genericAttribute01: state.genericAttribute01,
            genericAttribute02: state.genericAttribute02,
            genericAttribute03: state.genericAttribute03,
            genericAttribute04: state.genericAttribute04,
            genericAttribute05: state.genericAttribute05,
          });
        return Promise.resolve();
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('403')) {
          return Promise.reject(Helpers.getError(e));
        }
        return Promise.reject(Helpers.getError(e));
      }
    },
    async uploadFile({ state }) {
      if (state.file === {}) throw new Error('There is no file to be uploaded.');
      if (state.file.Id === '') throw new Error('There is no file to be uploaded.');
      if (state.file.Attachment === '') throw new Error('There is no file attached.');
      if (state.file.DisplayName === '') throw new Error('You must specify a name for the file.');
      if (state.file.Description === '') throw new Error('You must specify a description for the file.');

      const data = await Api.devices.uploadDocument(state.currentShip.device, state.file);
    },
    async getFilesList({ state, commit }, id) {
      const deviceID = id || state.currentShip.device;
      try {
        const data = await Api.devices.getDocuments(deviceID);
        const parsedData = JSON.parse(data.response);
        commit('SET_FILES', parsedData);
      } catch (error) {
        commit('SET_FILES', []);
      }
    },
    async downloadFile({ state, commit }, guid) {
      const data = await Api.devices.downloadDocument(state.currentShip.device, guid);
      commit('DOWNLOAD_FILE', JSON.parse(data.response));
    },
    async removeFile({ state, dispatch }, guid) {
      await Api.devices.removeDocument(state.currentShip.device, guid);
      await dispatch('getFilesList');
    },
    updateCurrentShip({ commit }, payload) {
      commit('UPDATE_CURRENTSHIP', payload);
    },
    updateReport({ commit }, payload) {
      commit('UPDATE_REPORT', payload);
    },
    setDevice(context, value) {
      context.commit('SET_DEVICE', value);
    },
    setDeviceId(context, value) {
      context.commit('SET_DEVICEID', value);
    },
    setCargo(context, value) {
      context.commit('SET_CARGO', value);
    },
    setCarrier(context, value) {
      context.commit('SET_CARRIER', value);
    },
    setCustomerRef(context, value) {
      context.commit('SET_CUSTOMERREF', value);
    },
    setDateActivation(context, value) {
      context.commit('SET_DATEACTIVATION', value);
    },
    setDateDeliver(context, value) {
      context.commit('SET_DATEDELIVER', value);
    },
    setActivationPlace(context, value) {
      context.commit('SET_ACTIVAIONPLACE', value);
    },
    setDeliveryPlace(context, value) {
      context.commit('SET_DELIVERYPLACE', value);
    },
    setLowerTemperature(context, value) {
      context.commit('SET_LOWERTEMPERATURE', value);
    },
    setHigherTemperature(context, value) {
      context.commit('SET_HIGHERTEMPERATURE', value);
    },
    setCustomer(context, value) {
      context.commit('SET_CUSTOMER', value);
    },
    setCustomerOfOurCustomer(context, value) {
      context.commit('SET_CUSTOMEROFOURCUSTOMER', value);
    },
    setTypeTransport(context, value) {
      context.commit('SET_TYPETRANSPORT', value);
    },
    setTransportNum(context, value) {
      context.commit('SET_TRANSPORTNUM', value);
    },
    setNumberTruck(context, value) {
      context.commit('SET_NUMBERTRUCK', value);
    },
    setNumberContainer(context, value) {
      context.commit('SET_NUMBERCONTAINER', value);
    },
    setClientWeb(context, value) {
      context.commit('SET_CLIENTWEB', value);
    },
    setRecipientInfo(context, value) {
      context.commit('SET_RECIPIENTINFO', value);
    },
    setRecipientInfoName(context, value) {
      context.commit('SET_RECIPIENTINFONAME', value);
    },
    setGenericAttribute01(context, value) {
      context.commit('SET_GENERICATTRIBUTE01', value);
    },
    setGenericAttribute02(context, value) {
      context.commit('SET_GENERICATTRIBUTE02', value);
    },
    setGenericAttribute03(context, value) {
      context.commit('SET_GENERICATTRIBUTE03', value);
    },
    setGenericAttribute04(context, value) {
      context.commit('SET_GENERICATTRIBUTE04', value);
    },
    setGenericAttribute05(context, value) {
      context.commit('SET_GENERICATTRIBUTE05', value);
    },
    setNumeroPaletRoyal(context, value) {
      context.commit('SET_NUMEROPALETROYAL', value);
    },
    setNumeroPedidoRoyal(context, value) {
      context.commit('SET_NUMEROPEDIDOROYAL', value);
    },
    setCodPreReferenciaFrutaria(context, value) {
      context.commit('SET_CODPREREFERENCIAFRUTARIA', value);
    },
    setModalEnableDevice(context, value) {
      context.commit('SET_MODALENABLEDEVICE', value);
    },
    setStatus(context, value) {
      context.commit('SET_STATUS', value);
    },
    initStore({ commit }) {
      commit('SET_DEVICEID', '');
      commit('SET_CARGO', '');
      commit('SET_CARRIER', '');
      commit('SET_CUSTOMERREF', '');
      commit('SET_ACTIVAIONPLACE', '');
      commit('SET_DELIVERYPLACE', '');
      commit('SET_LOWERTEMPERATURE', '');
      commit('SET_HIGHERTEMPERATURE', '');
      commit('SET_CUSTOMER', '');
      commit('SET_CUSTOMEROFOURCUSTOMER', '');
      commit('SET_TYPETRANSPORT', 134);
      commit('SET_TRANSPORTNUM', '');
      commit('SET_NUMBERTRUCK', '');
      commit('SET_NUMBERCONTAINER', '');
      commit('SET_CLIENTWEB', '');
      commit('SET_DATEACTIVATION', moment().format('MM/DD/YYYY')); // moment().format('DD/MM/YYYY'));
      commit('SET_DATEDELIVER', moment().format('MM/DD/YYYY')); // moment().format('DD/MM/YYYY'));
      commit('SET_DEVICE', {});
      commit('SET_RECIPIENTINFO', {});
      commit('SET_RECIPIENTINFONAME', '');
      commit('SET_GENERICATTRIBUTE01', '');
      commit('SET_GENERICATTRIBUTE02', '');
      commit('SET_GENERICATTRIBUTE03', '');
      commit('SET_GENERICATTRIBUTE04', '');
      commit('SET_GENERICATTRIBUTE05', '');
      commit('SET_NUMEROPALETROYAL', '');
      commit('SET_NUMEROPEDIDOROYAL', '');
      commit('SET_CODPREREFERENCIAFRUTARIA', '');
      commit('SET_MODALENABLEDEVICE', '');
      commit('SET_STATUS', '');
    },
    async deleteDeactivateDevice(_, id) {
      try {
        await Api.devices.remove(id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    clearFile({ commit }) {
      commit('CLEAR_FILE');
    },
    setFile({ commit }, file) {
      commit('SET_FILE_NAME', file.name);
      commit('SET_FILE_ATTACHMENT', file.attachment);
      commit('SET_FILE_EXTENSION', file.extension);
      commit('SET_FILE_ID', file.id);
    },
    setFileDisplayName({ commit }, displayName) {
      commit('SET_FILE_DISPLAY_NAME', displayName);
    },
    setFileDescription({ commit }, description) {
      commit('SET_FILE_DESCRIPTION', description);
    },
    setFileId({ commit }, id) {
      commit('SET_FILE_ID', id);
    },
  },
};
