import eventBus from '../../js/event-bus';
import $ from '../../js/i18n.config';

const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      name: 'editLoadingPoint',
      icon: 'edit',
      hint: $.t('common.toolTips.edit'),
    },
    {
      name: 'deleteLoadingPoint',
      icon: 'trash',
      hint: $.t('common.toolTips.delete'),
    },
  ],
};

export const colMethods = {
  openLoadingPointsPopup(e) {
    eventBus.$emit('showLoadingPointsPopup', e.row.data);
    eventBus.$emit('setMarkerPosition', e.row.data);
  },
  async removeLoadingPoint(e) {
    this.removeLocation(e.row.data.id);
  },
  addButtonFuncs(buttons) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'editLoadingPoint':
          button.onClick = this.openLoadingPointsPopup;
          break;
        case 'deleteLoadingPoint':
          button.onClick = this.removeLoadingPoint;
          break;
        default:
          break;
      }
    });
  },
};
export default [
  {
    dataField: 'name',
    caption: $.t('common.track.name'),
    dataType: 'string',
  },
  {
    caption: $.t('common.track.coordinates'),
    cellTemplate: 'coordinates-cell',
  },
  {
    dataField: 'isLoadPlace',
    caption: $.t('common.blocks.loadingPoint'),
    dataType: 'boolean',
  },
  {
    dataField: 'isDownloadPlace',
    caption: $.t('common.blocks.dischargePoint'),
    dataType: 'boolean',
  },
  buttonCol,
];
