var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxPopup',_vm._b({attrs:{"title":_vm.title,"visible":_vm.show,"on-hidden":_vm.onHidden,"height":"auto","width":620},on:{"update:visible":function($event){_vm.show=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxForm',{ref:"form",attrs:{"label-location":"top","form-data":_vm.formData,"show-colon-after-label":false,"on-field-data-changed":_vm.onFieldDataChanged}},[_c('DxSimpleItem',{attrs:{"data-field":"name","editor-type":"dxTextBox","is-required":true,"label":{
        text: _vm.$t('common.track.name')
      },"editor-options":{
        placeholder: _vm.$t('common.placeholder.selectName'),
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxSelectBox","data-field":"category","is-required":true,"label":{
        text: _vm.$t('administration.cargo.category')
      },"editor-options":{
        dataSource: _vm.merchandiseCategories,
        placeholder: _vm.$t('common.placeholder.selectCategory'),
      }}})],1),_vm._v(" "),_c('div',[_c('f7-icon',{attrs:{"icon":"info_circle_fill","size":15}})],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained","on-click":_vm.hidePopup}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.cancel"))+"\n    ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained","on-click":_vm.addCargos}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.save"))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }