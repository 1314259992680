<template>
  <f7-page class="main-protocols">
    <ProtocolsPopup v-if="loaded" />
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <f7-row class="justify-content-flex-end">
          <DxButton
            class="margin-left-half"
            :disabled="false"
            icon="plus"
            @click="showPopup"
          />
        </f7-row>
      </div>
      <div>
        <ProtocolsTable />
      </div>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../components/NavBar.vue';
import ProtocolsTable from '../../components/protocols/index.vue';
import ProtocolsPopup from '../../components/protocols/protocolsPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'ProtocolsPage',
  components: {
    Navbar,
    ProtocolsTable,
    ProtocolsPopup,
    DxButton,
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    eventBus.$on('protocolsLoaded', () => { this.loaded = true; });
  },
  methods: {
    showPopup() {
      eventBus.$emit('openProtocolsPopup');
    },
  },
};
</script>

<style lang="scss">
.main-protocols{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}

</style>
