var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxPopup',{attrs:{"visible":_vm.show,"on-hidden":_vm.onHidden,"title":_vm.$t('protocols.popup.titlePopup'),"close-on-outside-click":false,"drag-enabled":true,"position":"center","height":"auto","width":620},on:{"update:visible":function($event){_vm.show=$event}}},[_c('DxScrollView',[_c('DxForm',{ref:"form"},[_c('DxGroupItem',{attrs:{"col-count":1}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('protocols.popup.title'),"caption":_vm.$t('protocols.popup.title'),"editor-type":"dxTextBox","editor-options":{
            onValueChanged: _vm.onChangedTitle,
            value: _vm.title,
            placeholder: _vm.$t('protocols.popup.title'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('protocols.popup.titleRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('protocols.popup.description'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.description,
            onValueChanged: _vm.onChangedDescription,
            placeholder: _vm.$t('protocols.popup.description'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('protocols.popup.descriptionRequired')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}}),_vm._v(" "),_c('DxItem',{attrs:{"title":"TitleDocs"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('protocols.popup.documents')))])]},proxy:true}])}),_vm._v(" "),_c('DxItem',{attrs:{"title":"Documents"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxDataGrid',{attrs:{"v-bind":_vm.dataGrid,"data-source":_vm.protocolDocs,"focused-row-enabled":true,"key-expr":"Id","columns":_vm.docsColumns,"on-toolbar-preparing":_vm.onToolbarPreparing,"show-borders":true,"show-column-lines":true,"show-row-lines":true,"editing":{
              allowAdding: true,
              allowDeleting: true,
              allowUpdating: true,
              mode: 'cell',
              editColumnName: '',
              useIcons: true,
            }},scopedSlots:_vm._u([{key:"editTooltip",fn:function(){return [_c('p',[_c('i',[_vm._v(_vm._s(_vm.$t('protocols.popup.editTooltip')))])])]},proxy:true}])})]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained"},on:{"click":_vm.cancelForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.cancel"))+"\n      ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained"},on:{"click":_vm.validateForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.save"))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }