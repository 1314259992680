<template>
  <f7-card
    :title="$t('common.blocks.carriers')"
    class="card-block"
  >
    <f7-block>
      <f7-row>
        <DxDataGrid
          v-bind="commonProps.dataGrid"
          :data-source="carriers"
          :columns="config"
          class="margin-bottom"
          :on-toolbar-preparing="onToolbarPreparing"
          :editing="{
            allowAdding: true,
            allowDeleting: true,
            allowUpdating: true,
            mode: 'cell',
            editColumnName: '',
            useIcons: true,
          }"
        >
          <template #editTooltip>
            <p><i>{{ $t('trips.messageEditCelds') }}</i></p>
          </template>
        </DxDataGrid>
      </f7-row>
    </f7-block>
  </f7-card>
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';
import { commonProps } from '../../../components/trips/tripsColumnData';

export default {
  name: 'Carriers',
  components: {
    DxDataGrid,
  },
  data() {
    return {
      commonProps,
    };
  },
  computed: {
    config() {
      return [
        {
          dataField: 'name',
          dataType: 'string',
          caption: this.$t('common.track.name'),
        },
        {
          dataField: 'plate',
          dataType: 'string',
          caption: this.$t('common.track.carries.plate'),
        },
        {
          dataField: 'transportType',
          caption: this.$t('administration.carrier.columns.transportType'),
          dataType: 'string',
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: ['Maritime', 'Land', 'Aerial'],
          },
          alignment: 'center',
        },
        {
          dataField: 'orderNumber',
          caption: this.$t('administration.carrier.columns.orderNumber'),
          lookup: {
            dataSource: this.orders,
            displayExpr: 'reference',
            valueExpr: 'reference',
          },

        },
      ];
    },
    ...mapGetters('Trips', ['carriers', 'orders']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
    } catch (error) {
      console.error(error);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'editTooltip',
      });
    },
  },
};
</script>

<style lang='scss'>
::v-deep .dx-datagrid-revert-tooltip {
  display: none;
}
</style>
