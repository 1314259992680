import $ from '../../../../js/i18n.config';

export default [{
  dataField: 'name',
  caption: $.t('administration.carrier.columns.orderNumber'),
  dataType: 'string',
}, {
  dataField: 'plate',
  caption: $.t('common.track.carries.plate'),
  dataType: 'string',
  alignment: 'center',
},
{
  dataField: 'transportType',
  caption: $.t('administration.carrier.columns.transportType'),
  dataType: 'string',
  alignment: 'center',
},
];
