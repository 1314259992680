<template>
  <!-- //TODO: aHORA NO SE PINTA TEMPERATURA, LOCALIZACION Y HUMEDAD -->
  <DxDataGrid
    ref="dataGrid"
    v-bind="commonProps.dataGrid"
    :data-source="devices"
    key-expr="deviceId"
    :columns="deviceColumns"
  >
    <template #status-cell="{ data: datos }">
      <div>
        <i :class="setClassStatus(datos)" />
      </div>
    </template>

    <template
      #temp-cell="{data}"
    >
      <span :class="`temp-${getTempClass(data)}`">
        {{ data.value !== null ? data.value +'°C' : ' ' }}</span>
    </template>

    <template #median-temp-cell="{data}">
      <Suspense :get-data="createMedianTempFunc(data)">
        <template #default="{data}">
          {{ `${getMedianTemp(data)} °C` }}
        </template>
      </Suspense>
    </template>

    <template #out-of-range-cell="{data}">
      <Suspense :get-data="createOutOfRangeFunc(data)">
        <template #default="{data}">
          {{ `${getOutOfRangePercentage(...data)}%` }}
        </template>
      </Suspense>
    </template>

    <template #alert-cell="{data}">
      <div class="content-alert">
        <img
          v-for="svg in parseAlerts(data)"
          :key="svg"
          :src="svg"
          class="alert-icon"
        >
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import deviceColumns from './devicesTabColumnsData';
import Suspense from './suspenseData/index.vue';
import Api from '../../../../services/Api';
import tempHighIcon from '../../../../static/icons/ic_high_level.svg';
import tempHighIconDefault from '../../../../static/icons/ic_level.svg';
import lightIcon from '../../../../static/icons/ic_high_rute.svg';
import lightIconDefault from '../../../../static/icons/ic_light_off.svg';
import { commonProps } from '../../tripsColumnData';

export default {
  name: 'DevicesTab',
  components: {
    Suspense,
    DxDataGrid,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      deviceColumns,
      commonProps,
      devices: this.data.data.devices,
    };
  },
  computed: {
    dataGrid() {
      return this.$refs.dataGrid.instance;
    },
    ...mapState('Shipments', ['shipmentsList']),
  },
  methods: {
    parseAlerts(data) {
      if (data.data.value === undefined || data.data.value === 'undefined' || data.data.value === 'NaN') return [];
      const { temp, intrusion } = data.data.value;
      const iconsArray = [];
      if (temp === undefined || temp === 'undefined' || temp === 'NaN') {
        iconsArray.push(tempHighIconDefault);
      } else if (temp) {
        iconsArray.push(tempHighIcon);
      } else {
        iconsArray.push(tempHighIconDefault);
      }
      if (intrusion) {
        iconsArray.push(lightIcon);
      } else {
        iconsArray.push(lightIconDefault);
      }

      return iconsArray;
    },
    createMedianTempFunc(data) {
      const { deviceId } = data.data;

      return async function getReports() {
        const response = await Api.getReports(deviceId);
        const parsedData = JSON.parse(response.response);
        return parsedData;
      };
    },
    createOutOfRangeFunc(data) {
      const { deviceId, higherTemperature, lowerTemperature } = data.data;

      return async function getReports() {
        const response = await Api.getReports(deviceId);
        const parsedData = JSON.parse(response.response);
        return [parsedData, { min: lowerTemperature, max: higherTemperature }];
      };
    },
    getMedianTemp(reports) {
      if (!reports.length) return '-/-';
      const parseValue = (val) => {
        if (val === '' || val === null) return 0;
        return Number.parseFloat(val);
      };
      let length = 0;
      // eslint-disable-next-line max-len
      function reducer(acc, val) {
        const accumulator = parseValue(acc.deviceTemp);
        const value = parseValue(val.deviceTemp);
        if (accumulator && value) {
          length += 1;
        }
        return { deviceTemp: accumulator + value };
      }

      const sum = reports.reduce(reducer);
      const averageTemp = Math.trunc((sum.deviceTemp / length) * 100) / 100;
      return averageTemp;
    },
    getOutOfRangePercentage(reports, range) {
      if (!reports || !reports.length) return '-/-';
      function isOutOfRange(temp) {
        if (temp < range.min || temp > range.max) return true;
        return false;
      }
      let outOfRangeCount = 0;
      reports.forEach((report) => {
        if (isOutOfRange(report.deviceTemp)) outOfRangeCount += 1;
      });

      const average = (outOfRangeCount / reports.length) * 100;
      return Math.trunc((average * 100) / 100);
    },
    setClassStatus(datos) {
      if (datos.data.status === 39) return 'badge blob'; // Desactivado
      if (datos.data.status === 37 && datos.data.idDeviceReport <= 0) return 'badge blob rfid-off'; // Pendiente

      return 'badge blob rfid-on'; // Activado
    },
    getTempClass(row) {
      const range = { min: row.data.lowerTemperature, max: row.data.higherTemperature };
      const temp = Number.parseFloat(row.value);
      if (temp === undefined || temp === 'undefined ' || temp === 'NaN') return undefined;
      if (temp < range.min) return 'low';
      if (temp > range.max) return 'high';

      return undefined;
    },
    ...mapActions('Shipments', ['fetchShipmentList']),
  },
};
</script>

<style>
@import url('devicesTab.styles.scss');
</style>
