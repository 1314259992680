import $ from '../../../../js/i18n.config';

const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      icon: 'find',
      hint: $.t('common.toolTips.moreInfo'),
    },
  ],
};
const calculateRangeTemp = (row) => {
  const rangeTemperature = (row.lowerTemperature === -40 && row.higherTemperature === 40) ? ''
    : `${row.lowerTemperature} ${$.t('shipping.to')} ${row.higherTemperature}º`;

  return rangeTemperature;
};

export default [{
  dataField: 'state',
  caption: $.t('common.track.state'),
  dataType: 'number',
  cellTemplate: 'status-cell',
  alignment: 'center',
}, {
  caption: $.t('common.track.temperatureRange'),
  alignment: 'center',
  calculateCellValue: calculateRangeTemp,
}, {
  dataField: 'deviceTemp',
  caption: $.t('common.track.temperature'),
  dataType: 'number',
  alignment: 'center',
  cellTemplate: 'temp-cell',
}, {
  dataField: 'lastLocation',
  caption: $.t('common.track.lastLocation'),
  dataType: 'string',
}, {
  dataField: 'humidity',
  caption: $.t('common.track.humidity'),
  dataType: 'number',
  format: "#'%'",
}, {
  caption: $.t('common.temperature.medianTemperature'),
  dataType: 'number',
  cellTemplate: 'median-temp-cell',
}, {
  caption: $.t('common.track.outOfRange'),
  dataType: 'number',
  cellTemplate: 'out-of-range-cell',
}, {
  caption: $.t('common.track.alerts'),
  dataType: 'object',
  alignment: 'center',
  calculateCellValue: (row) => ({ temp: row.alertTemperature, intrusion: row.alertIntrusion }),
  cellTemplate: 'alert-cell',
},
buttonCol,
];
