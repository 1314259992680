<template>
  <f7-page>
    <NavBarVue />
    <AddOrderPopup />
    <RemoveOrderPopup />
    <div class="wrapper main-registertrip margin-top">
      <f7-row>
        <f7-col>
          <TripInfo />
          <Orders />
          <Carriers />
          <Devices />
        </f7-col>
      </f7-row>
      <div class="content-buttons margin-vertical">
        <DxButton
          class="dx-btn-success padding-half"
          styling-mode="contained"
          @click="goBack"
        >
          Cancelar
        </DxButton>

        <DxButton
          class="dx-btn-success padding-half margin-left-half"
          type="success"
          styling-mode="contained"
          @click="addPedido"
        >
          Guardar
        </DxButton>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DxButton from 'devextreme-vue/button';
import NavBarVue from '../../components/NavBar.vue';
import AddOrderPopup from './addOrderPopup/index.vue';
import TripInfo from './tripInfo/index.vue';
import Orders from './orders/index.vue';
import Carriers from './carriers/index.vue';
import Devices from './devices/index.vue';
import RemoveOrderPopup from './removeOrderPopup/index.vue';

export default {
  name: 'RegisterTripPage',
  components: {
    RemoveOrderPopup,
    AddOrderPopup,
    NavBarVue,
    TripInfo,
    Orders,
    Carriers,
    Devices,
    DxButton,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('Trips', ['formatedTripData']),
  },
  beforeMount() {
    this.initializeRegisterPage();
    if (this.isEditing) {
      this.fillFieldsWithData(this.data);
    }
  },
  methods: {
    goBack() {
      this.$f7.views.main.router.back();
    },
    addPedido() {
      this.postTrip(this.formatedTripData);
    },
    ...mapActions('Trips', ['initializeRegisterPage', 'fillFieldsWithData', 'postTrip']),
  },
};
</script>

<style lang="scss">
.wrapper{
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  opacity: 0;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 1;
}
[theme='dark'] {
  .wrapper{
    background: var(--f7-theme-color-gray);
  }
}
.main-registertrip{
  .block-title{
    font-size: 22px;
    line-height: 1.4;
  }
}

</style>
