import { mapGetters } from 'vuex';
import HomePage from '../pages/home.vue';
// Accounts
import Login from '../pages/accounts/loginPage.vue';
import Logout from '../pages/accounts/logout.vue';
import GeneralPage from '../pages/analytics/general.vue';
import InitialPage from '../pages/analytics/initial.vue';
import ShipInformation from '../pages/shipInformation/deviceInfo.vue';
import TripsPage from '../pages/trips/trips.vue';
import NotificationsPage from '../pages/notifications/notifications.vue';
import LoadingPointsPage from '../pages/loadingPoints/loadingPoints.vue';
import RegisterTripPage from '../pages/registerTrip/registerTrip.vue';
import CarriersPage from '../pages/carriers/carriers.vue';
import CargosPage from '../pages/cargos/cargos.vue';
import ComplaintsPage from '../pages/complaints/complaints.vue';
import Devices from '../pages/devices/devices.vue';
import ProtocolsPage from '../pages/protocols/protocols.vue';
import AdministrationColumnsDevices from '../pages/administrationColumns/devices/administrationColumnsDevices.vue';

// Others
import NotFoundPage from '../pages/404.vue';
import store from '../store';
// TODO: Hablado en reunión: comentar mientras está en pruebas
const panelLoad = store.state.authentication.appConfig.defaultPanelLoad;
let ruta = Devices;

if (panelLoad === 'Shipments') ruta = TripsPage; else ruta = Devices;

const routes = [
  // Default
  {
    path: '/',
    component: ruta,
    pushState: true,
  },
  // Accounts
  {
    path: '/accounts/loginPage/',
    component: Login,
  },
  {
    path: '/accounts/logout/',
    component: Logout,
  },
  // analytics
  {
    path: '/analytics/general/',
    component: GeneralPage,
  },
  {
    path: '/analytics/initial/',
    component: InitialPage,
  },
  {
    path: '/notifications/notifications/',
    component: NotificationsPage,
  },
  // Administration
  {
    path: '/administration/loadingPoints/',
    component: LoadingPointsPage,
  },
  {
    path: '/administration/carriers',
    component: CarriersPage,
  },
  {
    path: '/administration/cargos',
    component: CargosPage,
  },
  {
    path: '/administration/columnsDevices',
    component: AdministrationColumnsDevices,
  },
  // Others
  {
    path: '/home/',
    component: ruta,
  },
  {
    path: '/shipInformation/deviceInfo/:deviceId',
    component: ShipInformation,
    pushState: true,
  },
  {
    path: '/devices/',
    component: Devices,
    pushState: true,
  },
  {
    path: '/trips/',
    component: TripsPage,
    pushState: true,
  },
  {
    path: '/trips/register',
    component: RegisterTripPage,
    pushState: true,
  },
  {
    path: '/complaints/',
    component: ComplaintsPage,
    pushState: true,
  },
  {
    path: '/protocols/',
    component: ProtocolsPage,
    pushState: true,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
