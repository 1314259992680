import $ from '../../../../js/i18n.config';

export default [
  {
    dataField: 'name',
    caption: $.t('common.track.name'),
    dataType: 'string',
    alignment: 'center',
  },
  {
    dataField: 'description',
    caption: $.t('common.track.description'),
    dataType: 'string',
    alignment: 'center',
  },
  {
    dataField: 'amount',
    caption: $.t('complaints.amount'),
    dataType: 'number',
    alignment: 'center',
  },
  {
    dataField: 'isAccepted',
    caption: $.t('complaints.accepted'),
    dataType: 'boolean',
    alignment: 'center',
  },
  {
    dataField: 'creatorUser',
    caption: $.t('complaints.creatorUser'),
    dataType: 'string',
    alignment: 'center',
  },
];
