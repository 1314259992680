import $ from '../../../js/i18n.config';

export default [
  {
    dataField: 'Title',
    caption: $.t('protocols.popup.title'),
    dataType: 'string',
  },
  {
    dataField: 'Description',
    caption: $.t('protocols.popup.description'),
    dataType: 'string',
  },
  {
    dataField: 'Scope',
    caption: $.t('protocols.popup.scope'),
    editorType: 'dxSelectBox',
    dataType: 'string',
    lookup: {
      dataSource: [
        { text: $.t('protocols.scopes.default'), value: 1 },
        { text: $.t('protocols.scopes.transport'), value: 2 },
        { text: $.t('protocols.scopes.client'), value: 3 },
        { text: $.t('protocols.scopes.pointOfload'), value: 4 },
      ],
      displayExpr: 'text',
      valueExpr: 'value',
    },
  },
];
