<template>
  <div
    v-if="$helpers.havePermission(getPermissions.menu_notifications)"
    class="main-notificaciones"
  >
    <div>
      <f7-link popover-open=".popover-notifications">
        <div class="right">
          <a
            :title="notifications.length"
            class="link button-notification"
          >
            <img
              width="48"
              height="48"
              :src="icNotification"
            >
            <span
              v-if="notifications.length > 0"
              class="badge color-red"
            />
          </a>
        </div>
      </f7-link>
    </div>
    <f7-popover class="popover-notifications">
      <f7-card class="no-margin">
        <f7-card-header>
          <div class="title">
            <span class="badge color-red">{{ notifications.length }}</span>
            {{ $t('notifications.title') }}
          </div>
          <div class="right">
            <span @click="readAllNotifications">{{ $t('notifications.readAllNotifications') }}</span>
          </div>
        </f7-card-header>

        <f7-card-content :padding="true">
          <f7-list
            class="main-list"
          >
            <f7-list-group
              v-for="notificationType in notificationsArray"
              :key="notificationType.name"
              media-list
            >
              <f7-list-item
                group-title
                class="notification-group-title"
                :title="notificationType.name"
              />
              <f7-list-item
                v-for="item in notificationType.notifications"
                :key="item.id"
                class="notification-item"
                :chevron-center="true"
                :footer="`Hace ${diffDate(item.InsertedDate)}`"
                :link="createLink(item)"
              >
                <template
                  v-if="item.Resources[0].IdType === 1"
                  slot="inner-start"
                >
                  <div class="item-title">
                    {{ $t('notifications.newDocument') }}
                  </div>
                  <div class="item-text">
                    <span><b>{{ formatName(item.User) }}</b> {{ $t('notifications.addOne') }}
                      <f7-link
                        popover-close=".popover-notifications"
                        :href="`/shipInformation/deviceInfo/${item.Resources[1].Object.deviceId}/`"
                        :route-props="{currentTab: 'documents', redraw: false, docId: item.Resources[0].Object.Id}"
                        target="_blank"
                        :force="true"
                      >{{ $t('notifications.newDocument') }}
                        <b>, {{ item.Resources[0].Object.DisplayName }}<b /></b>
                      </f7-link>
                      <span v-if="item.Resources[1].Object !== null">
                        {{ $t('notifications.inDevice') }}
                        <f7-link
                          popover-close=".popover-notifications"
                          :href="`/shipInformation/deviceInfo/${item.Resources[1].Object.deviceId}/`"
                          :route-props="{currentTab: 'information', redraw: false}"
                          target="_blank"
                          :force="true"
                        >
                          <b>{{ item.Resources[1].Object.deviceId }}</b>
                        </f7-link>
                      </span>
                    </span>
                  </div>
                </template>
                <template
                  v-else-if="item.Resources[0].IdType === 2"
                  slot="inner-start"
                >
                  <div class="item-title">
                    {{ $t('notifications.newDevice') }}
                  </div>
                  <div class="item-text">
                    <span><b>{{ formatName(item.User) }}</b>{{ $t('notifications.addNewDevice') }}
                      <b>
                        <f7-link
                          popover-close=".popover-notifications"
                          :href="`/shipInformation/deviceInfo/${item.Resources[0].ReferenceObject}/`"
                          :route-props="{currentTab: 'information', redraw: false}"
                          target="_blank"
                          :force="true"
                        >{{ item.Resources[0].ReferenceObject }} </f7-link>
                      </b>
                    </span>
                  </div>
                </template>
                <div
                  slot="content-start"
                  class="content-notification-status"
                  @click="readNotification(item)"
                >
                  <div
                    class="is-read-container"
                    :title="!item.Read ? 'Marcar como leído' : 'Leído'"
                  >
                    <div
                      :class="{'not-read': !item.Read}"
                      class="round-badge"
                    />
                  </div>
                </div>
              </f7-list-item>
            </f7-list-group>
            <div class="button-container">
              <DxButton
                :text="$t('notifications.allNotifications')"
                @click="goToNotifications"
              />
            </div>
          </f7-list>
        </f7-card-content>
      </f7-card>
    </f7-popover>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import icClosePanel from '../../static/icons/ic_close_panel.svg';
import { MILISECONDS_TO_REFRESH_NOTIFICATIONS } from '../../js/constants';
import icNotification from '../../static/icons/ic_notification.svg';

export default {
  name: 'Notificaciones',
  components: {
    DxButton,
  },
  data() {
    return {
      verMas: false,
      icNotification,
      icClosePanel,
      visible: true,
      intervals: [],
    };
  },
  computed: {
    ...mapGetters('Notifications', ['getNotifications']),
    notifications() {
      const notificationsLimit = 10;
      const hoy = moment(new Date());
      const anteayer = hoy.clone().subtract(2, 'days');
      const notificaciones = [...this.getNotifications].map((item) => ({ ...item, InsertedDate: moment(new Date(item.InsertedDate)) }));

      // Ordenamos notificaciones según más reciente y limitamos a 10 notif. max.
      notificaciones
        .sort((a, b) => b.InsertedDate.diff(a.InsertedDate))
        .splice(notificationsLimit);

      const today = notificaciones
        .filter((item) => item.InsertedDate.isSame(hoy, 'day'));
      const yesterday = notificaciones
        .filter((item) => item.InsertedDate.isBetween(anteayer, hoy, 'day'));
      const older = notificaciones
        .filter((item) => item.InsertedDate.isSameOrBefore(anteayer, 'day'));

      return {
        length: notificaciones.length,
        today,
        yesterday,
        older,
      };
    },
    notificationsArray() {
      const { today, yesterday, older } = { ...this.notifications };
      const array = [
        { name: 'Hoy', notifications: today },
        { name: 'Ayer', notifications: yesterday },
        { name: 'Anteriores', notifications: older }];
      const filteredArray = array.filter((item) => item.notifications.length !== 0);

      return filteredArray;
    },
    ...mapGetters('authentication', [
      'getPermissions',
    ]),
  },
  created() {
    this.update();
    this.intervals.push(setInterval(() => this.$forceUpdate(), 1000 * 60));
    setInterval(this.update, MILISECONDS_TO_REFRESH_NOTIFICATIONS);
  },
  beforeUnmount() {
    this.intervals.forEach((interval) => clearInterval(interval));
  },
  methods: {
    createLink() {
      return '#';
    },
    goToInformation(item) {
      this.$f7.popover.close('.popover-notifications');
      this.$f7.views.main.router.navigate(`/shipInformation/deviceInfo/${item.Resources[1].Object.deviceId}/`,
        { reloadCurrent: true, force: true, ignoreCache: true });
    },
    diffDate(date) {
      const now = moment();
      const duration = moment.duration(now.diff(date));
      const units = ['seconds', 'minutes', 'hours', 'days', 'months'];
      const filteredUnits = units.filter((unit) => duration[unit]() !== 0);

      const months = this.$t('common.date.monthsAgo', { count: duration.months() });
      const days = this.$t('common.date.daysAgo', { count: duration.days() });
      const hours = this.$t('common.date.hoursAgo', { count: duration.hours() });
      const minutes = this.$t('common.date.minutesAgo', { count: duration.minutes() });
      const seconds = this.$t('common.date.secondsAgo');
      const and = this.$t('notifications.popover.and', { count: duration.days() });

      if (filteredUnits.includes('months')) return months;
      if (filteredUnits.includes('days') && filteredUnits.includes('hours')) return days + and + hours;
      if (filteredUnits.includes('days') && !filteredUnits.includes('hours')) return days;
      if (filteredUnits.includes('hours') && filteredUnits.includes('minutes')) return hours + and + minutes;
      if (filteredUnits.includes('hours') && !filteredUnits.includes('minutes')) return hours;
      if (filteredUnits.includes('minutes')) return minutes;
      return seconds;
    },
    goToNotifications() {
      this.$f7.popover.close('.popover-notifications');
      this.$f7.views.main.router.navigate('/notifications/notifications/');
    },
    async update() {
      try {
        await this.fetchNotifications();
      } catch (error) {
        this.$f7.dialog.alert(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    formatName(name) {
      return name
        .split('.')
        .map((item) => {
          item[0].toUpperCase();
          return item;
        })
        .join(' ');
    },
    async readNotification(item) {
      await this.removeNotification(item.Id);
    },
    async readAllNotifications() {
      await this.removeNotifications();
    },
    ...mapActions('Notifications', ['fetchNotifications', 'removeNotifications', 'removeNotification', 'updateTime']),
  },
};
</script>
<style lang="scss">
@import "./Notificaciones.styles.scss";
.popover .list + .list{
  margin-top: 30px;
}
.button-notification {
  position: relative;
  .badge {
    position: absolute;
    zoom: 0.6;
    top: 24px;
    right: 22px;
  }
}
.homefilters-icon-view {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a.link {
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #556080;
  }
  a.link:hover {
    text-decoration: underline;
  }
}
.popover-notifications{
  .card-header{
    img{
      filter: brightness(0.5);
    }
    .title{
      display: flex;
      align-items: center;
      span{
        margin-right: 5px;
      }
    }
  }
  .card-block.card {
    border: none;
  }
  .card-body{
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  .list{
    .item-link{
      .item-inner:before{
        right: 0;
      }
    }
    > .block-title:first-child{
      margin-bottom: 10px;
    }
  }
  --f7-list-group-title-height: 35px;
    .notification-item{
      padding-top: 10px;
      .item-inner{
      padding-top: 0;
      .item-text{
        .link{
          display: contents;
        }
      }
      }
    }
  .content-notification-status{
    align-self: flex-start;
  }
}

</style>
