<template>
  <f7-preloader
    v-if="loading && !error"
    :size="15"
  />
  <div v-else-if="!loading && error">
    Something went wrong...
  </div>
  <div v-else>
    <slot
      name="default"
      :data="data"
    />
  </div>
</template>

<script>
export default {
  name: 'Suspense',
  props: {
    getData: {
      type: Function,
      default: async () => {},
    },
  },
  data() {
    return {
      data: null,
      loading: true,
      error: false,
    };
  },
  async mounted() {
    try {
      this.data = await this.getData();
      this.error = false;
      this.loading = false;
    } catch (error) {
      this.error = true;
    }
  },
  methods: {

  },
};
</script>
