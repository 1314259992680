var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-reports"},[_c('f7-row',{attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"25"}},[_c('Table',{attrs:{"title":_vm.shipmentInfo.title,"data":_vm.shipmentInfo.data}}),_vm._v(" "),_c('Table',{attrs:{"title":_vm.shipmentSummary.title,"data":_vm.shipmentSummary.data}})],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"75"}},[_c('f7-card',{staticClass:"card-block"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('deviceInfo.reports.trackingTrip'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('DxDataGrid',{attrs:{"data-source":_vm.reports,"show-borders":false,"show-column-lines":true,"show-row-lines":true,"allow-column-resizing":true,"focused-row-enabled":true,"key-expr":"dateTimeAcquiredUtc","row-alternation-enabled":_vm.rowAlternationEnabled},on:{"cell-prepared":_vm.onCellPrepared},scopedSlots:_vm._u([{key:"date-template",fn:function(ref){
var data = ref.data;
return [_vm._v("\n              "+_vm._s(_vm.formatDate(data.value))+"\n            ")]}},{key:"status-template",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"status",class:_vm.getStatusClass(data)})]}},{key:"temp-cell",fn:function(ref){
var data = ref.data;
return [_c('span',{class:("temp-" + (_vm.getTempCellColor(data)))},[_vm._v("\n                "+_vm._s(data.value !== null ? data.value.toFixed(2) +'º' : ' '))])]}},{key:"alert-template",fn:function(ref){
var data = ref.data;
return [(_vm.getAlertIcon(data))?_c('div',[_c('img',{key:_vm.getAlertIcon(data),attrs:{"src":_vm.getAlertIcon(data),"width":"24","height":"24"}})]):_vm._e()]}}])},[_c('DxHeaderFilter',{attrs:{"visible":true}}),_vm._v(" "),_c('DxFilterRow',{attrs:{"visible":true}}),_vm._v(" "),_c('DxPager',{attrs:{"allowed-page-sizes":[50, 100, 500, 1000],"show-page-size-selector":true,"show-navigation-buttons":true}}),_vm._v(" "),_c('DxPaging',{attrs:{"page-size":16}}),_vm._v(" "),_c('DxPager',{attrs:{"show-page-size-selector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"dateTimeAcquiredUtc","data-type":"date","width":160,"cell-template":"date-template","caption":_vm.$t('common.date.dateStart')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"deviceTemp","caption":'ºC',"alignment":"center","width":90,"data-type":"number","cell-template":"temp-cell","calculate-filter-expression":_vm.getTempFilters,"allow-header-filtering":true,"allow-sorting":false}},[_c('DxHeaderFilter',{attrs:{"data-source":_vm.createFilters}})],1),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"eventCity","caption":_vm.$t('common.address.location'),"allow-header-filtering":true,"allow-sorting":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"eventZip","caption":_vm.$t('common.address.postalCode'),"allow-header-filtering":true,"alignment":"left","allow-sorting":false}}),_vm._v(" "),_vm._l((_vm.extraCols),function(col){return _c('DxColumn',{key:col.key,attrs:{"data-field":col.key,"caption":((_vm.$t(("shipping." + (col.key)))) + " " + (col.unit)),"format":"#'%'","alignment":"center","data-type":"number","allow-header-filtering":false,"allow-sorting":false}})}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"alertCode","width":110,"caption":_vm.$t('common.track.alert'),"cell-template":"alert-template","alignment":"center","allow-sorting":false}},[_c('DxHeaderFilter',{attrs:{"data-source":_vm.alertCodes}})],1)],2)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }