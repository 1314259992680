<template>
  <DxPopup
    :visible.sync="show"
    :on-hidden="onHidden"
    :title="$t('complaints.popup.titlePopup')"
    :close-on-outside-click="false"
    :drag-enabled="true"
    position="center"
    class="content-complaints-popup"
  >
    <DxScrollView>
      <DxForm ref="form">
        <DxGroupItem :col-count="1">
          <DxSimpleItem
            :data-field="$t('complaints.popup.name')"
            :caption="$t('complaints.popup.name')"
            editor-type="dxTextBox"
            :editor-options="{
              onValueChanged: onChangedName,
              value: name,
              placeholder: $t('complaints.popup.name'),
            }"
          >
            <DxRequiredRule :message="$t('complaints.popup.nameRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('complaints.popup.description')"
            editor-type="dxTextBox"
            :editor-options="{
              value: description,
              onValueChanged: onChangedDescription,
              placeholder: $t('complaints.popup.description'),
            }"
          >
            <DxRequiredRule :message="$t('complaints.popup.descriptionRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('complaints.popup.amount')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: amount,
              onValueChanged: onChangedAmount,
              placeholder: $t('complaints.popup.amount'),
            }"
          >
            <DxRequiredRule :message="$t('complaints.popup.amountRequired')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem :col-count="3" />
      </DxForm>
      <div class="content-buttons margin-top">
        <DxButton
          class="dx-btn-cancel margin-right padding-half"
          type="normal"
          styling-mode="contained"
          @click="cancelForm"
        >
          {{ $t("common.options.cancel") }}
        </DxButton>
        <DxButton
          class="dx-btn-success padding-half"
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("common.options.save") }}
        </DxButton>
      </div>
    </DxScrollView>
  </DxPopup>
</template>

<style lang="scss">

.content-complaints-popup{
  @media (min-width: 1024px) {
    .dx-overlay-content{
      height: auto!important;
      width: auto!important;
    }
  }
}

.main-launch-trip{
  .content-devx{
    width: 1080px;
    margin: 15px auto;
  }
}
.checkbox-items{
  .dx-field-item-label-content{
    width: auto!important;
    min-width: 35px;
  }
  &.dx-field-item:not(.dx-last-col) {
    padding-right: 0;
  }
  .dx-field-item-label-location-left{
    padding-right: 5px;
  }
  &.dx-last-col .dx-field-item-content{
    display: flex!important;
    align-items: flex-start;
  }
  &.dx-field-item:not(.dx-first-col) {
    padding-left: 5px;
  }
}
</style>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import 'devextreme-vue/radio-group';
import 'devextreme/ui/date_box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';
import helpers from '../../../js/helpers';

export default {
  name: 'ComplaintsPopup',
  components: {
    DxScrollView,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxButton,
  },
  props: {},
  data() {
    return {
      commonProps,
      show: false,
      loaded: false,
      isVisibleRecipientInfo: false,
    };
  },

  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapGetters('Trips', ['formatedTripData']),
    ...mapState('Complaints', ['complaints']),
    ...mapState('Complaints', [
      'name',
      'description',
      'amount',
      'isAccepted',
      'creatorUser',
      'documentation',
      'idShipment',
      'idCompany',
    ]),
    ...mapGetters('authentication', ['getThemeData']),

  },
  async beforeMount() {
    this.loaded = false;

    this.$f7.preloader.show();
    this.loaded = true;
    this.$f7.preloader.hide();
  },
  mounted() {
    eventBus.$on('openComplaintsPopup', (complaints) => {
      if (complaints) {
        this.setId(complaints.id);
        this.setName(complaints.name);
        this.setDescription(complaints.description);
        this.setAmount(complaints.amount);
        this.setIdCompany(complaints.idCompany);
        this.setIdShipment(complaints.idShipment);
        this.setShipmentReference(complaints.shipmentReference);
        this.setDocumentation(complaints.documentation);
        this.setIsAccepted(complaints.isAccepted);
      }
      this.show = true;
    });
  },
  methods: {
    onHidden() {
      this.show = false;
      this.form.resetValues();
      this.initStore();
    },
    changeSelectiontypeOfTransport(e) {
      this.selectionTypeOfTransport = e.value;
    },
    validateForm() {
      if (this.name === '' || typeof this.name === 'undefined') {
        this.$f7.dialog.alert(this.$t('complaints.popup.nameRequired'));
      } else if (this.description === '' || typeof this.description === 'undefined') {
        this.$f7.dialog.alert(this.$t('complaints.popup.descriptionRequired'));
      } else if (this.amount === '' || typeof this.amount === 'undefined') {
        this.$f7.dialog.alert(this.$t('complaints.popup.amountRequired'));
      } else {
        this.saveComplaints();
      }
    },
    async saveComplaints() {
      try {
        this.$f7.preloader.show();
        await this.postComplaint();
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        this.$f7.dialog.alert(msg);
      } finally {
        this.$f7.preloader.hide();
        this.show = false;
      }
    },
    async cancelForm() {
      this.initStore();
      this.show = false;
      this.form.resetValues();
    },
    onChangedName(e) {
      this.setName(e.value);
    },
    onChangedDescription(e) {
      this.setDescription(e.value);
    },
    onChangedAmount(e) {
      this.setAmount(e.value);
    },
    ...mapActions('Complaints', ['getInfoDeviceId', 'initStore',
      'setId', 'setName', 'setDescription', 'setAmount', 'setIdCompany', 'setIdShipment', 'setShipmentReference',
      'setIsAccepted', 'setDocumentation', 'setIsAccepted',
      'postComplaint']),
  },
};
</script>
