<template>
  <span :class="humidity">
    {{ (data.value !== null && typeof data.value !== `undefined` )
      ? data.value +'%' : ' ' }}</span>
</template>

<script>
export default {
  name: 'HumidityCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
  },
};
</script>
