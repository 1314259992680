<template>
  <div class="main-graph">
    <f7-block class="padding-half">
      <DxChart
        id="chart"
        ref="graph"
        :data-source="dataSource"
        :on-done="onDone"
      >
        <DxCommonSeriesSettings
          :argument-field="config.xAxis.key"
        />
        <DxSeries
          pane="top"
          :color="config.lineColor"
          :value-field="config.yAxis.key"
          :name="`${config.title}, ${config.measurement}`"
        />

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
          :value-margins-enabled="false"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM - hh:mm"
          />
        </DxArgumentAxis>
        <DxAdaptiveLayout
          :width="100"
          :keep-labels="true"
        />
        <DxValueAxis
          pane="top"
          value-type="numeric"
          :visual-range="visualRange"
        >
          <DxConstantLine
            v-if="showRange"
            :show-in-legend="true"
            :color="blue"
            :label="{
              text: $t('common.temperature.minTemp')
            }"
            dash-style="longDash"
            :value="range.min"
          />
          <DxConstantLine
            v-if="showRange"
            dash-style="longDash"
            :color="red"
            :label="{
              text: $t('common.temperature.maxTemp')
            }"
            :value="range.max"
          />
          <DxGrid :visible="true" />
          <DxTitle :text="`${config.title}, ${config.measurement}`" />
        </DxValueAxis>
        <DxExport :enabled="false" />
        <DxTitle
          horizontal-alignment="right"
          :text="`${config.title}`"
        >
          <DxFont
            color="var(--hi-theme-primary)"
            size="16px"
            family="Roboto, Segoe UI, Helvetica Neue, sans-serif"
          />
        </DxTitle>
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          content-template="tooltipTemplate"
        />
        <DxLegend :visible="false" />
        <template #tooltipTemplate="{data}">
          <div class="content-graph-tooltip">
            <div v-if="data.point.data.eventCity">
              <b>{{ config.title }} {{ $t('common.temperature.in') }}</b>
              <em>{{ data.point.data.eventCity }}</em>
            </div>
            <div v-else>
              {{ config.title }}
            </div>
            <div class="margin-vertical-half margin-bottom">
              <b>{{ data.value }} {{ config.measurement }} </b>
              <br><br><b>{{ formatDate(data.point.data.dateTimeAcquiredUtc) }}</b>
            </div>
          </div>
        </template>
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import moment from 'moment';

import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxFont,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
  DxAdaptiveLayout,
  DxConstantLine,
} from 'devextreme-vue/chart';
import { COLOR_RED, COLOR_BLUE } from '../../../js/constants';

export default {
  name: 'Graph',
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxFont,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
    DxAdaptiveLayout,
    DxConstantLine,
  },
  props: {
    refArray: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({
        title: 'Temperature',
        xAxis: { key: 'dateTimeAcquiredUtc' },
        yAxis: { key: 'deviceTemp' },
        range: false,
        lineColor: '#0493E6',
      }),
    },
    range: {
      type: Object,
      default: () => ({
        min: 5,
        max: 10,
      }),
    },
  },
  data() {
    return {
      visualRange: {},
      red: COLOR_RED,
      blue: COLOR_BLUE,
    };
  },
  computed: {
    showRange() {
      const isNumber = Number.isInteger(this.range.min) && Number.isInteger(this.range.max);
      const isNotDefaultRange = this.range.min !== -40 && this.range.max !== 40;
      const hasRangeActive = this.config.range;
      return isNumber && isNotDefaultRange && hasRangeActive;
    },
  },
  mounted() {
    this.refArray.push(this.$refs.graph);
  },
  methods: {
    onDone(e) {
      if (this.config.range === false) return;
      // eslint-disable-next-line no-underscore-dangle
      const valueRange = { ...e.component._valueAxes[0]._initRange };
      if (this.range.min < valueRange.startValue) valueRange.startValue = this.range.min - 2;
      if (this.range.max > valueRange.endValue) valueRange.endValue = this.range.max + 2;
      this.visualRange = valueRange;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Graph.styles.scss";
</style>
