import $ from '../../js/i18n.config';

export default [
  {
    name: 'Status',
    value: 'status',
    caption: $.t('shipping.status'),
  },
  {
    name: 'Alert',
    value: 'alert',
    caption: $.t('common.track.alert'),
  },
  {
    name: 'Device',
    value: 'device',
    caption: $.t('common.track.device'),
  },
  {
    name: 'Temp',
    value: 'temperature',
    caption: $.t('common.track.temperature'),
  },
  {
    name: 'Location',
    value: 'location',
    caption: $.t('shipping.location'),
  },
  {
    name: 'LastReport',
    value: 'lastReport',
    caption: $.t('common.track.lastReport'),
  },
  {
    name: 'Origin',
    value: 'origin',
    caption: $.t('shipping.origin'),
  },
  {
    name: 'Activation',
    value: 'dateStart',
    caption: $.t('common.date.dateStart'),
  },
  {
    name: 'CustomerRef',
    value: 'customerRef',
    caption: $.t('common.track.reference'),
  },
  {
    name: 'Client',
    value: 'customerOfOurCustomer',
    caption: $.t('shipping.customerOfOurCustomer'),
  },
  {
    name: 'Destination',
    value: 'destination',
    caption: $.t('shipping.destination'),
  },
  {
    name: 'Delivery',
    value: 'dateArrived',
    caption: $.t('common.date.dateDeliver'),
  },
  {
    name: 'NumberTransport',
    value: 'numberTransport',
    caption: $.t('shipping.numberTransport'),
  },
  {
    name: 'Carrier',
    value: 'carrier',
    caption: $.t('common.blocks.carrier'),
  },
  {
    name: 'Humidity',
    value: 'humidity',
    caption: $.t('common.track.humidity'),
  },
  {
    name: 'CO2',
    value: 'co2',
    caption: $.t('common.track.co2'),
  },
  {
    name: 'Week',
    value: 'week',
    caption: $.t('common.track.week'),
  },
  {
    name: 'PulpTemp',
    value: 'pulpTemp',
    caption: $.t('common.track.pulpTemp'),
  },
  {
    name: 'Range',
    value: 'range',
    caption: $.t('shipping.range'),
  },
  {
    name: 'Cargo',
    value: 'cargo',
    caption: $.t('common.blocks.cargo'),
  },
  {
    name: 'Device',
    value: 'device',
    caption: $.t('common.blocks.device'),
  },
];
