<template>
  <div class="wrapper main-deviceInfo">
    <f7-row no-gap>
      <f7-col :width="25">
        <Table
          :title="shipmentInfo.title"
          :data="shipmentInfo.data"
        />
        <Table
          :title="shipmentSummary.title"
          :data="shipmentSummary.data"
        />
      </f7-col>
      <f7-col :width="75">
        <f7-card class="card-block margin-bottom">
          <div class="card-header no-margin-bottom">
            {{ $t("common.blocks.trip") }}
          </div>
          <div class="card-body">
            <div
              v-if="mapConfig.show"
            >
              <template>
                <div>
                  <div>
                    <TravelMap
                      class="route-map"
                      :locations="mapConfig.locations"
                      :paths="mapConfig.paths"
                      :info-window-template-report="true"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </f7-card>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { ALERT_CODE_LIGHT } from '../../js/constants';
import Table from '../Table/index.vue';
import TravelMap from '../mapInfoDevice/map/index.vue';
import EventBus from '../../js/event-bus';

export default {
  name: 'InformationMap',
  components: {
    Table,
    TravelMap,
  },
  data() {
    return {
      shipmentInfo: {
        title: this.$t('deviceInfo.informationMap.sideTable.shipmentInfo.title'),
        data: [],
      },
      shipmentSummary: {
        title: this.$t('deviceInfo.informationMap.sideTable.shipmentSummary.title'),
        data: [],
      },
    };
  },
  computed: {
    mapConfig() {
      if (this.reports.length === 0) return { show: false, ...this.mapData };
      if (this.mapData.locations.length !== 0) return { show: true, ...this.mapData };

      return { show: false, ...this.mapData };
    },
    mapData() {
      const localLocations = [];
      const localPaths = [];
      const parsePosition = (position) => Number.parseFloat(position.replace(',', '.').replace('−', '-'));
      const isNumber = (position) => Number.isNaN(parsePosition(position));

      this.reports.forEach((report, i, reports) => {
        if (!isNumber(report.latitude) && !isNumber(report.longitude)) {
          localLocations.push(
            {
              id: report.ID,
              position: {
                lat: parsePosition(report.latitude),
                lng: parsePosition(report.longitude),
              },
              color: this.getMarkerIcon(this.currentShip, report),
              deviceId: report.deviceID,
              infoWindow: {
                title: '',
              },
            },
          );
        }
        if (reports.length > 2 && i + 1 !== reports.length) {
          if (!isNumber(report.latitude)
              && !isNumber(report.longitude)
              && !isNumber(reports[i + 1].latitude)
              && !isNumber(reports[i + 1].longitude)) {
            localPaths.push(
              {
                id: i,
                path: [{
                  lat: parsePosition(report.latitude),
                  lng: parsePosition(report.longitude),
                }, {
                  lat: parsePosition(reports[i + 1].latitude),
                  lng: parsePosition(reports[i + 1].longitude),
                }],
              },
            );
          }
        }
      });
      return {
        paths: localPaths,
        locations: localLocations,
      };
    },
    ...mapGetters('Shipments', ['getTableShipmentInfo', 'getTableShipmentSummary']),
    ...mapState('Shipments', ['currentShip', 'reports']),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      this.shipmentInfo.data = this.getTableShipmentInfo;
      this.shipmentSummary.data = this.getTableShipmentSummary;

      EventBus.$on('markerClickReport', (report) => {
        const reporte = this.reports.find((item) => item.ID === report);
        this.updateReport(reporte);
      });
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    getMarkerIcon(device, report) {
      const min = Number.parseFloat(device.lowerTemperature);
      const max = Number.parseFloat(device.higherTemperature);
      const temp = Number.parseFloat(report.deviceTemp);

      if (report.alertCode === ALERT_CODE_LIGHT) return ALERT_CODE_LIGHT;
      if (Number.isNaN(min) || Number.isNaN(max) || Number.isNaN(temp)) return 'circleYellow';

      if (temp < min) {
        return 'circleBlue';
      } if (temp > max) {
        return 'circleRed';
      }
      return 'circleGreen';
    },
    ...mapActions('Shipments', ['fetchReports', 'updateReport']),
  },

};
</script>
<style lang="scss" scoped>
  @import url('InformationMap.styles.scss');
</style>
