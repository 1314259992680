<template>
  <div class="wrapper main-deviceInfo">
    <f7-row no-gap>
      <f7-col :width="25">
        <Table
          :title="shipmentInfo.title"
          :data="shipmentInfo.data"
        />
        <Table
          :title="shipmentSummary.title"
          :data="shipmentSummary.data"
        />
      </f7-col>
      <f7-col :width="75">
        <f7-card class="card-block">
          <div class="card-header no-margin-bottom">
            Reporte
          </div>
          <div class="card-body">
            <!-- TODO no se cuando viene 1 o mas para hacer el grid -->
            <Graph
              v-for="(graph, index) in graphs"
              :key="graph.title"
              :ref-array="graphRefArray"
              :class="`main-${graph.title}`"
              :data-source="reportsGraph"
              :config="graph"
              :range="{
                min: currentShip.lowerTemperature,
                max: currentShip.higherTemperature
              }"
            />
          </div>
        </f7-card>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DEFAULT_SECONDARY_COLOR } from '../../js/constants';
import Table from '../Table/index.vue';
import Graph from './graph/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'DeviceInfo',
  components: {
    Graph,
    Table,
  },
  data() {
    return {
      graphRefArray: [],
      shipmentInfo: {
        title: this.$t('deviceInfo.information.sideTable.shipmentInfo.title'),
        data: [],
      },
      shipmentSummary: {
        title: this.$t('deviceInfo.information.sideTable.shipmentSummary.title'),
        data: [],
      },
      temperatureConfig: {
        title: this.$t('common.temperature.name'),
        xAxis: { key: 'dateTimeAcquiredUtc' },
        yAxis: { key: 'deviceTemp' },
        lineColor: DEFAULT_SECONDARY_COLOR,
        range: true,
        measurement: this.$t('common.temperature.unit'),
      },
      co2Config: {
        title: this.$t('deviceInfo.information.graph.co2.title'),
        xAxis: { key: 'dateTimeAcquiredUtc' },
        yAxis: { key: 'co2' },
        lineColor: DEFAULT_SECONDARY_COLOR,
        range: false,
        measurement: this.$t('deviceInfo.information.graph.co2.unit'),
      },
      humidityConfig: {
        title: this.$t('deviceInfo.information.graph.humidity.title'),
        xAxis: { key: 'dateTimeAcquiredUtc' },
        yAxis: { key: 'humidity' },
        lineColor: DEFAULT_SECONDARY_COLOR,
        range: false,
        measurement: this.$t('deviceInfo.information.graph.humidity.unit'),
      },
      pulpTemperatureConfig: {
        title: this.$t('deviceInfo.information.graph.pulpTemperature.title'),
        xAxis: { key: 'dateTimeAcquiredUtc' },
        yAxis: { key: 'pulpTemperature' },
        lineColor: DEFAULT_SECONDARY_COLOR,
        range: false,
        measurement: this.$t('deviceInfo.information.graph.pulpTemperature.unit'),
      },
    };
  },
  computed: {
    reportsGraph() {
      const localReports = [];
      this.reports.forEach((report) => {
        if (report.deviceTemp !== null) localReports.push(report);
      });
      return localReports;
    },
    graphs() {
      if (this.reports.length === 0) return [];
      const localGraphs = [];
      const properties = [
        { name: 'deviceTemp', config: this.temperatureConfig },
        { name: 'pulpTemperature', config: this.pulpTemperatureConfig },
        { name: 'humidity', config: this.humidityConfig },
        { name: 'co2', config: this.co2Config },
      ];

      properties.forEach((property) => {
        if (this.checkIfValid(this.reports, property.name)) {
          if (property.name === 'humidity') {
          }
          localGraphs.push(property.config);
        }
      });

      return localGraphs;
    },
    ...mapGetters('Shipments', ['getTableShipmentInfo', 'getTableShipmentSummary']),
    ...mapState('Shipments', ['currentShip', 'reports']),
  },
  async mounted() {
    eventBus.$on('redrawDeviceTabGraphs', () => {
      this.graphRefArray.forEach((graph) => graph.instance.render());
    });
    try {
      this.$f7.preloader.show();
      this.shipmentInfo.data = this.getTableShipmentInfo;
      this.shipmentSummary.data = this.getTableShipmentSummary;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    checkIfValid(objectList, property) {
      const hasProperty = Object.prototype.hasOwnProperty.call(objectList[0], property);
      if (!hasProperty) return false;

      const isNotFalsy = (object) => object[property] !== '' && object[property] !== null && object[property] !== undefined;

      return objectList.some((object) => isNotFalsy(object));
    },
    ...mapActions('Shipments', ['fetchReports']),
  },

};
</script>
<style lang="scss" scoped>
  @import url('DeviceInfo.styles.scss');
</style>
