/* eslint-disable no-prototype-builtins */
import Api from '../../services/Api';
import helpers from '../../js/helpers';
import eventBus from '../../js/event-bus';

export default {
  namespaced: true,
  state: {
    protocols: [],
    protocolsFilters: [],
    selectedProtocol: null,
    id: '',
    title: '',
    description: '',
    protocolDocs: [],
    creatorUser: '',
  },
  getters: {
    filteredProtocols(state) {
      return state.protocols.filter((protocol) => {
        if (state.protocolsFilters.length === 0) return true;
        return state.protocolsFilters.some((filter) => filter.filterExpr(protocol));
      });
    },
    protocols(state) {
      return state.protocols;
    },
    protocolsFilters(state) {
      return state.protocols;
    },
    selectedProtocols(state) {
      return state.selectedProtocols;
    },
  },
  mutations: {
    SET_PROTOCOLS(state, payload) {
      state.protocols = payload;
    },
    INITIALIZE_PROTOCOLS_PAGE(state) {
      state.selectedProtocols = null;
    },
    FILL_FIELDS_WITH_DATA(state, payload) {
      state.selectedProtocols = payload;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    SET_DESCRIPTION(state, payload) {
      state.description = payload;
    },
    SET_PROTOCOL_DOCS(state, payload) {
      state.protocolDocs = payload;
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },
    SET_CREATOR_USER(state, payload) {
      state.creatorUser = payload;
    },
  },
  actions: {
    initializePprotocolsPage({ commit }) {
      commit('INITIALIZE_PROTOCOLS_PAGE');
    },
    async fetchProtocols({ commit }) {
      try {
        const data = await Api.protocols.getAll();
        const parsedData = JSON.parse(data.response);
        commit('SET_PROTOCOLS', parsedData);
        eventBus.$emit('protocolsLoaded');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        eventBus.$emit('protocolsLoaded');
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async postProtocols({ state, dispatch }) {
      try {
        // await Api.protocols.post(protocol);
        // await dispatch('fetchProtocols');
        await Api.protocols.post(
          {
            Id: state.id,
            Title: state.title,
            Description: state.description,
            ProtocolDocs: state.protocolDocs,
          },
        );
        await dispatch('fetchProtocols');
        return Promise.resolve();
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
    },
    async removeProtocols({ dispatch }, id) {
      try {
        await Api.protocols.remove(id);
        await dispatch('fetchProtocols');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    setId(context, value) {
      context.commit('SET_ID', value);
    },
    setTitle(context, value) {
      context.commit('SET_TITLE', value);
    },
    setDescription(context, value) {
      context.commit('SET_DESCRIPTION', value);
    },
    setProtocolDocs(context, value) {
      context.commit('SET_PROTOCOL_DOCS', value);
    },
    setCreatorUser(context, value) {
      context.commit('SET_CREATOR_USER', value);
    },
    initStore({ commit }) {
      commit('SET_ID', '');
      commit('SET_TITLE', '');
      commit('SET_DESCRIPTION', '');
      commit('SET_PROTOCOL_DOCS', []);
      commit('SET_CREATOR_USER', '');
    },
  },
};
