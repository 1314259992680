var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxPopup',_vm._b({attrs:{"id":"attach-file-popup","title":_vm.$t(_vm.tradRoot + '.title'),"visible":_vm.popupShow,"on-hidden":_vm.onHidden,"height":"auto","width":700},on:{"update:visible":function($event){_vm.popupShow=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxForm',{ref:"form",attrs:{"show-colon-after-label":false}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t(_vm.tradRoot + '.description'),"editor-type":"dxTextBox","editor-options":{
          value: _vm.fileDisplayName,
          onValueChanged: _vm.onChangeDisplayName,
          placeholder: this.$t(_vm.tradRoot + '.descriptionPlaceholder'),
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t(_vm.tradRoot + '.descriptionRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t(_vm.tradRoot + '.grades'),"editor-type":"dxTextArea","editor-options":{
          value: _vm.fileDescription,
          onValueChanged: _vm.onChangeDescription,
          placeholder: this.$t(_vm.tradRoot + '.gradesPlaceholder'),
          height: 85,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t(_vm.tradRoot + '.gradesRequired')}})],1)],1),_vm._v(" "),_c('FileUploader',{attrs:{"id":_vm.Id}}),_vm._v(" "),_c('f7-block-footer',{staticClass:"content-action-botton"},[_c('div',{staticClass:"content-buttons margin-vertical"},[_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained","name":"ButtonSave"},on:{"click":_vm.savePopup}},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.tradRoot + '.saveFile'))+"\n        ")])],1)])],1),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-file",attrs:{"raised":"","type":"default","disabled":!_vm.currentShip.editPermissions,"styling-mode":"outlined"},on:{"click":_vm.openPopupFile}},[_c('f7-icon',{staticClass:"color-black",attrs:{"md":"f7:arrow_up_doc"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }