<template>
  <span :class="`temp-${getTempClass(data)}`">
    {{ (data.value !== null && typeof data.value !== `undefined` )
      ? data.value.toFixed(2) +'º' : ' ' }}</span>
</template>

<script>
export default {
  name: 'TempCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getTempClass(row) {
      const range = this.parseRange(row.data.range);
      const temp = Number.parseFloat(row.value);

      if (temp < range.min) return 'low';
      if (temp > range.max) return 'high';

      return undefined;
    },
    parseRange(range) {
      const ranges = range.split(' ');

      return {
        min: Number.parseFloat(ranges[0]),
        max: Number.parseFloat(ranges[2]),
      };
    },
  },
};
</script>
