<template>
  <DxPopup
    v-bind="commonProps.popup"
    :title="title"
    :visible.sync="show"
    :on-hidden="onHidden"
    height="auto"
    :width="620"
  >
    <DxForm
      ref="form"
      :show-colon-after-label="false"
    >
      <DxSimpleItem
        :data-field="$t('common.blocks.carrier')"
        editor-type="dxTextBox"
        :is-required="true"
        :label="{
          location: 'top'
        }"
        :editor-options="{
          value: carrierName,
          onValueChanged: onChangeCarrierName,
          placeholder: `${$t('common.blocks.carrier')}...`,
        }"
      />
      <DxGroupItem
        caption="Location"
        :col-count="2"
      >
        <!-- <DxSimpleItem
          data-field="Search"
          :label="{
            location: 'top'
          }"
          editor-type="dxAutocomplete"
          :editor-options="{
            items: autocompleteData,
            onValueChanged: queryChanged,
            valueExpr: 'place_id',
            searchExpr: 'description',
          }"
        /> -->
        <DxSimpleItem
          :data-field="$t('common.track.latitude')"
          editor-type="dxTextBox"
          :label="{
            location: 'top'
          }"
          :editor-options="{
            disabled: true,
            value: lat,
            onValueChanged: onChangeLat,
            placeholder: `${$t('common.track.latitude')}...`,
          }"
        />
        <DxSimpleItem
          :data-field="$t('common.track.longitude')"
          editor-type="dxTextBox"
          :label="{
            location: 'top'
          }"
          :editor-options="{
            disabled: true,
            value: lng,
            onValueChanged: onChangeLng,
            placeholder: `${$t('common.track.longitude')}...`,
          }"
        />
      </DxGroupItem>
    </DxForm>

    <TravelMap
      class="travel-map"
      :style="{height: '350px'}"
      :get-position="true"
    />
    <div>
      <f7-icon
        icon="info_circle_fill"
        :size="15"
      />
      <p>
        <i>
          {{ $t('administration.carrier.popup.tooltip') }}
        </i>
      </p>
    </div>
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="hidePopup"
      >
        {{ $t("common.options.cancel") }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="addCarrier"
      >
        {{ $t("common.options.save") }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm, DxSimpleItem, DxGroupItem,
} from 'devextreme-vue/form';
import Autocomplete from 'devextreme/ui/autocomplete';
import DxButton from 'devextreme-vue/button';
import eventBus from '../../../js/event-bus';
import TravelMap from '../../mapInfoDevice/map/index.vue';
import { commonProps } from '../../trips/tripsColumnData';
import api from '../../../services/Api';

export default {
  name: 'CarriersPopup',
  components: {
    Autocomplete,
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxButton,
    TravelMap,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      isEditing: false,
      carrierName: '',
      query: '',
      autocompleteData: [],
      lat: '',
      lng: '',
      id: '',
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    title() {
      if (this.isEditing) return this.$t('administration.carrier.popup.titleEditing');
      return this.$t('administration.carrier.popup.title');
    },
  },
  mounted() {
    eventBus.$on('showCarriersPopup', (transportista) => {
      this.show = true;
      if (transportista) {
        const { lat, lng } = transportista.coordinates.position;
        const { name, id } = transportista;
        this.isEditing = true;
        this.carrierName = name;
        this.lat = lat;
        this.lng = lng;
        this.id = id;
      }
    });
    eventBus.$on('autocompletePredictions', (predictions) => {
      this.autocompleteData = predictions;
    });

    eventBus.$on('mapMarkerPosition', (position) => {
      this.lat = position.lat;
      this.lng = position.lng;
    });
  },
  methods: {
    queryChanged(e) {
      this.query = e.value;
      eventBus.$emit('getAutoCompletePredictions', e.value);
    },
    onChangeCarrierName(e) {
      this.carrierName = e.value;
    },
    onChangeLat(e) {
      this.lat = e.value;
    },
    onChangeLng(e) {
      this.lng = e.value;
    },
    hidePopup() {
      this.show = false;
    },
    async addCarrier() {
      const carrier = {
        id: this.id,
        name: this.carrierName,
        coordinates: { position: { lat: this.lat, lng: this.lng } },
      };
      this.$f7.preloader.show();
      if (this.isEditing) {
        await api.editCarrier(carrier);
      } else {
        await api.addNewCarrier(carrier);
      }
      this.$f7.preloader.hide();
      this.show = false;
    },
    onHidden() {
      this.form.resetValues();
      this.id = '';
      this.show = false;
      this.isEditing = false;
    },
  },
};
</script>

<style>

</style>
