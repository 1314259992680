import $ from './i18n.config';

export default {
  en: {
    'dxList-selectAll': $.t('common.options.selectAll'),
    'dxDataGrid-headerFilterEmptyValue': $.t('common.track.empty'),

    'dxDataGrid-filterRowOperationEquals': $.t('shipping.headerFilters.operators.equal'),
    'dxDataGrid-filterRowOperationNotEquals': $.t('shipping.headerFilters.operators.notEqual'),
    'dxDataGrid-filterRowOperationLess': $.t('shipping.headerFilters.operators.lessThan'),
    'dxDataGrid-filterRowOperationLessOrEquals': $.t('shipping.headerFilters.operators.lessThanOrEqual'),
    'dxDataGrid-filterRowOperationGreater': $.t('shipping.headerFilters.operators.greaterThan'),
    'dxDataGrid-filterRowOperationGreaterOrEquals': $.t('shipping.headerFilters.operators.greaterThanOrEqual'),
    'dxDataGrid-filterRowOperationStartsWith': $.t('shipping.headerFilters.operators.startsWith'),
    'dxDataGrid-filterRowOperationContains': $.t('shipping.headerFilters.operators.contains'),
    'dxDataGrid-filterRowOperationNotContains': $.t('shipping.headerFilters.operators.notContains'),
    'dxDataGrid-filterRowOperationEndsWith': $.t('shipping.headerFilters.operators.endsWith'),
    'dxDataGrid-filterRowOperationBetween': $.t('shipping.headerFilters.operators.between'),
    'dxDataGrid-filterRowOperationBetweenStartText': 'Start',
    'dxDataGrid-filterRowOperationBetweenEndText': 'End',

    'dxDataGrid-columnChooserTitle': $.t('devextreme.columnChooserTitle'),
    'dxDataGrid-columnChooserEmptyText': $.t('devextreme.columnChooserEmptyText'),

    'dxDataGrid-sortingAscendingText': $.t('devextreme.sortingAscendingText'),
    'dxDataGrid-sortingDescendingText': $.t('devextreme.sortingDescendingText'),
    'dxDataGrid-sortingClearText': $.t('devextreme.sortingClearText'),
    'dxDataGrid-allowDeleting': $.t('devextreme.sortingClearText'),
    'dxDataGrid-exporting': $.t('devextreme.exporting'),
  },
};
