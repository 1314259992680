<template>
  <DxPopup
    v-bind="commonProps.popup"
    :title="title"
    :visible.sync="show"
    :on-hidden="onHidden"
    :width="620"
  >
    <DxForm
      ref="form"
      :form-data="formData"
      :show-colon-after-label="false"
      label-location="left"
    >
      <DxSimpleItem
        data-field="name"
        editor-type="dxTextBox"
        :is-required="true"
        :editor-options="{
          placeholder: `${$t('administration.loadingPoints.popup.loadingPointName')}...`,
        }"
        :label="{
          text: $t('administration.loadingPoints.popup.loadingPointName')
        }"
      />
      <DxGroupItem
        caption="Tipo de ubicación"
        :col-count="2"
      >
        <!-- <DxSimpleItem
          data-field="Search"
          :label="{
            location: 'top'
          }"
          editor-type="dxAutocomplete"
          :editor-options="{
            items: autocompleteData,
            onValueChanged: queryChanged,
            valueExpr: 'place_id',
            searchExpr: 'description',
          }"
        /> -->
        <DxSimpleItem
          data-field="isLoadPlace"
          editor-type="dxCheckBox"
          :validation-rules="[{
            type:'custom',
            validationCallback: validateLocationType,
            message: 'Al menos un tipo de ubicación es requerido'
          }]"
          :label="{
            text: $t('administration.loadingPoints.popup.loadPlace')
          }"
        />
        <DxSimpleItem
          data-field="isDownloadPlace"
          editor-type="dxCheckBox"
          :validation-rules="[{
            type:'custom',
            validationCallback: validateLocationType,
            message: 'Al menos un tipo de ubicación es requerido'
          }]"
          :label="{
            text: $t('administration.loadingPoints.popup.downloadPlace')
          }"
        />
      </DxGroupItem>
    </DxForm>

    <TravelMap
      class="travel-map"
      :style="{height: '350px'}"
      :get-position="true"
    />
    <div>
      <f7-row>
        <i>
          <f7-icon
            f7="info_circle_fill"
            :size="25"
          />
          {{ $t('administration.loadingPoints.popup.tooltip') }}
        </i>
      </f7-row>
    </div>
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="() => show = false"
      >
        {{ $t("common.options.cancel") }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="addLoadingPoint"
      >
        {{ $t("common.options.save") }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm, DxSimpleItem, DxGroupItem,
} from 'devextreme-vue/form';
import Autocomplete from 'devextreme/ui/autocomplete';
import DxButton from 'devextreme-vue/button';
import { mapActions } from 'vuex';
import eventBus from '../../../js/event-bus';
import TravelMap from '../../mapInfoDevice/map/index.vue';
import { commonProps } from '../../trips/tripsColumnData';

export default {
  name: 'DeactivatePopup',
  components: {
    Autocomplete,
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxButton,
    TravelMap,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      isEditing: false,
      formData: {
        name: '',
        latitude: '',
        longitude: '',
        id: '',
        codEnt: '',
        isDownloadPlace: true,
        isLoadPlace: true,
      },
      query: '',
      autocompleteData: [],
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    title() {
      if (this.isEditing) return this.$t('administration.loadingPoints.popup.titleEditing');
      return this.$t('administration.loadingPoints.popup.title');
    },
  },
  mounted() {
    eventBus.$on('showLoadingPointsPopup', (puntoDeCarga) => {
      this.show = true;
      if (puntoDeCarga) {
        this.isEditing = true;
        this.formData = { ...puntoDeCarga };
      }
    });

    eventBus.$on('autocompletePredictions', (predictions) => {
      this.autocompleteData = predictions;
    });

    eventBus.$on('mapMarkerPosition', (position) => {
      this.formData.latitude = position.lat;
      this.formData.longitude = position.lng;
    });
  },
  methods: {
    validateLocationType(e) {
      let { isLoadPlace, isDownloadPlace } = this.formData;
      if (e.formItem.dataField === 'isDownloadPlace') { isDownloadPlace = e.value; }
      if (e.formItem.dataField === 'isLoadPlace') { isLoadPlace = e.value; }
      if (isLoadPlace || isDownloadPlace) return true;
      return false;
    },
    queryChanged(e) {
      this.query = e.value;
      eventBus.$emit('getAutoCompletePredictions', e.value);
    },
    async addLoadingPoint() {
      if (!this.form.validate().isValid) return;
      try {
        this.$f7.preloader.show();
        // if (this.formData.isLoadPlace === '') { this.formData.isLoadPlace = false; }
        // if (this.formData.isDownloadPlace === '') { this.formData.isDownloadPlace = false; }
        await this.postLocation(this.formData);
      } finally {
        this.$f7.preloader.hide();
        this.show = false;
      }
    },
    onHidden() {
      this.form.resetValues();
      this.formData.isDownloadPlace = true;
      this.formData.isLoadPlace = true;
      eventBus.$emit('setMarkerPosition');
      this.show = false;
      this.isEditing = false;
    },
    ...mapActions('Trips', ['postLocation']),
  },
};
</script>

<style>

</style>
