var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxPopup',_vm._b({attrs:{"title":_vm.title,"visible":_vm.show,"on-hidden":_vm.onHidden,"width":620},on:{"update:visible":function($event){_vm.show=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxForm',{ref:"form",attrs:{"form-data":_vm.formData,"show-colon-after-label":false,"label-location":"left"}},[_c('DxSimpleItem',{attrs:{"data-field":"name","editor-type":"dxTextBox","is-required":true,"editor-options":{
        placeholder: ((_vm.$t('administration.loadingPoints.popup.loadingPointName')) + "..."),
      },"label":{
        text: _vm.$t('administration.loadingPoints.popup.loadingPointName')
      }}}),_vm._v(" "),_c('DxGroupItem',{attrs:{"caption":"Tipo de ubicación","col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":"isLoadPlace","editor-type":"dxCheckBox","validation-rules":[{
          type:'custom',
          validationCallback: _vm.validateLocationType,
          message: 'Al menos un tipo de ubicación es requerido'
        }],"label":{
          text: _vm.$t('administration.loadingPoints.popup.loadPlace')
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":"isDownloadPlace","editor-type":"dxCheckBox","validation-rules":[{
          type:'custom',
          validationCallback: _vm.validateLocationType,
          message: 'Al menos un tipo de ubicación es requerido'
        }],"label":{
          text: _vm.$t('administration.loadingPoints.popup.downloadPlace')
        }}})],1)],1),_vm._v(" "),_c('TravelMap',{staticClass:"travel-map",style:({height: '350px'}),attrs:{"get-position":true}}),_vm._v(" "),_c('div',[_c('f7-row',[_c('i',[_c('f7-icon',{attrs:{"f7":"info_circle_fill","size":25}}),_vm._v("\n        "+_vm._s(_vm.$t('administration.loadingPoints.popup.tooltip'))+"\n      ")],1)])],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained","on-click":function () { return _vm.show = false; }}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.cancel"))+"\n    ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained","on-click":_vm.addLoadingPoint}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.save"))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }