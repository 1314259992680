import eventBus from '../../js/event-bus';
import $ from '../../js/i18n.config';
import api from '../../services/Api';

const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      name: 'editCarriers',
      icon: 'edit',
      hint: $.t('common.toolTips.edit'),
    },
    {
      name: 'deleteCarriers',
      icon: 'trash',
      hint: $.t('common.toolTips.delete'),
    },
  ],
};

export const colMethods = {
  openCarriersPopup(e) {
    eventBus.$emit('showCarriersPopup', e.row.data);
    eventBus.$emit('setMarkerPosition', e.row.data);
  },
  async removeCarriers(e) {
    await api.removeCarriers(e.row.data.id);
  },
  addButtonFuncs(buttons) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'editCarriers':
          button.onClick = this.openCarriersPopup;
          break;
        case 'deleteCarriers':
          button.onClick = this.removeCarriers;
          break;
        default:
          break;
      }
    });
  },
};
export default [
  {
    dataField: 'name',
    caption: $.t('common.track.name'),
    dataType: 'string',
  },
  {
    dataField: 'coordinates',
    caption: $.t('common.track.coordinates'),
    dataType: 'object',
    cellTemplate: 'coordinates-cell',
  },
  buttonCol,
];
