<template>
  <DxPopup
    v-bind="commonProps.popup"
    :visible.sync="show"
    :on-hidden="onHidden"
    :title="$t('launchTrip.title')"
    :close-on-outside-click="false"
    :drag-enabled="true"
    position="center"
    class="content-launch-trip-popup"
  >
    <DxScrollView>
      <DxForm ref="form">
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('launchTrip.numeroDC')"
            :caption="$t('launchTrip.numeroDC')"
            editor-type="dxTextBox"
            :editor-options="{
              onValueChanged: onChangedDeviceId,
              value: deviceId,
              placeholder: $t('launchTrip.numeroDC'),
            }"
          >
            <DxRequiredRule :message="$t('launchTrip.numeroDCRequired')" />
          </DxSimpleItem>
          <DxGroupItem
            v-if="getThemeData.modalEnableDevice === 'frutaria'"
            :col-count="2"
          >
            <DxSimpleItem
              :data-field="$t('common.track.reference')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: getPreReferenciaFrutaria,
                value: codPreReferenciaFrutaria,
                onValueChanged: onValueChangedPreReferenciaFrutaria,
              }"
            />
            <DxSimpleItem
              editor-type="dxTextBox"
              :editor-options="{
                value: customerRef,
                onValueChanged: onChangedCustomerRef,
                placeholder: $t('common.track.reference'),
              }"
            />
          </DxGroupItem>
          <DxSimpleItem
            v-if="getThemeData.modalEnableDevice !== 'frutaria'"
            :data-field="$t('common.track.reference')"
            editor-type="dxTextBox"
            :editor-options="{
              value: customerRef,
              readOnly: readonlyReference,
              onValueChanged: onChangedCustomerRef,
              placeholder: $t('common.track.reference'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('common.date.dateActivation')"
            editor-type="dxDateBox"
            required
            :editor-options="{
              displayFormat: 'dd/MM/yyyy',
              value: dateActivation,
              max: dateDeliver,
              onValueChanged: onChangedDateActivation,
              placeholder: $t('common.date.dateActivation'),
              invalidDateMessage: $t('common.date.dateInvalid'),
            }"
          >
            <DxRequiredRule :message="$t('launchTrip.departureDateRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('common.date.dateDeliver')"
            editor-type="dxDateBox"
            :editor-options="{
              displayFormat: 'dd/MM/yyyy',
              value: dateDeliver,
              min: dateActivation,
              onValueChanged: onChangedDateDeliver,
              placeholder: $t('common.date.dateDeliver'),
              invalidDateMessage: $t('common.date.dateInvalid'),
            }"
          >
            <DxRequiredRule :message="$t('launchTrip.arrivalDateRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('common.track.origin')"
            :editor-type="typeOfOrigin"
            :editor-options="{
              value: activationPlace,
              onValueChanged: onChangedActivationPlace,
              placeholder: $t('common.track.origin'),
              items: listOrigins,
            }"
          />
          <DxSimpleItem
            :data-field="$t('common.track.destination')"
            :editor-type="typeOfDestination"
            :editor-options="{
              value: deliveryPlace,
              onValueChanged: onChangedDeliveryPlace,
              placeholder: $t('common.track.destination'),
              items: listDestinations,
            }"
          />
          <DxSimpleItem
            :data-field="$t('launchTrip.client')"
            editor-type="dxTextBox"
            :editor-options="{
              value: customerOfOurCustomer,
              onValueChanged: onChangedCustomer,
              placeholder: $t('launchTrip.client'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('common.blocks.carrier')"
            editor-type="dxTextBox"
            :editor-options="{
              value: carrier,
              onValueChanged: onChangedCarrier,
              placeholder: $t('common.blocks.carrier'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('launchTrip.transportNum')"
            editor-type="dxTextBox"
            :editor-options="{
              value: transportNum,
              onValueChanged: onChangedTransportNum,
              placeholder: $t('launchTrip.transportNum'),
            }"
          />
          <DxSimpleItem
            v-if="getThemeData.modalEnableDevice !== 'frutaria'"
            :data-field="$t('common.blocks.cargo')"
            :editor-type="typeOfCargo"
            :editor-options="{
              value: latestCargo,
              onValueChanged: onChangedCargo,
              placeholder: $t('common.blocks.cargo'),
              items: listTypeOfCargo,
            }"
          />
          <DxGroupItem :col-count="7">
            <DxSimpleItem
              v-for="(item, index) in getCargos"
              v-if="getThemeData.modalEnableDevice === 'frutaria'"
              :key="index"
              css-class="checkbox-items"
              editor-type="dxCheckBox"
              :editor-options="{
                items: listTypeOfCargo,
                text: item.Name,
                onValueChanged: onChangedCargoFrutaria(item),
              }"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              v-if="getThemeData.modalEnableDevice === 'casi'"
              :data-field="$t('launchTrip.comercial')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: getComercialCasi,
                value: genericAttribute01,
                placeholder: $t('launchTrip.comercial'),
                onValueChanged: onValueChangedGenericAttribute01,
              }"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              v-if="getThemeData.modalEnableDevice === 'royal'"
              :data-field="$t('launchTrip.numeroPaletRoyal')"
              editor-type="dxTextBox"
              :editor-options="{
                value: numeroPaletRoyal,
                onValueChanged: onChangedNumeroPaletRoyal,
                placeholder: $t('launchTrip.numeroPaletRoyal'),
              }"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              v-if="getThemeData.modalEnableDevice === 'royal'"
              :data-field="$t('launchTrip.numeroPedidoRoyal')"
              editor-type="dxTextBox"
              :editor-options="{
                value: numeroPedidoRoyal,
                placeholder: $t('launchTrip.numeroPedidoRoyal'),
                onValueChanged: onChangedNumeroPedidoRoyal,
              }"
            />
          </DxGroupItem>
          <DxSimpleItem
            :data-field="$t('common.temperature.lowerTemperature')"
            editor-type="dxNumberBox"
            :editor-options="{
              readOnly: readonlyTemperatures,
              value: lowerTemperature,
              onValueChanged: onChangedLowerTemperature,
              placeholder: $t('common.temperature.lowerTemperature'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('common.temperature.higherTemperature')"
            editor-type="dxNumberBox"
            :editor-options="{
              readOnly: readonlyTemperatures,
              value: higherTemperature,
              onValueChanged: onChangedHigherTemperature,
              placeholder: $t('common.temperature.higherTemperature'),
            }"
          />
          <DxSimpleItem
            :editor-options="{
              value: typeTransport,
              onValueChanged: onChangedTypeTransport,
              items: typeOfTransport,
              displayExpr: 'text',
              valueExpr: 'value',
              layout: 'horizontal',
              invalidDateMessage: $t('launchTrip.typeOfTransport')}"
            :data-field="$t('launchTrip.typeOfTransport')"
            editor-type="dxRadioGroup"
          />
          <DxSimpleItem
            v-if="showVisibilityThridParty === true"
            :data-field="$t('launchTrip.visibilityToThirdParties')"
            editor-type="dxSelectBox"
            :editor-options="{
              value: recipientInfoName,
              items: listRecipientInfo,
              displayExpr: 'Name',
              valueExpr: 'Identifier',
              placeholder: $t('launchTrip.visibilityToThirdParties'),
              onValueChanged: onValueChangedRecipientInfo,
            }"
          />
        </DxGroupItem>
        <DxGroupItem :col-count="3" />
      </DxForm>
      <div class="content-buttons margin-top">
        <DxButton
          class="dx-btn-cancel margin-right padding-half"
          type="normal"
          styling-mode="contained"
          @click="cancelTrip"
        >
          {{ $t("common.options.cancel") }}
        </DxButton>
        <DxButton
          class="dx-btn-success padding-half"
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("common.options.save") }}
        </DxButton>
      </div>
    </DxScrollView>
  </DxPopup>
</template>

<style lang="scss">

.content-launch-trip-popup{
  @media (min-width: 1024px) {
    .dx-overlay-content{
      height: auto!important;
      width: auto!important;
    }
  }
}

.main-launch-trip{
  .content-devx{
    width: 1080px;
    margin: 15px auto;
  }
}
.checkbox-items{
  .dx-field-item-label-content{
    width: auto!important;
    min-width: 35px;
  }
  &.dx-field-item:not(.dx-last-col) {
    padding-right: 0;
  }
  .dx-field-item-label-location-left{
    padding-right: 5px;
  }
  &.dx-last-col .dx-field-item-content{
    display: flex!important;
    align-items: flex-start;
  }
  &.dx-field-item:not(.dx-first-col) {
    padding-left: 5px;
  }
}
</style>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import 'devextreme-vue/radio-group';
import 'devextreme/ui/date_box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '../../js/event-bus';
import { commonProps } from '../trips/tripsColumnData';

export default {
  name: 'LaunchTripPopup',
  components: {
    DxScrollView,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxButton,
  },
  props: {},
  data() {
    return {
      commonProps,
      show: false,
      loaded: false,
      readonlyTemperatures: false,
      readonlyReference: false,
      cargoFrutaria: [],
      isVisibleRecipientInfo: false,
      datos: [],
    };
  },

  computed: {
    showVisibilityThridParty() {
      return this.getRecipientInfo.length > 0
              && this.$helpers.havePermission(this.getPermissions.visibility_third_parties_show);
    },
    form() {
      return this.$refs.form.instance;
    },
    typeOfTransport() {
      return this.getTypeOfTransport;
    },
    typeOfCargo() {
      return this.getCargos.length > 0 ? 'dxSelectBox' : 'dxTextBox';
    },
    typeOfOrigin() {
      return this.getOrigins.length > 0 ? 'dxSelectBox' : 'dxTextBox';
    },
    typeOfDestination() {
      return this.getDestinations.length > 0 ? 'dxSelectBox' : 'dxTextBox';
    },
    listTypeOfCargo() {
      return this.getCargos.map((content) => content.Name);
    },
    listOrigins() {
      return this.getOrigins.map((content) => content.Name);
    },
    listDestinations() {
      return this.getDestinations.map((content) => content.Name);
    },
    listRecipientInfo() {
      const hash = {};
      const unicos = this.getRecipientInfo.filter((current) => {
        const exists = !hash[current.Identifier];
        hash[current.Identifier] = true;
        return exists;
      });
      return unicos;
    },
    latestCargo() {
      if (this.getCargos.length > 0) {
        let indexCargo = this.getCargos.indexOf(
          this.getCargos.find((p) => p.Identifier === this.cargo),
        );
        if (
          indexCargo === null
          || indexCargo === -1
          || indexCargo === 'undefined'
          || indexCargo === undefined
        ) {
          indexCargo = this.getCargos.indexOf(
            this.getCargos.find((p) => p.Identifier === 'OTROS'),
          );
          return this.listTypeOfCargo[indexCargo];
        }
      }

      return this.cargo;
    },
    ...mapGetters('Shipments', ['getTypeOfTransport']),
    ...mapState('Shipments', [
      'deviceId',
      'cargo',
      'cargos',
      'carrier',
      'customerRef',
      'dateActivation',
      'dateDeliver',
      'activationPlace',
      'deliveryPlace',
      'lowerTemperature',
      'higherTemperature',
      'customer',
      'customerOfOurCustomer',
      'typeTransport',
      'transportNum',
      'numberTruck',
      'numberContainer',
      'clientWeb',
      'status',
      'genericAttribute01', 'genericAttribute02', 'genericAttribute03', 'genericAttribute04', 'genericAttribute05',
      'recipientInfo', 'recipientInfoName',
      'numeroPaletRoyal',
      'numeroPedidoRoyal',
      'codPreReferenciaFrutaria',
    ]),
    ...mapGetters('authentication', ['getThemeData', 'getPermissions']),
    ...mapGetters('Template', [
      'getCargos',
      'getOrigins',
      'getDestinations',
      'getComercialCasi',
      'getPreReferenciaFrutaria',
      'getRecipientInfo',
    ]),
  },
  async beforeMount() {
    this.loaded = false;
    if (this.deviceId !== '') {
      this.setModalEnableDevice(this.getThemeData.modalEnableDevice);
      await this.getInfoDeviceId(this.deviceId, this.getThemeData.modalEnableDevice);
    } else {
      this.initStore();
    }

    this.$f7.preloader.show();
    this.loaded = true;
    this.$f7.preloader.hide();
    if (this.getThemeData.modalEnableDevice === 'anecoopCamaras') this.readonlyTemperatures = true;
    if (this.getThemeData.modalEnableDevice === 'royal') this.readonlyReference = true;
    if (this.getRecipientInfo.length > 0) this.isVisibleRecipientInfo = true;
  },
  async mounted() {
    /* eventBus.$on('noUserPermisions', () => {
      this.$f7.dialog.alert(this.$t('launchTrip.noUserPermisions'));
    }); */
    eventBus.$on('openLaunchTrip', (deviceId) => {
      if (deviceId) {
        this.cargarPopup(deviceId);
      } else {
        this.show = true;
        this.initStore();
      }
    });
  },
  methods: {
    async cargarPopup(deviceId) {
      try {
        await this.getInfoDeviceId(deviceId, this.getThemeData.modalEnableDevice);
        this.show = true;
      } catch (error) {
        if (error === '403') { this.$f7.dialog.alert(this.$t('launchTrip.noWritePermissions')); } else { this.$f7.dialog.alert(this.$t('launchTrip.noOwnerDevice')); }
      }
    },
    onHidden() {
      this.show = false;
      this.form.resetValues();
      this.initStore();
    },
    changeSelectiontypeOfTransport(e) {
      this.selectionTypeOfTransport = e.value;
    },
    validateForm() {
      if (this.deviceId === '' || typeof this.deviceId === 'undefined') {
        this.$f7.dialog.alert(this.$t('launchTrip.numeroDCRequired'));
      } else if (
        typeof this.dateActivation === 'undefined'
        || this.dateActivation === ''
        || this.dateActivation === 'Invalid date'
        || this.dateActivation === null
      ) {
        this.$f7.dialog.alert(this.$t('launchTrip.departureDateRequired'));
      } else if (
        typeof this.dateDeliver === 'undefined'
        || this.dateDeliver === ''
        || this.dateDeliver === 'Invalid date'
        || this.dateDeliver === null
      ) {
        this.$f7.dialog.alert(this.$t('launchTrip.arrivalDateRequired'));
      } else if (moment(new Date(new Date(this.dateActivation).valueOf()), 'DD/MM/YYYY')
       > moment(new Date(new Date(this.dateDeliver).valueOf()), 'DD/MM/YYYY')) {
        this.$f7.dialog.alert(this.$t('launchTrip.dateChecking'));
      } else if (this.lowerTemperature >= this.higherTemperature) {
        this.$f7.dialog.alert(this.$t('launchTrip.temperatureCheck'));
      } else if (this.status === 39) {
        this.$f7.dialog.alert(this.$t('launchTrip.modifyOrActivate'));
        this.saveTrip();
      } else {
        this.saveTrip();
      }
    },
    async saveTrip() {
      // TODO: Realizar proceso.
      try {
        if (this.getThemeData.modalEnableDevice === 'frutaria') {
          this.setCustomerRef(this.codPreReferenciaFrutaria + this.customerRef);
        }
        await this.activateDevice();
        this.$f7.dialog.alert(this.$t('launchTrip.deviceActivationRequested'));
        this.show = false;
        this.form.resetValues();
      } catch (error) {
        this.$f7.dialog.alert(this.$t('launchTrip.noWritePermissions'));
      }
    },
    async cancelTrip() {
      this.initStore();
      this.show = false;
      this.form.resetValues();
    },
    async onChangedDeviceId(e) {
      try {
        await this.getInfoDeviceId(e.value, this.getThemeData.modalEnableDevice);
      } catch (error) {
        if (error === '403') { this.$f7.dialog.alert(this.$t('launchTrip.noWritePermissions')); } else { this.$f7.dialog.alert(this.$t('launchTrip.noOwnerDevice')); }
      }
    },
    onChangedActivationPlace(e) {
      this.setActivationPlace(e.value);
    },
    onChangedDeliveryPlace(e) {
      this.setDeliveryPlace(e.value);
    },
    onChangedCustomer(e) {
      this.setCustomerOfOurCustomer(e.value);
    },
    onChangedCarrier(e) {
      this.setCarrier(e.value);
    },
    onChangedTransportNum(e) {
      this.setTransportNum(e.value);
    },
    onChangedLowerTemperature(e) {
      this.setLowerTemperature(e.value);
    },
    onChangedHigherTemperature(e) {
      this.setHigherTemperature(e.value);
    },
    onChangedCustomerRef(e) {
      this.setCustomerRef(e.value);
    },
    onChangedCargoFrutaria(item) {
      const localThis = this;
      return function (e) {
        if (e.value === true) {
          localThis.cargoFrutaria.push(item);
        } else {
          const i = localThis.cargoFrutaria.indexOf(item);
          localThis.cargoFrutaria.splice(i, 1);
        }

        let temperaturaMinima = null;
        let temperaturaMaxima = null;

        if (localThis.cargoFrutaria.length === 0) {
          localThis.readonlyTemperatures = false;
          temperaturaMinima = null;
          temperaturaMaxima = null;
        } else if (localThis.cargoFrutaria.length === 1) {
          localThis.readonlyTemperatures = true;
          temperaturaMinima = localThis.cargoFrutaria[0].Range.Min;
          temperaturaMaxima = localThis.cargoFrutaria[0].Range.Max;
        } else if (localThis.cargoFrutaria.length > 1) {
          const temperaturaMinAnterior = localThis.cargoFrutaria[0].Range.Min;
          const temperaturaMaxAnterior = localThis.cargoFrutaria[0].Range.Max;

          for (let i = 0; i < localThis.cargoFrutaria.length; i += 1) {
            if (
              localThis.cargoFrutaria[i].Range.Min !== temperaturaMinAnterior
              || localThis.cargoFrutaria[i].Range.Max !== temperaturaMaxAnterior
            ) {
              localThis.readonlyTemperatures = false;
              temperaturaMinima = null;
              temperaturaMaxima = null;
            } else {
              localThis.readonlyTemperatures = true;
              temperaturaMinima = localThis.cargoFrutaria[i].Range.Min;
              temperaturaMaxima = localThis.cargoFrutaria[i].Range.Max;
            }
          }
        }

        let valorCargo = '';
        if (localThis.cargoFrutaria.length > 0) {
          for (let i = 0; i < localThis.cargoFrutaria.length; i += 1) {
            valorCargo += `${localThis.cargoFrutaria[i].Name}/`;
          }
        }

        const mercancia = valorCargo.slice(0, -1);

        localThis.setCargo(mercancia);
        localThis.setLowerTemperature(temperaturaMinima);
        localThis.setHigherTemperature(temperaturaMaxima);

        return '';
      };
    },
    onChangedCargo(e) {
      if (this.getCargos) this.setCargo(e.value);
    },
    onChangedTypeTransport(e) {
      this.setTypeTransport(e.value);
    },
    onChangedDateActivation(e) {
      if (e.value !== null) {
        this.setDateActivation(e.value);
      } else if (this.show) {
        this.setDateActivation(null);
        this.$f7.dialog.alert(this.$t('launchTrip.departureDateRequired'));
      }
    },
    onChangedDateDeliver(e) {
      if (e.value !== null) {
        this.setDateDeliver(e.value);
      } else if (this.show) {
        this.setDateDeliver(null);
        this.$f7.dialog.alert(this.$t('launchTrip.arrivalDateRequired'));
      }
    },
    consultRecipientInfo(identifier) {
      return this.getRecipientInfo.find((o) => o.Identifier === identifier);
    },
    onValueChangedRecipientInfo(e) {
      this.setRecipientInfoName(e.value);
      const recipientInfo = this.consultRecipientInfo(e.value);
      this.setRecipientInfo(recipientInfo);
    },
    onValueChangedGenericAttribute01(e) {
      this.setGenericAttribute01(e.value);
    },
    onChangedNumeroPaletRoyal(e) {
      this.setNumeroPaletRoyal(e.value);
      const referencia = `${e.value}/${this.numeroPedidoRoyal}`;
      let referenciaSinSeparador;
      if (referencia.charAt(0) === '/') {
        referenciaSinSeparador = referencia.replace('/', '');
      } else if (referencia.charAt(referencia.length - 1) === '/') {
        referenciaSinSeparador = referencia.replace('/', '');
      } else {
        referenciaSinSeparador = referencia;
      }
      this.setCustomerRef(referenciaSinSeparador);
    },
    onChangedNumeroPedidoRoyal(e) {
      this.setNumeroPedidoRoyal(e.value);
      const referencia = `${this.numeroPaletRoyal}/${e.value}`;
      let referenciaSinSeparador;
      if (referencia.charAt(0) === '/') {
        referenciaSinSeparador = referencia.replace('/', '');
      } else if (referencia.charAt(referencia.length - 1) === '/') {
        referenciaSinSeparador = referencia.replace('/', '');
      } else {
        referenciaSinSeparador = referencia;
      }
      this.setCustomerRef(referenciaSinSeparador);
    },
    onValueChangedPreReferenciaFrutaria(e) {
      this.setCodPreReferenciaFrutaria(e.value);
      if (e.value === '02/') {
        this.setActivationPlace('EXAASA');
      } else if (e.value === '04/') {
        this.setActivationPlace('AGRASUR');
      } else if (e.value === '05/') {
        this.setActivationPlace('EXAGAR');
      } else if (e.value === '06/') {
        this.setActivationPlace('CORINSA');
      } else {
        this.setActivationPlace('');
      }
    },
    ...mapActions('Shipments', ['setInfoDeviceId', 'initStore', 'setDeviceId',
      'setCustomerRef', 'setCargo', 'setCargos', 'setActivationPlace',
      'setDeliveryPlace', 'setCustomer', 'setCustomerOfOurCustomer', 'setCarrier', 'setTransportNum',
      'setLowerTemperature', 'setHigherTemperature', 'setDateActivation',
      'setTypeTransport', 'setDateDeliver', 'activateDevice',
      'setGenericAttribute01', 'setGenericAttribute02', 'setGenericAttribute03',
      'setGenericAttribute04', 'setGenericAttribute05',
      'setNumeroPaletRoyal', 'setNumeroPedidoRoyal', 'setCodPreReferenciaFrutaria',
      'setModalEnableDevice', 'setRecipientInfo', 'setRecipientInfoName', 'getInfoDeviceId']),
  },
};
</script>
