<template>
  <f7-page>
    <Navbar />
    <div class="wrapper main-deviceInfo">
      <f7-toolbar
        tabbar
        class="no-shadow"
      >
        <f7-link
          tab-link="#information"
          :tab-link-active="isActive('information')"
          @click="(v) => $emit('information', v)"
        >
          {{ $t('common.track.information') }}
        </f7-link>
        <f7-link
          tab-link="#informationMap"
          :tab-link-active="isActive('informationMap')"
          @click="(v) => $emit('informationMap', v)"
        >
          {{ $t('common.track.map') }}
        </f7-link>
        <f7-link
          tab-link="#reports"
          :tab-link-active="isActive('reports')"
          @click="(v) => $emit('reports', v)"
        >
          {{ $t('common.track.reports') }}
        </f7-link>
        <f7-link
          tab-link="#summary"
          :tab-link-active="isActive('summary')"
          @click="(v) => $emit('summary', v)"
        >
          {{ $t('deviceInfo.summary.tabTitle') }}
        </f7-link>
        <f7-link
          v-if="$helpers.havePermission(getPermissions.documents_tab_show)"
          tab-link="#documents"
          :tab-link-active="isActive('documents')"
          @click=" (v) => $emit('documents', v)"
        >
          {{ $t('common.blocks.documents') }}
        </f7-link>
      </f7-toolbar>
      <f7-tabs
        v-if="loaded"
      >
        <f7-tab
          id="information"
          :tab-active="isActive('information')"
          @tab:show="handleInformationShow"
        >
          <DeviceInfo />
        </f7-tab>
        <f7-tab
          id="informationMap"
          :tab-active="isActive('informationMap')"
        >
          <InformationMap />
        </f7-tab>
        <f7-tab
          id="reports"
          :tab-active="isActive('reports')"
        >
          <Reports />
        </f7-tab>
        <f7-tab
          id="summary"
          :tab-active="isActive('summary')"
        >
          <Summary />
        </f7-tab>
        <f7-tab
          id="documents"
          :tab-active="isActive('documents')"
        >
          <Documents :id="docId" />
        </f7-tab>
      </f7-tabs>
    </div>
  </f7-page>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import Navbar from '../../components/NavBar.vue';
import DeviceInfo from '../../components/deviceInfo/index.vue';
import Reports from '../../components/reports/index.vue';
import Summary from '../../components/summary/index.vue';
import Documents from '../../components/documents/index.vue';
import InformationMap from '../../components/informationMap/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'DevicesInfo',
  components: {
    Navbar,
    DeviceInfo,
    Reports,
    Summary,
    Documents,
    InformationMap,
  },
  props: {
    deviceId: {
      type: String,
      default: '',
    },
    currentTab: { type: String, default: 'information' },
    redraw: { type: Boolean, default: false },
    docId: { type: String, default: '' },
  },
  data() {
    return {
      Navbar,
      loaded: false,
      redrawCount: 0,
    };
  },
  computed: {
    ...mapState('Shipments', ['currentShip', 'reports']),
    ...mapGetters('authentication', ['getUsername', 'getPermissions']),
    ...mapState('authentication', ['user']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      // eslint-disable-next-line no-prototype-builtins
      await this.setCurrentShipById(this.deviceId);
      await this.fetchReports(this.deviceId);
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    setActiveTab(name) {
      this.currentTab = name;
    },
    isActive(name) {
      return name === this.currentTab;
    },
    handleInformationShow() {
      if (this.redrawCount !== 0) return;
      if (this.redraw) eventBus.$emit('redrawDeviceTabGraphs');
      this.redrawCount += 1;
    },
    ...mapActions('Shipments', ['fetchReports', 'setCurrentShipById']),
  },

};
</script>
