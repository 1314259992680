var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',_vm._b({ref:"dataGrid",attrs:{"data-source":_vm.devices,"key-expr":"deviceId","columns":_vm.deviceColumns},scopedSlots:_vm._u([{key:"status-cell",fn:function(ref){
var datos = ref.data;
return [_c('div',[_c('i',{class:_vm.setClassStatus(datos)})])]}},{key:"temp-cell",fn:function(ref){
var data = ref.data;
return [_c('span',{class:("temp-" + (_vm.getTempClass(data)))},[_vm._v("\n      "+_vm._s(data.value !== null ? data.value +'°C' : ' '))])]}},{key:"median-temp-cell",fn:function(ref){
var data = ref.data;
return [_c('Suspense',{attrs:{"get-data":_vm.createMedianTempFunc(data)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n        "+_vm._s(((_vm.getMedianTemp(data)) + " °C"))+"\n      ")]}}],null,true)})]}},{key:"out-of-range-cell",fn:function(ref){
var data = ref.data;
return [_c('Suspense',{attrs:{"get-data":_vm.createOutOfRangeFunc(data)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n        "+_vm._s(((_vm.getOutOfRangePercentage.apply(void 0, data)) + "%"))+"\n      ")]}}],null,true)})]}},{key:"alert-cell",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"content-alert"},_vm._l((_vm.parseAlerts(data)),function(svg){return _c('img',{key:svg,staticClass:"alert-icon",attrs:{"src":svg}})}),0)]}}])},'DxDataGrid',_vm.commonProps.dataGrid,false))}
var staticRenderFns = []

export { render, staticRenderFns }