<template>
  <div class="block-number-devices">
    <div class="content-badge">
      <span class="badge badge-primary">{{ totalCount }}</span>
      <span class="hidden-mobile-small">
        {{ $t('common.blocks.trips', {count: totalCount}) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCount',
  props: {
    totalCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
