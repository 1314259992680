export default {
  name: 'Mercancía',
  formData: {},
  fields: [
    {
      dataField: 'product',
      editorType: 'dxSelectBox',
      editorOptions: { disabled: false, displayExpr: 'name' },
      label: {
        text: 'product',
      },
    },
    {
      dataField: 'volume',
      editorOptions: { disabled: true },
      label: {
        text: 'volume',
      },
    },
    {
      dataField: 'unit',
      editorOptions: { disabled: false },
      label: {
        text: 'unit',
      },
    },
    {
      dataField: 'goodsCollected',
      editorOptions: { disabled: true },
      label: {
        text: 'Goods Collected',
      },
    },
  ],
};
