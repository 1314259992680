import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules

import authentication from './modules/authentication';
import Shipments from './modules/Shipments';
import Template from './modules/Template';
import Notifications from './modules/Notifications';
import Trips from './modules/Trips';
import Complaints from './modules/Complaints';
import Protocols from './modules/Protocols';

import DatabaseSync from './modules/database/sync'; 

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    Shipments,
    Template,
    Notifications,
    Trips,
    Complaints,
    Protocols,
    DatabaseSync,
  },
  components: {
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
    modules: ['authentication'],
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
