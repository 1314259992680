<template>
  <f7-card
    title="Pedidos"
    class="card-block main-orders"
  >
    <f7-block>
      <div class="content-buttons margin-vertical">
        <DxButton
          class="dx-btn-success padding-half"
          styling-mode="contained"
          @click="addPedido"
        >
          <i class="dx-icon-plus padding-right-half" />
          {{ $t('trips.add') }}
        </DxButton>
      </div>
      <DxTabPanel
        class="margin-bottom"
        :data-source="orders"
        :defer-rendering="false"
        :show-nav-buttons="true"
        :repaint-changes-only="true"
        item-title-template="title"
        item-template="itemTemplate"
      >
        <template #title="{data: pedido}">
          <div>
            <span>{{ $t('trips.registerTrip.referenceMin') }} {{ pedido.reference }}</span>
            <i
              class="dx-icon dx-icon-trash no-margin"
              @click="handleRemoveOrder(pedido)"
            />
          </div>
        </template>
        <template #itemTemplate="{data: pedido}">
          <DxTabPanel
            :data-source="createTabs(pedido)"
            item-title-template="title"
            item-template="itemTemplate"
          >
            <template #title="{data: tab}">
              <span>{{ tab.name }}</span>
            </template>
            <template #itemTemplate="{data: tab}">
              <div
                v-if="tab.name.toLowerCase() === 'información'"
                class="padding-half"
              >
                <DxForm
                  ref="form"
                  :col-count="2"
                  :on-field-data-changed="onFieldDataChanged"
                  class="padding-bottom-half"
                  :form-data="tab.data"
                  :items="tab.fields"
                />
              </div>
              <div
                v-else
                class="padding-top"
              >
                <DxDataGrid
                  :data-source="tab.data"
                  :columns="tab.columns"
                  :on-toolbar-preparing="onToolbarPreparing"
                >
                  <DxEditing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    :confirm-delete="true"
                    mode="cell"
                    :use-icons="true"
                  >
                    <DxTexts
                      :confirm-delete-message="$t('devextreme.confirmDelete')"
                    />
                  </DxEditing>
                  <template #editTooltip>
                    <p><i>{{ $t('trips.messageEditCelds') }}</i></p>
                  </template>
                </DxDataGrid>
                <!-- <span v-if="showDisabledColsTooltip(tab)">
                  <i>Some columns are disabled because they were specified as so, in
                    <a :href="goTo(tab.name)">Aministration/{{ tab.name }}</a>
                  </i>
                </span> -->
              </div>
            </template>
          </DxTabPanel>
        </template>
      </dxtabpanel>
    </f7-block>
  </f7-card>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import { DxForm } from 'devextreme-vue/form';
import DxTabPanel from 'devextreme-vue/tab-panel';
import { mapActions, mapGetters } from 'vuex';
import { DxDataGrid, DxEditing, DxTexts } from 'devextreme-vue/data-grid';
import eventBus from '../../../js/event-bus';
import { loadColumns, unloadColumns, cargosColumns } from '../../../components/trips/detailTemplate/ordersTab/ordersTabColumnData';

export default {
  name: 'Orders',
  components: {
    DxButton,
    DxDataGrid,
    DxForm,
    DxTabPanel,
    DxEditing,
    DxTexts,
  },
  data() {
    return {
      config: [],
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    loadColumns() {
      const cols = loadColumns;
      const { lookup } = cols[0];
      const { lookup: merchandise } = cols[cols.length - 1];

      merchandise.dataSource = this.merchandise;
      lookup.dataSource = this.locations.filter((item) => item.isLoadPlace === true);
      return cols;
    },
    unloadColumns() {
      const cols = unloadColumns;
      const { lookup } = cols[0];

      lookup.dataSource = this.locations.filter((item) => item.isDownloadPlace === true);
      return cols;
    },
    cargosColumns() {
      const cols = cargosColumns;
      const { lookup } = cols[0];

      lookup.dataSource = this.merchandise;
      return cols;
    },
    ...mapGetters('Trips', ['locations', 'orders', 'merchandise']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      await this.getLocations();
      await this.getLocations();
      await this.getMerchandises();
    } catch (error) {
      console.error(error);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    handleRemoveOrder(order) {
      eventBus.$emit('showRemoveOrderPopup', order);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'editTooltip',
      });
    },
    onFieldDataChanged(e) {
    },
    createTabs(pedido) {
      const pedidoLocal = [
        {
          name: this.$t('common.track.information'),
          data: {
            id: pedido.id,
            reference: pedido.reference,
          },
          fields: [
            {
              dataField: 'reference',
              caption: this.$t('common.track.reference'),
              editorType: 'dxTextBox',
              editorOptions: {
                onValueChanged: (e) => {
                  const orderFind = this.orders.find((order) => order.reference === e.previousValue);
                  orderFind.reference = e.value;
                },
              },
            },
          ],
        },
        {
          name: this.$t('common.blocks.loadingPoints'),
          data: pedido.loadPlaces,
          columns: this.loadColumns,
        },
        {
          name: this.$t('common.blocks.dischargePoints'),
          data: pedido.deliveryPlaces,
          columns: this.unloadColumns,
        },
        {
          name: this.$t('common.blocks.cargo'),
          data: pedido.merchandises,
          columns: this.cargosColumns,
        },
      ];
      return pedidoLocal;
    },

    goTo(tabName) {
      switch (tabName) {
        case this.$t('registerTrip.orders.loadingPoints.title'):
          return '/administration/loadingPoints';
        case this.$t('registerTrip.orders.unloadingPoints.title'):
          return '/administration/unloadingPoints';
        case this.$t('registerTrip.orders.cargo.title'):
          return '/administration/cargos';
        default:
          break;
      }
      return '/administration/carriers';
    },
    showDisabledColsTooltip(tab) {
      return tab.fields.some((item) => {
        if (item.hasOwnProperty('editorOptions')) {
          if (item.editorOptions.hasOwnProperty('disabled')) {
            if (item.editorOptions.disabled === true) return true;
            return false;
          }
        }
      });
    },
    addPedido() {
      eventBus.$emit('showAddOrderPopup');
    },
    ...mapActions('Trips', ['getLocations', 'removeOrder', 'getMerchandises']),
  },

};
</script>

<style lang="scss">
.main-orders{
  .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tab .dx-tab-content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    i{
      align-self: flex-start;
    }
  }
  .dx-tabpanel:nth-of-type(1){
    border: 1px solid #e6ebf5;
  }
}
</style>
