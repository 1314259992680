<template>
  <f7-card
    :title="$t('trips.registerTrip.titleInformation')"
    class="card-block no-margin"
  >
    <f7-block>
      <DxForm
        :on-field-data-changed="onFieldDataChanged"
        :show-colon-after-label="false"
        label-location="top"
        :form-data="tripInfo"
        :items="infoTripFields"
      />
    </f7-block>
  </f7-card>
</template>

<script>
import { DxForm } from 'devextreme-vue/form';
import { mapGetters } from 'vuex';

export default {
  name: 'TripInfo',
  components: {
    DxForm,
  },
  computed: {
    infoTripFields() {
      return ([
        {
          itemType: 'group',
          colCount: 2,
          caption: this.$t('common.track.information'),
          items: [
            {
              dataField: 'reference',
              label: {
                text: this.$t('common.track.reference'),
              },
            },
            {
              dataField: 'clientReference',
              label: {
                text: this.$t('trips.columns.clientReference'),
              },
            },
          ],
        },
        {
          itemType: 'group',
          colCount: 2,
          caption: this.$t('common.date.dates'),
          items: [
            {
              dataField: 'aproxDeparture',
              editorType: 'dxDateBox',
              label: {
                text: this.$t('trips.columns.departure'),
              },
              editorOptions: {
                displayFormat: 'dd/MM/yyyy',
                placeholder: this.$t('common.date.dateActivation'),
                invalidDateMessage: this.$t('common.date.dateInvalid'),
              },
            },
            {
              dataField: 'aproxDelivery',
              editorType: 'dxDateBox',
              label: {
                text: this.$t('trips.columns.arrival'),
              },
              editorOptions: {
                displayFormat: 'dd/MM/yyyy',
                placeholder: this.$t('common.date.dateDeliver'),
                invalidDateMessage: this.$t('common.date.dateInvalid'),
              },
            }],
        },
        {
          itemType: 'group',
          colCount: 2,
          caption: this.$t('common.track.route'),
          items: [
            {
              dataField: 'origin',
              editorType: 'dxSelectBox',
              label: {
                text: this.$t('common.track.origin'),
              },
              editorOptions: {
                dataSource: this.locations.filter((item) => item.isLoadPlace),
                displayExpr: 'name',
                valueExpr: 'id',
                placeholder: this.$t('common.placeholder.selectLocation'),
              },
            },
            {
              dataField: 'destination',
              editorType: 'dxSelectBox',
              label: {
                text: this.$t('trips.columns.destination'),
              },
              editorOptions: {
                dataSource: this.locations.filter((item) => item.isDownloadPlace),
                displayExpr: 'name',
                valueExpr: 'id',
                placeholder: this.$t('common.placeholder.selectLocation'),
              },
            },
          ],
        },
      ]);
    },
    ...mapGetters('Trips', ['tripInfo', 'locations']),
  },
  methods: {
    onFieldDataChanged(e) {
      if (e.dataField === 'aproxDeparture') {
        e.component.getEditor('aproxDelivery').option('min', e.value);
      }
      if (e.dataField === 'aproxDelivery') {
        e.component.getEditor('aproxDeparture').option('max', e.value);
      }
    },
  },
};
</script>
