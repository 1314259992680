import {
  APPLICATION_NAME,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_TERTIARY_COLOR,
  APP_LOGO,

} from '../../js/constants';
import EventBus from '../../js/event-bus';
import i18n from '../../js/i18n.config';
import api from '../../services/Api';
import Sync from '../../services/Sync.js';

export default {
  namespaced: true,

  state: {
    appConfig: {
      name: APPLICATION_NAME,
      logoUrl: APP_LOGO,
      apiBaseUrl: null,
      primaryColor: DEFAULT_PRIMARY_COLOR,
      secondaryColor: DEFAULT_SECONDARY_COLOR,
      tertiaryColor: DEFAULT_TERTIARY_COLOR,
      accessKey: '',
      defaultPanelLoad: '',
    },
    loggedUser: null,
    wantToRemember: false,
    wantToRecoveryPass: false,
    changePassword: false,
    messageChangePassword: false,
    currentLocation: null,
    user: null,
    actualMenuSelected: undefined,
  },

  getters: {
    getWantToRemember: (state) => state.wantToRemember,
    getPermissions: (state) => state.user.user.permissions,
    getActualMenuSelected: (state) => state.actualMenuSelected,
    getLogo: (state) => state.appConfig.logoUrl,
    getPrimaryColor: (state) => state.appConfig.primaryColor,
    getSecondaryColor: (state) => state.appConfig.secondaryColor,
    getTertiaryColor: (state) => state.appConfig.tertiaryColor,
    getThemeColor: (state) => state.user.user.theme.themeColor,
    getThemePrimaryColor: (state) => state.user.user.theme.primaryColor,
    getThemeSecondaryColor: (state) => state.user.user.theme.secondaryColor,
    getThemeTertiaryColor: (state) => state.user.user.theme.tertiaryColor,
    getThemeData: (state) => state.user.user.theme,
    getColumnPreferences: (state) => state.user.user.theme.columnsPreference,
    getGenericAttributesNames: (state) => state.user.user.theme.nameAttributesGeneric,
    getAccessKey: (state) => state.appConfig.accessKey,
    getUserLang: (state) => state.user.user.theme.culture.split('-')[0],
    getUsername: (state) => state.user.user.contact.code,
    getUserThemename: (state) => state.user.user.contact.name,
    getTokenDateExpired: (state) => JSON.parse(Buffer.from(state.user.access.split('.')[1], 'base64').toString()).DateExpired,
    getDefaultPanelLoad: (state) => JSON.parse(Buffer.from(state.user.access.split('.')[1], 'base64').toString()).Theme.DefaultPanelLoad,
  },

  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOGGED_USER(state, payload) {
      state.loggedUser = payload;
    },
    SET_WANT_TO_REMEMBER(state) {
      state.wantToRemember = !state.wantToRemember;
    },
    WANT_TO_RECOVERY_PASS(state) {
      state.wantToRecoveryPass = !state.wantToRecoveryPass;
    },
    CHANGE_PASSWORD(state) {
      state.changePassword = !state.changePassword;
    },
    SET_MESSAGE_CHANGE_PASSWORD(state) {
      state.messageChangePassword = !state.messageChangePassword;
    },
    setActualMenuSelected(state, payload) {
      state.actualMenuSelected = payload;
    },
    SET_APPCONFIG(state, payload) {
      if (payload.user.theme.logo !== '') {
        state.appConfig.logoUrl = `../static/img/customers/${payload.user.theme.logo}`;
      }
      if (payload.user.theme.primaryColor !== '') {
        state.appConfig.primaryColor = payload.user.theme.primaryColor;
      }
      if (payload.user.theme.secondaryColor !== '') {
        state.appConfig.secondaryColor = payload.user.theme.secondaryColor;
      }
      if (payload.user.theme.tertiaryColor !== '') {
        state.appConfig.tertiaryColor = payload.user.theme.tertiaryColor;
      }
      if (payload.accessKey !== '') {
        state.appConfig.accessKey = payload.accessKey;
      }
      if (payload.defaultPanelLoad !== '') {
        state.appConfig.defaultPanelLoad = JSON.parse(Buffer.from(payload.access.split('.')[1], 'base64').toString()).Theme.DefaultPanelLoad;
      }
    },
  },

  actions: {
    initialize(context, payload) {
      if (context.state.loggedUser) {
        EventBus.$emit('newLoggedUser', context.state.loggedUser);
      }
      EventBus.$on('invalidTokenDetected', () => context.dispatch('logout'));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('SET_APPCONFIG', JSON.parse(newLoggedUser)));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('SET_LOGGED_USER', newLoggedUser));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('SET_USER', JSON.parse(newLoggedUser)));
      EventBus.$on('newLoggedUser', () => i18n.i18next.changeLanguage(context.getters.getUserLang));
    },
    async login({ commit }, payload) {
      const { username, password, remember } = payload;
      await api.login({
        username,
        password,
        remember,
      });
      const culture = await api.getCulture();
      i18n.i18next.changeLanguage(culture);
      window.location.reload();
    },
    async logout(context) {
      context.commit('SET_LOGGED_USER', null);
      context.commit('SET_WANT_TO_REMEMBER');
      context.commit('setActualMenuSelected', undefined);
      // Sync.clearDatabase();
      history.pushState({}, '', '/');
      window.localStorage.clear();
      window.location.reload();
    },
    selectLocation({ commit }, value) {
      commit('setCurrentLocation', value);
    },
    wantToRecoveryPass({ commit }) {
      commit('WANT_TO_RECOVERY_PASS');
    },
    changePassword({ commit }) {
      commit('CHANGE_PASSWORD');
    },
    setWantToRemember({ commit }) {
      commit('SET_WANT_TO_REMEMBER');
    },
    setMessageChangePassword({ commit }) {
      commit('SET_MESSAGE_CHANGE_PASSWORD');
    },
    setActualMenuSelected({ commit }, payload) {
      commit('setActualMenuSelected', payload);
    },
  },
};
