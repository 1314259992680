<template>
  <GoogleMapLoader
    :map-config="mapConfig"
    api-key="AIzaSyCu8wi_ObsAmDZsoBsgoUQEMCI87b06NIU"
    :get-position="getPosition"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        :info-window="infoWindow"
        :info-window-instance="infoWindowInstance"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import Vue from 'vue';
import { mapSettings } from '../../../js/constants';
import GoogleMapLoader from '../loadMap/index.vue';
import GoogleMapMarker from '../markers/index.vue';
import InfoWindowTemplate from '../markers/infoWindowTemplate/index.vue';

export default {
  name: 'DevicesMap',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
  },
  props: {
    getPosition: {
      type: Boolean,
      default: false,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    paths: {
      type: Array,
      default: () => [],
    },
    moreInfoButton: {
      type: Boolean,
      default: false,
    },
    infoWindowTemplateReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      infoWindow: {},
      markers: [
        {
          id: 'a',
          position: { lat: null, lng: null },
        },
      ],
    };
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
    infoWindowInstance() {
      const InfoWindowClass = Vue.extend(InfoWindowTemplate);
      const infoWindowInstance = new InfoWindowClass({
        propsData: {
          moreInfoButton: this.moreInfoButton,
          infoWindowTemplateReport: this.infoWindowTemplateReport,
        },
        i18n: this.$i18n,
        store: this.$store,
      });
      infoWindowInstance.$mount();
      return infoWindowInstance;
    },
    mapCenter() {
      if (this.markers.length > 1) {
        return this.markers[1].position;
      } return { lat: 40.4167, lng: -3.70325 };
    },
  },
  beforeMount() {
    this.markers = [...this.markers, ...this.locations];
  },
  mounted() {
    this.$root.$on('googleReady', (google) => {
      this.infoWindow = new google.maps.InfoWindow();
    });
  },
};
</script>
