<template>
  <DxPopup
    v-bind="commonProps.popup"
    :title="$t('administration.loadingPoints.configPopup.title')"
    :visible.sync="show"
    :on-hidden="onHidden"
    height="auto"
    :width="620"
  >
    <DxForm
      ref="form"
      :col-count="2"
    >
      <DxSimpleItem
        v-for="item in config.fields"
        :key="item.label.text"
        editor-type="dxCheckBox"
        :label="{
          text: $t(`common.track.${item.label.text}`)
        }"
        :editor-options="{
          value: handleValue(item.editorOptions.disabled),
          onValueChanged: (e) => handleCheckboxChanged(e, item) ,
        }"
      />
    </DxForm>
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="hidePopup"
      >
        {{ $t("common.options.cancel") }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="updateConfig"
      >
        {{ $t("common.options.save") }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm, DxSimpleItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { mapGetters } from 'vuex';
import api from '../../../services/Api';

import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';
import config from './config';

export default {
  name: 'ConfigPopup',
  components: {
    DxPopup,
    DxForm,
    DxButton,
    DxSimpleItem,
  },
  data() {
    return {
      commonProps,
      config: [],
      show: false,
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapGetters('trips', ['getLocations']),
  },
  async beforeMount() {
    await this.getConfig();
  },
  mounted() {
    eventBus.$on('showLoadingPointsConfigPopup', () => { this.show = true; });
  },
  methods: {
    handleValue(disabled) {
      if (typeof disabled !== 'boolean') return true;
      return !disabled;
    },
    async updateConfig() {
      this.$f7.preloader.show();
      this.getLocations();
      // await api.updateLoadingPointsConfiguration(this.config);
      this.getConfig();
      this.$f7.preloader.hide();

      this.hidePopup();
    },
    async getConfig() {
      this.config = await config;
    },
    handleCheckboxChanged(e, item) {
      item.editorOptions.disabled = e.value;
    },
    hidePopup() {
      this.show = false;
    },
    onHidden() {
      this.show = false;
    },
  },
};
</script>

<style>

</style>
