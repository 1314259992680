<template>
  <f7-page>
    <Navbar />
    <Filters />
    <TripsTable />
  </f7-page>
</template>

<script>
import Navbar from '../../components/NavBar.vue';
import TripsTable from '../../components/trips/index.vue';
import Filters from '../../components/trips/filters/index.vue';

export default {
  name: 'Trips',
  components: {
    Navbar,
    TripsTable,
    Filters,
  },
};
</script>
