<template>
  <div>
    <DxAccordion
      :multiple="true"
      :collapsible="true"
      :data-source="data.data.orders"
    >
      <template #title="{data: order}">
        <div> {{ $t('common.track.reference') }}: {{ order.reference }}</div>
      </template>
      <template #item="{data: order}">
        <DxTabPanel>
          <DxItem :title="$t('common.blocks.loadingPoints')">
            <template #default>
              <DxDataGrid
                v-bind="commonProps.dataGrid"
                :data-source="order.loadPlaces"
                :columns="loadColumns"
                :on-cell-prepared="onCellPrepared"
              >
                <DxMasterDetail
                  :enabled="true"
                  template="master-template"
                />
                <template #master-template="{data}">
                  <TravelMap
                    v-if="![null, undefined, ''].includes(data.row.data.latitude)"
                    class="route-map"
                    :locations="[{id: data.row.data.id,
                                  position: {
                                    lat: data.row.data.latitude,
                                    lng: data.row.data.longitude,
                                  }}]"
                  />
                  <div v-else>
                    <p>{{ $t('trips.ordersTab.noCoordinates') }}</p>
                  </div>
                </template>
                <template #showMapButton="{data}">
                  <a @click="showMap(data)">{{ $t('trips.ordersTab.showMap') }}</a>
                </template>
              </DxDataGrid>
            </template>
          </DxItem>
          <DxItem :title="$t('common.blocks.dischargePoints')">
            <template #default>
              <DxDataGrid
                :data-source="order.deliveryPlaces"
                v-bind="commonProps.dataGrid"
                :columns="unloadColumns"
                :on-cell-prepared="onCellPrepared"
              >
                <DxMasterDetail
                  :enabled="true"
                  template="master-template"
                />
                <template #master-template="{data}">
                  <TravelMap
                    v-if="![null, undefined, ''].includes(data.row.data.latitude)"
                    class="route-map"
                    :locations="[{id: data.row.data.id,
                                  position: {
                                    lat: data.row.data.latitude,
                                    lng: data.row.data.longitude,
                                  }}]"
                  />
                  <div v-else>
                    <p>{{ $t('trips.ordersTab.noCoordinates') }}</p>
                  </div>
                </template>
                <template #showMapButton="{data}">
                  <a @click="showMap(data)">{{ $t('trips.ordersTab.showMap') }}</a>
                </template>
              </DxDataGrid>
            </template>
          </DxItem>
          <DxItem :title="$t('common.blocks.cargos')">
            <template #default>
              <DxDataGrid
                :data-source="order.merchandises"
                v-bind="commonProps.dataGrid"
                :columns="cargosColumns"
              />
            </template>
          </DxItem>
        </DxTabPanel>
      </template>
    </DxAccordion>
  </div>
</template>

<script>
import { DxDataGrid, DxMasterDetail } from 'devextreme-vue/data-grid';
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
import moment from 'moment';
import DxAccordion from 'devextreme-vue/accordion';
import { loadColumns, unloadColumns, cargosColumns } from './ordersTabColumnData';
import TravelMap from '../../../mapInfoDevice/map/index.vue';
import { commonProps } from '../../tripsColumnData';

export default {
  name: 'OrdersTab',
  components: {
    DxAccordion,
    DxTabPanel,
    TravelMap,
    DxDataGrid,
    DxMasterDetail,
    DxItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      commonProps,
      cargos: this.data.data.cargos,
      unloadingPoints: this.data.data.deliveryPlaces,
      loadingPoints: this.data.data.loadPlaces,
    };
  },
  computed: {
    loadColumns() {
      const cols = loadColumns;
      cols[0].calculateDisplayValue = (e) => e.name;
      cols[0].calculateCellValue = (e) => e;
      return cols;
    },
    unloadColumns() {
      const cols = unloadColumns;
      cols[0].calculateDisplayValue = (e) => e.name;
      cols[0].calculateCellValue = (e) => e;
      return cols;
    },
    cargosColumns() {
      const cols = cargosColumns;
      cols[0].calculateDisplayValue = (e) => e.product;
      cols[0].calculateCellValue = (e) => e;
      return cols;
    },
  },
  methods: {
    showMap(data) {
      const expand = data.component.expandRow;
      const collapse = data.component.collapseRow;
      const isExpanded = data.component.isRowExpanded;
      const { key } = data.row;

      if (isExpanded(key)) {
        collapse(key);
      } else {
        expand(key);
      }
    },
    addButtonFuncs(buttons) {
      buttons.forEach((button) => {
        switch (button.name) {
          case 'showMap':
            button.onClick = this.showMap;
            break;
          default:
            break;
        }
      });
    },
    onCellPrepared(row) {
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons);
    },
    formatDate(value) {
      return moment(value).format('DD/MM/YYYY');
    },
    formatTime(value) {
      return moment(value).format('hh:mm');
    },
    parseDate(value) {
      return moment(value, 'DD/MM/YYYY hh:mm').toDate();
    },
  },
};
</script>

<style>

</style>
