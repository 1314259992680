<template>
  <DxPopup
    id="attach-file-popup"
    v-bind="commonProps.popup"
    :title="$t('navbar.popup.deactivate.title')"
    :visible.sync="show"
    :on-hidden="onHiddenDeactivate"
    height="auto"
    :width="620"
  >
    <f7-block class="no-margin">
      <div class="item-title item-label">
        <p class="margin-bottom-half">
          {{ $t('navbar.popup.deactivate.description.line1') }}
          {{ $t('navbar.popup.deactivate.description.line2') }}
        </p>
      </div>
      <div class="padding-vertical-half text-align-center">
        <h4 class="color-his-primary">
          {{ $t('navbar.popup.deactivate.description.line3') }}
        </h4>
      </div>
    </f7-block>
    <f7-block-footer
      class="no-margin-top display-flex justify-content-center"
    >
      <f7-button
        fill
        type="contained"
        styling-mode="outlined"
        name="deactivate"
        @click="deactivatePopupDeactivate"
      >
        {{ $t('common.options.confirm') }}
      </f7-button>
    </f7-block-footer>
  </DxPopup>
</template>

<script>
import { mapActions } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';

export default {
  name: 'DeactivatePopup',
  components: {
    DxPopup,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      deviceId: '',
    };
  },
  mounted() {
    eventBus.$on('openDeactivatePopup', (deviceId) => {
      this.deviceId = deviceId;
      this.show = true;
    });
  },
  methods: {
    async deactivatePopupDeactivate() {
      this.$f7.preloader.show();
      try {
        // await this.deleteDeactivateDevice(this.deviceId);
        this.$f7.dialog.alert(this.$t('navbar.popup.deactivate.notification'));
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      }
    },
    onHiddenDeactivate() {
      this.show = false;
      this.deviceId = '';
    },
    ...mapActions('Shipments', ['deleteDeactivateDevice']),
  },
};
</script>
